import {
  ESGReportsAPIKeys,
  ESGReportsKeys,
  greenliteThirdPartyKeys,
  esgExportReportKeys,
  esgExportReportAPIKeys
} from "@/constants";

const makeEsgThirdPartyDTO = ({
  anti_bribery_and_corruption_strength,
  diversity_and_inclusion_strength,
  environment_rating,
  environment_risk_severity,
  environmental_practices_strength,
  governance_rating,
  governance_risk_severity,
  greenlite_explore_rating,
  health_and_safety_strength,
  human_rights_and_modern_slavery_strength,
  id,
  labour_strength,
  name,
  resilience_strength,
  overall_risk_severity,
  social_rating,
  social_risk_severity,
  supply_chain_strength,
  whistleblowing_strength,
  environment_resilience_strength,
  social_resilience_strength,
  governance_resilience_strength,
  carbon_neutral_strength
} = {}) => ({
  [greenliteThirdPartyKeys.ID]: id,
  [greenliteThirdPartyKeys.NAME]: name,
  [greenliteThirdPartyKeys.GREENLITE_EXPLORE_RATING]: greenlite_explore_rating,
  [greenliteThirdPartyKeys.RISK_SEVERITY]: overall_risk_severity,
  [greenliteThirdPartyKeys.RESILIENCE_STRENGTH]: resilience_strength,
  [greenliteThirdPartyKeys.ENVIRONMENTAL_RATING]: environment_rating,
  [greenliteThirdPartyKeys.SOCIAL_RATING]: social_rating,
  [greenliteThirdPartyKeys.GOVERNANCE_RATING]: governance_rating,
  [greenliteThirdPartyKeys.ENVIRONMENTAL_RISK]: environment_risk_severity,
  [greenliteThirdPartyKeys.SOCIAL_RISK]: social_risk_severity,
  [greenliteThirdPartyKeys.GOVERNANCE_RISK]: governance_risk_severity,
  [greenliteThirdPartyKeys.ENVIRONMENT_RESILIENCE]:
    environment_resilience_strength,
  [greenliteThirdPartyKeys.ENVIRONMENTAL_PRACTICES]:
    environmental_practices_strength,
  [greenliteThirdPartyKeys.CARBON_NEUTRAL]: carbon_neutral_strength,
  [greenliteThirdPartyKeys.SOCIAL_RESILIENCE]: social_resilience_strength,
  [greenliteThirdPartyKeys.HUMAN_RIGHTS_AND_MODERN_SLAVERY]:
    human_rights_and_modern_slavery_strength,
  [greenliteThirdPartyKeys.LABOUR]: labour_strength,
  [greenliteThirdPartyKeys.DIVERSITY_AND_INCLUSION]:
    diversity_and_inclusion_strength,
  [greenliteThirdPartyKeys.HEALTH_AND_SAFETY]: health_and_safety_strength,
  [greenliteThirdPartyKeys.GOVERNANCE_RESILIENCE]:
    governance_resilience_strength,
  [greenliteThirdPartyKeys.ANTI_BRIBERY_CORRUPTION]:
    anti_bribery_and_corruption_strength,
  [greenliteThirdPartyKeys.WHISTLEBLOWING]: whistleblowing_strength,
  [greenliteThirdPartyKeys.SUPPLY_CHAIN]: supply_chain_strength
});

const makeEsgThirdPartiesDTO = ({
  data,
  page_number,
  per_page,
  total,
  sort,
  third_party_name_search
} = {}) => ({
  [greenliteThirdPartyKeys.PER_PAGE]: per_page,
  [greenliteThirdPartyKeys.PAGE_NUMBER]: page_number,
  [greenliteThirdPartyKeys.TOTAL]: total,
  [greenliteThirdPartyKeys.SORT]: sort,
  [greenliteThirdPartyKeys.SEARCH]: third_party_name_search,
  [greenliteThirdPartyKeys.DATA]: data?.map(makeEsgThirdPartyDTO) || []
});

const makeESGReportSummaryDTO = (report) => ({
  [ESGReportsKeys.TRIGGER_DATE]: report?.[ESGReportsAPIKeys.TRIGGER_DATE] || "",
  [ESGReportsKeys.REPORT_ID]: report?.[ESGReportsAPIKeys.REPORT_ID] || ""
});

const makeESGReportsSummaryDTO = (reports = []) =>
  reports?.map(makeESGReportSummaryDTO);

const makeResilienceIndicatorDTO = (data = {}) => ({
  [ESGReportsKeys.PILLAR]: data?.[ESGReportsAPIKeys.PILLAR],
  [ESGReportsKeys.NAME]: data?.[ESGReportsAPIKeys.NAME],
  [ESGReportsKeys.SCORE]: data?.[ESGReportsAPIKeys.SCORE],
  [ESGReportsKeys.STRENGTH]: data?.[ESGReportsAPIKeys.STRENGTH]
});

const makeResilienceIndicatorsDTO = (data = []) =>
  data.map(makeResilienceIndicatorDTO);

const makeResilienceComponentDTO = (data = {}) => ({
  [ESGReportsKeys.COMPONENT]: data?.[ESGReportsAPIKeys.COMPONENT],
  [ESGReportsKeys.PILLAR]: data?.[ESGReportsAPIKeys.PILLAR],
  [ESGReportsKeys.RESILIENCE_STRENGTH]:
    data?.[ESGReportsAPIKeys.RESILIENCE_STRENGTH],
  [ESGReportsKeys.SCORE]: data?.[ESGReportsAPIKeys.SCORE],
  [ESGReportsKeys.RESILIENCE_INDICATORS]: makeResilienceIndicatorsDTO(
    data?.[ESGReportsAPIKeys.RESILIENCE_INDICATORS]
  )
});

const makeRiskComponentDTO = (data = {}) => ({
  [ESGReportsKeys.PILLAR]: data?.[ESGReportsAPIKeys.PILLAR],
  [ESGReportsKeys.RISK_SEVERITY]: data?.[ESGReportsAPIKeys.RISK_SEVERITY],
  [ESGReportsKeys.SCORE]: data?.[ESGReportsAPIKeys.SCORE]
});

const makePillarDTO = (data = {}) => ({
  [ESGReportsKeys.RATING]: data?.[ESGReportsAPIKeys.RATING],
  [ESGReportsKeys.SCORE]: data?.[ESGReportsAPIKeys.SCORE],
  [ESGReportsKeys.COMPONENTS]: {
    [ESGReportsKeys.RESILIENCE]: makeResilienceComponentDTO(
      data?.[ESGReportsAPIKeys.COMPONENTS]?.[ESGReportsAPIKeys.RESILIENCE]
    ),
    [ESGReportsKeys.RISK]: makeRiskComponentDTO(
      data?.[ESGReportsAPIKeys.COMPONENTS]?.[ESGReportsAPIKeys.RISK]
    )
  }
});

const makeESGDTO = (data = {}) => ({
  [ESGReportsKeys.RATING]: data[ESGReportsAPIKeys.RATING],
  [ESGReportsKeys.SCORE]: data[ESGReportsAPIKeys.SCORE],
  [ESGReportsKeys.COMPONENTS]: {
    [ESGReportsKeys.ENVIRONMENT_RESILIENCE]: makeResilienceComponentDTO(
      data?.[ESGReportsAPIKeys.COMPONENTS]?.[
        ESGReportsAPIKeys.ENVIRONMENT_RESILIENCE
      ]
    ),
    [ESGReportsKeys.GOVERNANCE_RESILIENCE]: makeResilienceComponentDTO(
      data?.[ESGReportsAPIKeys.COMPONENTS]?.[
        ESGReportsAPIKeys.GOVERNANCE_RESILIENCE
      ]
    ),
    [ESGReportsKeys.SOCIAL_RESILIENCE]: makeResilienceComponentDTO(
      data?.[ESGReportsAPIKeys.COMPONENTS]?.[
        ESGReportsAPIKeys.SOCIAL_RESILIENCE
      ]
    ),
    [ESGReportsKeys.ENVIRONMENT_RISK]: makeRiskComponentDTO(
      data?.[ESGReportsAPIKeys.COMPONENTS]?.[ESGReportsAPIKeys.ENVIRONMENT_RISK]
    ),
    [ESGReportsKeys.GOVERNANCE_RISK]: makeRiskComponentDTO(
      data?.[ESGReportsAPIKeys.COMPONENTS]?.[ESGReportsAPIKeys.GOVERNANCE_RISK]
    ),
    [ESGReportsKeys.SOCIAL_RISK]: makeRiskComponentDTO(
      data?.[ESGReportsAPIKeys.COMPONENTS]?.[ESGReportsAPIKeys.SOCIAL_RISK]
    )
  }
});

const makeResilienceDTO = (data = {}) => ({
  [ESGReportsKeys.SCORE]: data[ESGReportsAPIKeys.SCORE],
  [ESGReportsKeys.STRENGTH]: data[ESGReportsAPIKeys.STRENGTH],
  [ESGReportsKeys.COMPONENTS]: {
    [ESGReportsKeys.ENVIRONMENT]: makeResilienceComponentDTO(
      data?.[ESGReportsAPIKeys.COMPONENTS]?.[ESGReportsAPIKeys.ENVIRONMENT]
    ),
    [ESGReportsKeys.GOVERNANCE]: makeResilienceComponentDTO(
      data?.[ESGReportsAPIKeys.COMPONENTS]?.[ESGReportsAPIKeys.GOVERNANCE]
    ),
    [ESGReportsKeys.SOCIAL]: makeResilienceComponentDTO(
      data?.[ESGReportsAPIKeys.COMPONENTS]?.[ESGReportsAPIKeys.SOCIAL]
    )
  }
});

const makeRiskDTO = (data = {}) => ({
  [ESGReportsKeys.SCORE]: data[ESGReportsAPIKeys.SCORE],
  [ESGReportsKeys.SEVERITY]: data[ESGReportsAPIKeys.SEVERITY],
  [ESGReportsKeys.COMPONENTS]: {
    [ESGReportsKeys.ENVIRONMENT]: makeRiskComponentDTO(
      data?.[ESGReportsAPIKeys.COMPONENTS]?.[ESGReportsAPIKeys.ENVIRONMENT]
    ),
    [ESGReportsKeys.GOVERNANCE]: makeRiskComponentDTO(
      data?.[ESGReportsAPIKeys.COMPONENTS]?.[ESGReportsAPIKeys.GOVERNANCE]
    ),
    [ESGReportsKeys.SOCIAL]: makeRiskComponentDTO(
      data?.[ESGReportsAPIKeys.COMPONENTS]?.[ESGReportsAPIKeys.SOCIAL]
    )
  }
});

const makeSingleESGReportDTO = ({ esg_score = {}, url = "" } = {}) => ({
  [ESGReportsKeys.ENVIRONMENT]: makePillarDTO(
    esg_score?.[ESGReportsAPIKeys.ENVIRONMENT]
  ),
  [ESGReportsKeys.ESG]: makeESGDTO(esg_score?.[ESGReportsAPIKeys.ESG]),
  [ESGReportsKeys.GOVERNANCE]: makePillarDTO(
    esg_score?.[ESGReportsAPIKeys.GOVERNANCE]
  ),
  [ESGReportsKeys.SOCIAL]: makePillarDTO(esg_score?.[ESGReportsAPIKeys.SOCIAL]),
  [ESGReportsKeys.RESILIENCE]: makeResilienceDTO(
    esg_score?.[ESGReportsAPIKeys.RESILIENCE]
  ),
  [ESGReportsKeys.RISK]: makeRiskDTO(esg_score?.[ESGReportsAPIKeys.RISK]),
  [ESGReportsKeys.URL]: url
});

const makeEsgExportReportAPIDTO = (data = {}) => ({
  [esgExportReportKeys.COMPANY_ID]: data?.[esgExportReportAPIKeys.COMPANY_ID],
  [esgExportReportKeys.COMPANY_NAME]:
    data?.[esgExportReportAPIKeys.COMPANY_NAME],
  [esgExportReportKeys.EMAIL]: data?.[esgExportReportAPIKeys.EMAIL],
  [esgExportReportKeys.USER_ID]: data?.[esgExportReportAPIKeys.USER_ID]
});

export {
  makeEsgThirdPartyDTO,
  makeEsgThirdPartiesDTO,
  makeESGReportSummaryDTO,
  makeESGReportsSummaryDTO,
  makeSingleESGReportDTO,
  makeEsgExportReportAPIDTO,
  makeResilienceIndicatorDTO,
  makeResilienceIndicatorsDTO,
  makeResilienceComponentDTO,
  makeRiskComponentDTO,
  makePillarDTO,
  makeESGDTO,
  makeResilienceDTO,
  makeRiskDTO
};
