import { riskIcons, riskStatus } from "@/constants";
/**
 * @namespace Entity
 */

/**
 * @typedef IconWithTextOptions
 * @property {object | *} IconOptions - options to pass to the BaseIcon component
 * @property {object | *} textOptions - options to pass to the BaseText component
 */

/**
 * @memberof Entity
 * Returns an object of options to be used with the `IconWithText` component
 * @param {object} parameter - provided object
 * @param {string} parameter.status - IDD status
 * @param {boolean} parameter.remediated - Is the status remediated
 * @returns {IconWithTextOptions} - Options for `IconWithText`
 */
const getRiskIconBasedOnStatus = ({ status, remediated = false }) => {
  switch (status) {
    case riskStatus.NOT_AVAILABLE:
      return riskIcons.NOT_AVAILABLE;
    case riskStatus.NOT_RUN:
      return riskIcons.NOT_RUN;
    case riskStatus.IN_PROGRESS:
      return riskIcons.IN_PROGRESS;
    case riskStatus.NO_RISK_ALERT:
      return remediated
        ? riskIcons.NO_RISK_ALERT_REMEDIATED
        : riskIcons.NO_RISK_ALERT;
    case riskStatus.RISK_ALERT:
      return remediated
        ? riskIcons.RISK_ALERT_REMEDIATED
        : riskIcons.RISK_ALERT;
    default:
      return riskIcons.NOT_RUN;
  }
};

/**
 * Returns subtype of rating custom attribute
 * @param {object} parameter - provided object
 * @param {string} parameter.attributeName - unique identifier for custom attribute
 * @param {Array} parameter.customAttributeList - list of all custom attributes
 * @returns {*|string} subtype id
 */
const getCustomAttributeRatingScaleTypeId = ({
  attributeName,
  customAttributeList = []
}) => {
  const { tp_subtype_name: subTypeName } =
    customAttributeList.find(
      ({ tp_customatt }) => tp_customatt === attributeName
    ) || {};

  return subTypeName || "";
};

export { getRiskIconBasedOnStatus, getCustomAttributeRatingScaleTypeId };
