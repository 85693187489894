<template>
  <div
    :class="[
      'multi-select',
      {
        'multi-select--active': isDropDownVisible,
        'multi-select--disabled-scroll': showAllSelectedOptions
      }
    ]"
    data-test-id="multi-select"
    v-click-outside="onClickOutside"
  >
    <template v-if="label">
      <div :id="id">
        <Label
          :id="id"
          :label="label"
          :isRequired="isRequired"
          :class="[{ 'multi-select__label--hidden': isLabelHidden }]"
        >
          <template v-for="(_, slot) in $slots">
            <template :slot="slot">
              <slot :name="slot" />
            </template>
          </template>
        </Label>
      </div>
    </template>
    <div
      :aria-labelledby="id"
      :aria-owns="getOptionsId"
      class="multi-select__wrapper"
      role="listbox"
      :aria-roledescription="description"
      aria-multiselectable="true"
      :aria-activedescendant="lastFocusedDropDownListElement"
      tabindex="0"
    >
      <div class="multi-select__selections" @click="onToggleDropDown">
        <div
          :class="[
            'multi-select__selections-description',
            isScrollBarVisible && 'multi-select__selections-description--scroll'
          ]"
        >
          <BaseText v-if="isPlaceholderVisible" :text="placeholder" />
          <div v-else class="multi-select__selections-badges" ref="badgesList">
            <BaseBadge
              v-for="{ value, text, amendable, id } in getSelectedOptions"
              :ref="id"
              :key="id"
              :text="text"
              :theme="themes.DARK_GREY_INVERSE"
              :icon-space="space.NONE"
              :text-space="space.SMALL"
              :isRemovable="amendable"
              :white-space="getBadgeWhiteSpace"
              :text-align="textAlign.LEFT"
              class="multi-select__selections-badge"
              @remove-badge="onRemoveBadge(value)"
            />
            <div class="multi-select__selections-badges--space"></div>
          </div>
        </div>
        <CallToAction
          class="multi-select__selections-cta"
          aria-controls="multiSelectList"
          type="button"
          :aria-label="`drop down multi select ${name}`"
          :icon="getIcon"
          theme="none"
          :iconSpace="space.LARGE"
        />
      </div>
      <div
        data-test-id="multi-select__drop-down"
        class="multi-select__drop-down"
        :aria-expanded="isDropDownVisible"
      >
        <div class="multi-select__drop-down-search">
          <input
            role="searchbox"
            ref="multiSelectDropDownSearch"
            id="multi-select-drop-down-search"
            name="multi-select-drop-down-search"
            placeholder="Search"
            class="multi-select__drop-down-search-input"
            data-test-id="multi-select__drop-down-search-input"
            :value="dropDownSearchValue"
            aria-label="search bar"
            autocomplete="off"
            @keyup="onMultiSelectDropDownSearch"
            @keydown.enter="onDynamicOptionCreation"
          />
          <CallToAction
            v-if="isDropDownClearCtaVisible"
            class="multi-select__drop-down-search-clear-cta"
            icon="times"
            :theme="clearSearchTextIconTheme"
            icon-shape="circle"
            :icon-size="10"
            :iconSpace="space.SMALL"
            aria-label="clear search value"
            @mouseenter="onClearSearchTextIconTheme(true)"
            @mouseleave="onClearSearchTextIconTheme(false)"
            @click="onClearSearchBar"
          />
        </div>
        <ul
          v-if="getAvailableOptions.length"
          id="multiSelectList"
          data-test-id="multi-select__drop-down-list"
          class="multi-select__drop-down-list"
        >
          <li
            v-for="(
              { value, text, hidden, selected, id }, index
            ) in getAvailableOptions"
            :key="`${value}-${index}`"
            tabindex="0"
            :class="[
              'multi-select__drop-down-list-element',
              hidden && 'multi-select__drop-down-list-element--hidden'
            ]"
            :id="id"
            data-test-id="multi-select__drop-down-list-element"
            role="option"
            :aria-selected="selected"
            :aria-hidden="hidden"
            @click.stop="onDropDownListElementChange(value)"
            @keyup.enter.stop="onDropDownListElementChange(value)"
          >
            {{ text }}
          </li>
        </ul>
        <div v-else class="multi-select__drop-down--no-option-available">
          <BaseText text="No options available" />
        </div>
      </div>
      <InputErrorMessage :error="error" />
    </div>
  </div>
</template>

<script>
import Label from "@/atoms/Label/Label";
import InputErrorMessage from "@/molecules/InputErrorMessage/InputErrorMessage";
import BaseText from "@/atoms/BaseText/BaseText";
import BaseBadge from "@/atoms/BaseBadge/BaseBadge";
import {
  icons,
  operations,
  space,
  themes,
  whiteSpace,
  textAlign
} from "@/constants";
import CallToAction from "@/atoms/CallToAction/CallToAction";
import vClickOutside from "v-click-outside";
import { sortBy } from "lodash";

export default {
  name: "MultiSelect",
  components: {
    Label,
    InputErrorMessage,
    BaseText,
    BaseBadge,
    CallToAction
  },
  props: {
    id: {
      required: true,
      type: String
    },
    label: {
      type: String,
      default: ""
    },
    isRequired: {
      type: Boolean,
      default: true
    },
    error: {
      default: "",
      type: String
    },
    options: {
      type: Array,
      default: () => []
    },
    placeholder: {
      type: String,
      default: ""
    },
    isLabelHidden: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: ""
    },
    description: {
      type: String,
      default: "Multi select input"
    },
    showAllSelectedOptions: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      themes,
      space,
      textAlign,
      isDropDownVisible: false,
      isScrollBarVisible: false,
      dropDownSearchValue: "",
      clearSearchTextIconTheme: "none",
      lastFocusedDropDownListElement: "",
      selectionOrder: []
    };
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  computed: {
    getBadgeWhiteSpace() {
      return this.showAllSelectedOptions
        ? whiteSpace.NORMAL
        : whiteSpace.NO_WRAP;
    },
    isDropDownClearCtaVisible() {
      return !!this.dropDownSearchValue;
    },
    getIcon() {
      return this.isDropDownVisible ? icons.CARET_UP : icons.CARET_DOWN;
    },
    isPlaceholderVisible() {
      return !this.getSelectedOptions.length;
    },
    getSelectedOptions() {
      const options = this.options.reduce((acc, option) => {
        if (option.selected) {
          acc.push({
            ...option,
            order: this.selectionOrder?.findIndex(
              ({ value }) => value === option.value
            )
          });
        }
        return acc;
      }, []);

      return sortBy(options, ["order"]);
    },
    getOptionsId() {
      return this.options.map(({ id } = {}) => id);
    },
    getAvailableOptions() {
      return this.options.filter(({ text, hidden, selected }) => {
        if (hidden) {
          return false;
        }

        if (selected) {
          return false;
        }

        return text
          .toLowerCase()
          .includes(this.dropDownSearchValue.toLowerCase());
      });
    }
  },
  watch: {
    getSelectedOptions() {
      this.setScrollBarVisibility();
    }
  },
  methods: {
    async setScrollBarVisibility() {
      await this.$nextTick();
      this.isScrollBarVisible =
        this.$refs.badgesList?.offsetWidth < this.selectedOptionsWidth();
    },
    onClearSearchBar() {
      this.dropDownSearchValue = "";
    },
    onClearSearchTextIconTheme(value) {
      this.clearSearchTextIconTheme = value ? themes.INVERSE : themes.NONE;
    },
    onClickOutside() {
      this.onHideDropDown();
    },
    selectedOptionsWidth() {
      return this.getSelectedOptions.reduce((acc, { id }) => {
        acc += this.$refs[id]?.[0]?.$el?.offsetWidth;

        return acc;
      }, 0);
    },
    onToggleDropDown() {
      this.isDropDownVisible = !this.isDropDownVisible;

      if (this.isDropDownVisible) {
        this.$refs.multiSelectDropDownSearch.focus();
      } else {
        this.dropDownSearchValue = "";
      }
    },
    onHideDropDown() {
      this.isDropDownVisible = false;
      this.dropDownSearchValue = "";
    },
    onDropDownListElementChange(selectedValue) {
      this.selectionOrder.push(
        this.options.find(({ value }) => selectedValue === value)
      );
      this.$emit("change", { eventType: operations.ADD, value: selectedValue });
    },
    onDynamicOptionCreation() {
      this.$emit("change", {
        eventType: operations.CREATE,
        value: this.dropDownSearchValue
      });
      this.dropDownSearchValue = "";
    },
    onRemoveBadge(selectedValue) {
      this.selectionOrder = this.selectionOrder.filter(
        ({ value }) => value !== selectedValue
      );
      this.$emit("change", {
        eventType: operations.DELETE,
        value: selectedValue
      });
    },
    onMultiSelectDropDownSearch(event = {}) {
      this.dropDownSearchValue = event?.target?.value || "";
    }
  },
  async mounted() {
    this.selectionOrder = this.options.reduce((acc, option) => {
      if (option.selected) {
        acc.push(option);
      }
      return acc;
    }, []);
    await this.$nextTick();
    this.setScrollBarVisibility();
  }
};
</script>

<style lang="scss" scoped>
.multi-select {
  position: relative;
  z-index: 0;
  text-align: left;
  margin-bottom: 20px;

  &--disabled-scroll {
    & .multi-select__selections-description {
      padding: 4px 3px;

      &--scroll {
        overflow-x: auto;
      }
    }

    & .multi-select__selections-badges {
      display: flex;
      flex-wrap: wrap;
    }

    & .multi-select__selections-badge {
      margin: 4px 3px 3px 4px;
    }

    & .multi-select__selections-cta {
      height: auto;
    }
  }

  &__label--hidden {
    @include screen-reader-only;
  }

  &__selections {
    position: relative;
    display: flex;
    justify-content: space-between;
    border: 1px solid $chinese-silver;
    border-radius: 5px;
    overflow: hidden;
    min-height: 37px;

    &::after {
      content: "";
      position: absolute;
      top: 1px;
      left: calc(100% - 54px);
      width: 20px;
      height: calc(100% - 2px);
      background: linear-gradient(-90deg, $white, $white, transparent);
    }

    &-description {
      position: relative;
      padding: 8px 6px;
      width: 100%;
      text-align: left;
      overflow: hidden;
      align-self: center;

      &--scroll {
        overflow-x: scroll;
      }
    }

    &-badge {
      display: inline-flex;
      margin: 0 6px 0 0;
    }

    &-badges {
      position: relative;
      display: flex;
      padding-right: 20px;

      &--space {
        padding: 5px;
      }
    }

    &-cta {
      position: relative;
      border: none;
      border-radius: 0;

      &:after {
        content: "";
        position: absolute;
        top: 0;
        right: 100%;
        background: $chinese-silver;
        width: 1px;
        height: 100vh;
      }

      &:last-child {
        border-radius: 0 5px 5px 0;
      }
    }
  }

  &__drop-down {
    position: absolute;
    top: calc(100% + 5px);
    left: 0;
    z-index: -1;
    opacity: 0;
    height: 0;
    width: 100%;
    border: 1px solid $chinese-silver;
    border-radius: 5px;
    overflow: hidden;
    background: $white;
    box-shadow: $box-shadow;

    &-list {
      max-height: 100px;
      list-style-type: none;
      padding: 0;
      margin-bottom: 0;
      overflow-y: scroll;

      &-element {
        text-align: left;
        padding: 3px 10px;
        cursor: pointer;

        &:hover {
          background: $spanish-gray;
        }

        &:focus {
          border: 1px solid $spanish-gray;
        }

        &--hidden {
          display: none;
        }
      }
    }

    &-search {
      position: relative;
      padding: 15px 10px 10px 10px;
      box-shadow: $box-shadow;

      &-input {
        outline: none;
        border: 1px solid $spanish-gray;
        width: 100%;
        padding: 0 40px 0 5px;
        border-radius: 5px;
        border-color: $chinese-silver;

        &:hover,
        &:focus {
          outline: 1px solid $spanish-gray;
        }
      }

      &-icon {
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 40px;
        outline: none;
        border: none;
        background: transparent;
        border-left: 1px solid $granite-gray;

        &:hover,
        &:focus {
          background: $spanish-gray;
        }
      }

      &-clear-cta {
        position: absolute;
        top: 50%;
        right: 15px;
        transform: translateY(-50%);
        z-index: 1;
      }
    }

    &--no-option-available {
      padding: 20px 10px 0;
    }
  }

  &--active {
    z-index: 1;

    .multi-select__drop-down {
      z-index: inherit;
      opacity: 1;
      height: auto;
    }
  }
}
</style>
