<template>
  <ul
    :class="['menu', `menu__theme--${theme}`, `menu--${this.getType()}`]"
    data-test-id="menu"
  >
    <li v-for="(option, index) in itemOptions" :key="index">
      <MenuItem
        class="menu__item"
        :id="index"
        :theme="theme"
        :type="type"
        v-bind="option"
        @click="handleClick"
      />
    </li>
  </ul>
</template>

<script>
import MenuItem from "@/molecules/MenuItem/MenuItem";
import { themes, menuItemTypes } from "@/constants";
import { isValidTheme } from "@/utils";

export default {
  name: "Menu",
  components: { MenuItem },
  props: {
    itemOptions: {
      type: Array,
      default: () => []
    },
    theme: {
      type: String,
      default: themes.PRIMARY,
      validator(value) {
        return isValidTheme(value);
      }
    },
    type: {
      type: String,
      default: menuItemTypes.DEFAULT,
      validator: (value) => Object.values(menuItemTypes).includes(value)
    }
  },
  data() {
    return {
      themes,
      allowedTypes: Object.values(menuItemTypes)
    };
  },
  methods: {
    handleClick(event) {
      this.$emit("click", event);
    },
    getType() {
      return this.allowedTypes.includes(this.type)
        ? this.type
        : menuItemTypes.DEFAULT;
    }
  }
};
</script>

<style lang="scss" scoped>
.menu {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  border-bottom: 1px solid $primary-color;

  &--aside {
    flex-direction: column;
    border-bottom: none;
  }

  @each $theme, $primary-color, $secondary-color in $themes {
    &__theme--#{$theme} {
      background: $secondary-color;
    }
  }
}
</style>
