import { makeUUID } from "@/utils";
import { SetToBlockClass } from "@/molecules/SetToBlock/SetToBlock.class";

export class EmailNotificationExpectedDataClass extends SetToBlockClass {
  id = makeUUID();

  emailNotificationExpectedDataName = "";

  constructor({
    valueDataTypeText = "",
    valueDataTypeOptions = [],
    valueDataTypeErrorMessage = "",
    valueDataType = "",
    valueTypeOptions = [],
    valueTypeErrorMessage = "",
    isReadOnly = true,
    isMiddleSelectHidden = false,
    component = {},
    componentOptions = {},
    componentValue = "",
    isHidden = false,
    emailNotificationExpectedDataName = ""
  }) {
    super({
      valueDataTypeOptions,
      valueDataType,
      isReadOnly,
      isMiddleSelectHidden,
      valueTypeOptions,
      component,
      componentOptions,
      valueDataTypeText,
      componentValue,
      isHidden,
      valueDataTypeErrorMessage,
      valueTypeErrorMessage
    });
    this.setEmailNotificationExpectedDataName(
      emailNotificationExpectedDataName
    );
  }

  setEmailNotificationExpectedDataName(emailNotificationExpectedDataName) {
    this.emailNotificationExpectedDataName = emailNotificationExpectedDataName;
  }
}
