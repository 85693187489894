import { userEmailTemplatesDestinationTypes } from "@/constants";

export const getters = {
  getDestinationBaseOptions: (_, __, rootState) => {
    const platformUsersOptions =
      rootState.platformSettings.platformUserList.map(
        ({ email, memberId }) => ({
          text: email,
          value: memberId,
          type: userEmailTemplatesDestinationTypes.USER,
          companyId: rootState.company.companyId
        })
      );
    const rolesOptions = rootState.roles.roles.map(({ id, name }) => ({
      text: name,
      value: id,
      type: userEmailTemplatesDestinationTypes.ROLE,
      companyId: rootState.company.companyId
    }));
    return [...rolesOptions, ...platformUsersOptions];
  },
  getUserEmailTemplatesOptions: (state) =>
    state.userEmailTemplates.map(({ id, name }) => ({
      text: name,
      value: id
    })),
  getUserEmailTemplateBasedById: (state) => (id) =>
    state.userEmailTemplates.find(
      (userEmailTemplate) => id === userEmailTemplate.id
    ) || {}
};
