import {
  actionKeys,
  actionKeysApi,
  actionStepType,
  variableType,
  actionStepSubType
} from "@/constants";
import { makeOptionsForSelect } from "@/molecules/Select/Select.dto";
import {
  makeIfStatementDTO,
  makeIfStepAPIDTO
} from "@/services/actions/dto/ifStatementDTO/ifStatement.dto";
import {
  makeSetStepAPIDTO,
  makeSetStepDTO
} from "@/services/actions/dto/setStepDTO/setStep.dto";
import {
  makeEndStepDTO,
  makeEndStepAPIDTO
} from "@/services/actions/dto/endStepDTO/endStep.dto";
import {
  makeTriggerStepAPIDTO,
  makeTriggerStepDTO
} from "@/services/actions/dto/triggerStepDTO/triggerStep.dto";
import {
  makeNotificationEmailStepDTO,
  makeNotificationEmailStepAPIDTO
} from "@/services/actions/dto/notificationEmailDTO/notificationEmail.dto";
import { makeUUID } from "@/utils";

const makeDataDefinitionVariableOptionValueAPIDTO = (optionValue = {}) => ({
  [actionKeysApi.COMPANY_ID]: optionValue[actionKeys.COMPANY_ID],
  [actionKeysApi.DDQ_ID]: optionValue[actionKeys.DDQ_ID]
});

const makeDataDefinitionVariableOptionValueDTO = (optionValue = {}) => ({
  [actionKeys.COMPANY_ID]: optionValue[actionKeysApi.COMPANY_ID],
  [actionKeys.DDQ_ID]: optionValue[actionKeysApi.DDQ_ID]
});

const makeStepDTO = (functionStep) => {
  if (functionStep[actionKeysApi.STEP_TYPE] === actionStepType.SET) {
    return makeSetStepDTO({ functionStep });
  } else if (functionStep[actionKeysApi.STEP_TYPE] === actionStepType.IF) {
    return makeIfStatementDTO({ functionStep });
  } else if (functionStep[actionKeysApi.STEP_TYPE] === actionStepType.END) {
    return makeEndStepDTO(functionStep);
  } else if (functionStep[actionKeysApi.STEP_TYPE] === actionStepType.TRIGGER) {
    return makeTriggerStepDTO(functionStep);
  } else if (
    functionStep[actionKeysApi.SPECIFICATION][actionKeysApi.TYPE] ===
    actionStepSubType.EMAIL
  ) {
    return makeNotificationEmailStepDTO({ functionStep });
  }
};

const makeStepAPIDTO = (functionStep, entities, functionSteps) => {
  if (functionStep[actionKeys.STEP_TYPE] === actionStepType.SET) {
    return makeSetStepAPIDTO({ functionStep, entities, functionSteps });
  } else if (functionStep[actionKeys.STEP_TYPE] === actionStepType.IF) {
    return makeIfStepAPIDTO(functionStep, entities, functionSteps);
  } else if (functionStep[actionKeys.STEP_TYPE] === actionStepType.END) {
    return makeEndStepAPIDTO(functionStep);
  } else if (functionStep[actionKeys.STEP_TYPE] === actionStepType.TRIGGER) {
    return makeTriggerStepAPIDTO(functionStep);
  } else if (functionStep[actionKeys.STEP_TYPE] === actionStepSubType.EMAIL) {
    return makeNotificationEmailStepAPIDTO({ functionStep, entities });
  }
};

const makeDataDefinitionVariableDTO = ({
  name,
  entity,
  options,
  context,
  _makeDataDefinitionVariableOptionValueDTO = makeDataDefinitionVariableOptionValueDTO
} = {}) => ({
  [actionKeys.NAME]: name,
  [actionKeys.ENTITY]: entity,
  ...(options && {
    [actionKeys.OPTIONS]:
      options?.map(({ text, value }) => ({
        text,
        value: _makeDataDefinitionVariableOptionValueDTO(value)
      })) || []
  }),
  ...(context && {
    [actionKeys.CONTEXT]: {
      [actionKeys.FORM_ID]: context[actionKeysApi.FORM_ID]
    }
  })
});

const makeDataDefinitionVariableAPIDTO = ({ name, entity, context } = {}) => ({
  [actionKeysApi.NAME]: name,
  [actionKeysApi.ENTITY]: entity,
  ...(context && {
    [actionKeysApi.CONTEXT]: {
      [actionKeysApi.FORM_ID]: context[actionKeysApi.FORM_ID]
    }
  })
});

const makeDataDefinitionDTO = ({
  company_id,
  variables,
  _makeDataDefinitionVariableDTO = makeDataDefinitionVariableDTO
} = {}) => ({
  [actionKeys.COMPANY_ID]: company_id,
  [actionKeys.VARIABLES]: variables?.map(_makeDataDefinitionVariableDTO) || []
});

const makeDataDefinitionAPIDTO = ({
  companyId,
  variables,
  _makeDataDefinitionVariableAPIDTO = makeDataDefinitionVariableAPIDTO
} = {}) => ({
  [actionKeysApi.COMPANY_ID]: companyId,
  [actionKeysApi.VARIABLES]:
    variables?.map(_makeDataDefinitionVariableAPIDTO) || []
});

const makeActionDTO = ({
  id,
  name,
  description,
  expected_data_definition,
  function_body,
  is_active,
  is_licensed,
  created_at,
  updated_at,
  is_system,
  _makeDataDefinitionDTO = makeDataDefinitionDTO,
  _makeStepDTO = makeStepDTO
} = {}) => ({
  [actionKeys.ID]: id,
  [actionKeys.NAME]: name,
  [actionKeys.DESCRIPTION]: description,
  [actionKeys.EXPECTED_DATA_DEFINITION]: _makeDataDefinitionDTO(
    expected_data_definition
  ),
  [actionKeys.FUNCTION_BODY]:
    function_body?.map((functionStep) => _makeStepDTO(functionStep)) || [],
  [actionKeys.IS_ACTIVE]: is_active,
  [actionKeys.IS_LICENSED]: is_licensed,
  [actionKeys.CREATED_AT]: created_at,
  [actionKeys.UPDATED_AT]: updated_at,
  [actionKeys.IS_SYSTEM]: is_system
});

const makeActionAPIDTO = ({
  action,
  entities,
  _makeDataDefinitionAPIDTO = makeDataDefinitionAPIDTO,
  _makeStepAPIDTO = makeStepAPIDTO
} = {}) => ({
  [actionKeysApi.NAME]: action[actionKeys.NAME],
  [actionKeysApi.DESCRIPTION]: action[actionKeys.DESCRIPTION],
  [actionKeysApi.IS_ACTIVE]: action[actionKeys.IS_ACTIVE],
  [actionKeysApi.EXPECTED_DATA_DEFINITION]: _makeDataDefinitionAPIDTO(action),
  [actionKeysApi.FUNCTION_BODY]:
    action[actionKeys.FUNCTION_BODY]?.map((functionStep, _, functionSteps) =>
      _makeStepAPIDTO(functionStep, entities, functionSteps)
    ) || []
});

const makeParsedExpectedDataDTO = ({ name, attributes }) => ({
  [actionKeys.NAME]: name,
  [actionKeys.ATTRIBUTES]:
    attributes?.map(({ type, data } = {}) => {
      const baseExpectedData = {
        [actionKeys.TYPE]: type,
        [actionKeys.DATA]: {
          [actionKeys.TYPE]: data?.[actionKeysApi.TYPE],
          [actionKeys.DATA]: {}
        }
      };

      if (type === variableType.PROXY) {
        baseExpectedData[actionKeys.TYPE] = variableType.VARIABLE;
        baseExpectedData[actionKeys.DATA][actionKeys.TYPE] = variableType.PROXY;
        baseExpectedData[actionKeys.DATA][actionKeys.DATA] = {
          [actionKeys.NAME]: data?.[actionKeysApi.NAME],
          [actionKeys.ENTITY]: data?.[actionKeysApi.ENTITY],
          [actionKeys.CONTEXT]: {
            [actionKeys.FORM_ID]:
              data?.[actionKeysApi.CONTEXT]?.[actionKeysApi.FORM_ID]
          },
          [actionKeys.PROXY_NAME]: data?.[actionKeysApi.PROXY_NAME],
          [actionKeys.ATTRIBUTE_NAME]: data?.[actionKeysApi.ATTRIBUTE_NAME],
          [actionKeys.REFERENCE_URL]: data?.[actionKeysApi.REFERENCE_URL],
          [actionKeys.IS_READ_ONLY]: true
        };
      } else {
        baseExpectedData[actionKeys.DATA][actionKeys.DATA] = {
          [actionKeys.NAME]: data?.[actionKeysApi.DATA]?.[actionKeysApi.NAME],
          [actionKeys.IS_ENUM]:
            data?.[actionKeysApi.DATA]?.[actionKeysApi.IS_ENUM],
          [actionKeys.IS_READ_ONLY]:
            data?.[actionKeysApi.DATA]?.[actionKeysApi.IS_READ_ONLY],
          [actionKeys.IS_CUSTOM_ATTRIBUTE]:
            data?.[actionKeysApi.DATA]?.[actionKeysApi.IS_CUSTOM_ATTRIBUTE],
          [actionKeys.IDENTIFIER]:
            data?.[actionKeysApi.DATA]?.[actionKeysApi.IDENTIFIER],
          [actionKeys.SUB_TYPE]:
            data?.[actionKeysApi.DATA]?.[actionKeysApi.SUB_TYPE],
          [actionKeys.OPTIONS]: makeOptionsForSelect(
            undefined,
            data?.[actionKeysApi.DATA]?.[actionKeysApi.OPTIONS]
          ),
          ...(data?.[actionKeysApi.DATA]?.hasOwnProperty([
            actionKeysApi.IS_ARRAY
          ]) && {
            [actionKeys.IS_ARRAY]:
              data[actionKeysApi.DATA][actionKeysApi.IS_ARRAY]
          })
        };
      }
      return baseExpectedData;
    }) || []
});

const makeAvailableExpectedDataDTO = (expectedDataEntities = {}) => ({
  [actionKeys.NAME]: expectedDataEntities[actionKeysApi.NAME],
  [actionKeys.ENTITY]: expectedDataEntities[actionKeysApi.ENTITY],
  ...(expectedDataEntities.hasOwnProperty(actionKeys.CONTEXT) && {
    [actionKeys.CONTEXT]: {
      [actionKeys.FORM_ID]:
        expectedDataEntities?.[actionKeysApi.CONTEXT]?.[actionKeysApi.FORM_ID]
    }
  }),
  ...(expectedDataEntities.hasOwnProperty(actionKeysApi.REFERENCE_URL) && {
    [actionKeys.REFERENCE_URL]:
      expectedDataEntities[actionKeysApi.REFERENCE_URL],
    [actionKeys.REFERENCES]: {}
  })
});

const makeEntitiesDynamicReferenceDTO = (reference = {}) => ({
  [actionKeys.ID]: makeUUID(),
  [actionKeys.TITLE]: reference[actionKeysApi.TITLE],
  [actionKeys.SECTIONS]: reference?.[actionKeysApi.SECTIONS]?.map(
    (section) =>
      ({
        [actionKeys.ID]: makeUUID(),
        [actionKeys.TITLE]: section[actionKeysApi.TITLE],
        [actionKeys.ELEMENTS]:
          section?.[actionKeysApi.ELEMENTS]?.map((element) => ({
            [actionKeys.ID]: makeUUID(),
            [actionKeys.KEY]: element[actionKeysApi.KEY],
            [actionKeys.VALUE]: element[actionKeysApi.VALUE]
          })) || []
      } || [])
  )
});

export {
  makeDataDefinitionVariableOptionValueDTO,
  makeDataDefinitionVariableOptionValueAPIDTO,
  makeStepDTO,
  makeActionDTO,
  makeParsedExpectedDataDTO,
  makeActionAPIDTO,
  makeStepAPIDTO,
  makeAvailableExpectedDataDTO,
  makeDataDefinitionDTO,
  makeDataDefinitionVariableDTO,
  makeDataDefinitionVariableAPIDTO,
  makeEntitiesDynamicReferenceDTO,
  makeDataDefinitionAPIDTO
};
