import { makeUUID } from "@/utils";
import BaseText from "@/atoms/BaseText/BaseText";
import { actionKeys, actionStepType, operations } from "@/constants";

export default {
  data() {
    return {
      endStepList: []
    };
  },
  methods: {
    deleteEndStepIndexFromList(index) {
      this.endStepList = this.endStepList.filter(
        ({ stepIndex }) => stepIndex !== index
      );
    },
    deleteEndStepIdFromList(id) {
      this.endStepList = this.endStepList.filter(
        ({ endStep }) => endStep.id !== id
      );
    },
    makeEndStep(data) {
      return {
        id: makeUUID(),
        stepType: actionStepType.END,
        comment: data?.comment,
        hasComment: !!data?.comment,
        text: "END"
      };
    },
    makeSuccessEndStep(stepIndex) {
      return {
        stepType: actionStepType.END,
        component: BaseText,
        componentOptions: this.makeEndStepAndExtendEndStepList(stepIndex)
      };
    },
    makeEndStepAndExtendEndStepList(
      functionData,
      index = this.functionSteps.length
    ) {
      const endStepInstance = this.makeEndStep(functionData);
      this.endStepList.push({
        stepIndex: index,
        endStep: endStepInstance
      });

      return endStepInstance;
    },
    makeNewEndStep(stepIndex = this.functionSteps.length - 1) {
      this.updateAllStepIndexes(operations.ADD, stepIndex);
      this.functionSteps.splice(
        stepIndex,
        0,
        this.makeEndStepAndExtendEndStepList(this.addBlockIndex)
      );
    },
    makeSuccessEndStepForIfBlock() {
      const { index: stepIndex, event } = this.successStepEvent;
      const successSetStep = this.makeSuccessEndStep(stepIndex);
      if (event.property === actionKeys.ELSE_BLOCK) {
        const elseBlocks = [
          ...(this.functionSteps[stepIndex]?.[event.property] || []),
          successSetStep
        ];
        this.$set(this.functionSteps[stepIndex], event.property, elseBlocks);
      } else {
        const successSteps = [
          ...(this.functionSteps[stepIndex]?.[event.property]?.[event.index]
            ?.successSteps || []),
          successSetStep
        ];
        this.$set(
          this.functionSteps[stepIndex][event.property][event.index],
          actionKeys.SUCCESS_STEPS,
          successSteps
        );
      }
    },
    updateEndStepsIndexes(editType, index) {
      for (
        let endSetStepsIndex = 0;
        endSetStepsIndex < this.endStepList.length;
        endSetStepsIndex++
      ) {
        if (this.endStepList[endSetStepsIndex].stepIndex >= index) {
          if (
            editType === operations.ADD &&
            this.endStepList[endSetStepsIndex].stepIndex >= index
          ) {
            this.endStepList[endSetStepsIndex].stepIndex += 1;
          } else if (
            editType === operations.DELETE &&
            this.endStepList[endSetStepsIndex].stepIndex > index
          ) {
            this.endStepList[endSetStepsIndex].stepIndex -= 1;
          }
        }
      }
    }
  }
};
