<template>
  <div class="dropdown-menu-list" data-test-id="dropdown-menu-list">
    <div
      v-for="(option, index) in menuOptions"
      :key="index"
      class="dropdown-menu-list__section"
      data-test-id="dropdown-menu-list__section"
    >
      <DropdownMenuHeading
        v-if="option.headingOptions"
        v-bind="option.headingOptions"
        data-test-id="dropdown-menu-list__section-heading"
      />
      <ComponentList :listOptions="option.listOptions" />
    </div>
  </div>
</template>

<script>
import ComponentList from "@/molecules/ComponentList/ComponentList";
import DropdownMenuHeading from "@/molecules/DropdownMenuHeading/DropdownMenuHeading";

export default {
  name: "DropdownMenuList",
  components: { ComponentList, DropdownMenuHeading },
  props: {
    menuOptions: {
      type: Array,
      default: () => []
    }
  }
};
</script>

<style lang="scss" scoped>
.dropdown-menu-list {
  background-color: white;
  border-radius: $border-radius;
  overflow: hidden;
  padding: 0;
  margin: 0;
  border: 1px solid $grey;
  @include box-shadow($box-shadow);

  &__section {
    &:not(:last-child)::after {
      content: "";
      display: block;
      height: 1px;
      background-color: $metallic-silver;
      margin: 3px 5px;
    }
  }
}
</style>
