import {
  themes,
  typographySize,
  border,
  iconSizes
} from "@/constants/design.constant";
import { icons, shapes } from "@/constants/shapes.constant";

const riskIcons = {
  NOT_AVAILABLE: {
    iconOptions: {
      shape: shapes.SQUARE,
      border: "dashed",
      theme: themes.NONE,
      size: 16
    },
    textOptions: {
      tag: "p",
      text: "Not available",
      size: typographySize.BODY_TEXT_SMALL
    }
  },
  NOT_RUN: {
    iconOptions: {
      icon: icons.MINUS,
      shape: shapes.SQUARE,
      border: "solid",
      theme: themes.LIGHT_GREY_INVERSE,
      size: 16
    },
    textOptions: {
      tag: "p",
      text: "Not run",
      size: typographySize.BODY_TEXT_SMALL
    }
  },
  IN_PROGRESS: {
    iconOptions: {
      shape: shapes.CIRCLE,
      icon: icons.HISTORY,
      size: 16
    },
    textOptions: {
      tag: "p",
      text: "In progress",
      size: typographySize.BODY_TEXT_SMALL
    }
  },
  NO_RISK_ALERT: {
    iconOptions: {
      icon: icons.CHECK,
      shape: shapes.CIRCLE,
      theme: themes.SUCCESS,
      size: 16
    },
    textOptions: {
      tag: "p",
      text: "No risk alert",
      size: typographySize.BODY_TEXT_SMALL
    }
  },
  RISK_ALERT: {
    iconOptions: {
      icon: icons.EXCLAMATION,
      shape: shapes.TRIANGLE,
      theme: themes.ERROR,
      size: 16
    },
    textOptions: {
      tag: "p",
      text: "Risk alert",
      size: typographySize.BODY_TEXT_SMALL
    }
  },
  NO_RISK_ALERT_REMEDIATED: {
    iconOptions: {
      icon: icons.CHECK,
      shape: shapes.CIRCLE,
      theme: themes.SUCCESS,
      size: 16,
      overlayText: "R"
    },
    textOptions: {
      tag: "p",
      text: ["No risk alert", "(Remediated)"],
      size: typographySize.BODY_TEXT_SMALL
    }
  },
  RISK_ALERT_REMEDIATED: {
    iconOptions: {
      icon: icons.EXCLAMATION,
      shape: shapes.TRIANGLE,
      theme: themes.ERROR,
      size: 16,
      overlayText: "R"
    },
    textOptions: {
      tag: "p",
      text: ["Risk alert", "(Remediated)"],
      size: typographySize.BODY_TEXT_SMALL
    }
  }
};

const defaultIcons = {
  SQUARE_DASHED: {
    shape: shapes.SQUARE,
    theme: themes.NONE,
    border: border.DASHED,
    size: iconSizes.DEFAULT
  },
  DO_NO_ENGAGE: {
    icon: icons.EXCLAMATION,
    shape: shapes.OCTAGON,
    theme: themes.INVERSE,
    size: iconSizes.DEFAULT
  },
  CHECK: {
    icon: icons.CHECK,
    shape: shapes.CIRCLE,
    theme: themes.SUCCESS_INVERSE_WHITE,
    size: iconSizes.DEFAULT
  },
  HIGH_ALERT: {
    icon: icons.EXCLAMATION,
    shape: shapes.TRIANGLE,
    theme: themes.ERROR,
    size: iconSizes.DEFAULT
  },
  REMEDIATED_OK: {
    icon: icons.CHECK,
    shape: shapes.CIRCLE,
    theme: themes.SUCCESS,
    overlayText: "R",
    size: iconSizes.DEFAULT
  },
  REMEDIATED_HIGH_ALERT: {
    icon: icons.EXCLAMATION,
    shape: shapes.TRIANGLE,
    theme: themes.ERROR,
    overlayText: "R",
    size: iconSizes.DEFAULT
  },
  IN_PROGRESS: {
    icon: icons.HISTORY,
    shape: shapes.SQUARE,
    theme: themes.STRONG_INVERSE,
    size: iconSizes.DEFAULT
  },
  WARNING_ALERT: {
    icon: icons.EXCLAMATION,
    shape: shapes.CIRCLE,
    theme: themes.WARNING,
    size: iconSizes.DEFAULT
  }
};

export { riskIcons, defaultIcons };
