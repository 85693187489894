<template>
  <div
    :class="[
      'rating-custom-attribute',
      `rating-custom-attribute--text-align-${textAlign}`
    ]"
    data-test-id="rating-custom-attribute"
  >
    <BaseIcon
      v-if="isIconSubtype"
      v-bind="getIcon"
      v-b-tooltip.hover.bottom
      :title="iconTooltip"
      class="rating-custom-attribute__icon"
      data-test-id="rating-custom-attribute__icon"
    />
    <div
      v-else
      :class="[
        'rating-custom-attribute__numeric',
        `rating-custom-attribute__numeric-theme--${getTheme()}`
      ]"
      data-test-id="rating-custom-attribute__numeric"
    >
      <BaseText
        :theme="getTheme()"
        :text="value"
        :size="typographySize.LARGE_TEXT_BOLD"
        tag="span"
        data-test-id="rating-custom-attribute__numeric_text"
      />
    </div>
  </div>
</template>

<script>
import {
  customAttributeRiskScales,
  numericScale,
  ratingTypes,
  textAlign,
  themes,
  icons,
  shapes,
  border,
  typographySize
} from "@/constants";
import BaseText from "@/atoms/BaseText/BaseText";
import BaseIcon from "@/atoms/BaseIcon/BaseIcon";

export default {
  name: "RatingCustomAttribute",
  components: {
    BaseText,
    BaseIcon
  },
  props: {
    value: {
      type: String,
      required: true
    },
    textAlign: {
      type: String,
      default: textAlign.CENTER
    },
    scaleType: {
      type: String,
      default: customAttributeRiskScales.ELEVEN_POINT_SCALE
    }
  },
  data() {
    return {
      typographySize
    };
  },
  computed: {
    isIconSubtype() {
      return isNaN(parseInt(this.value));
    },
    iconTooltip() {
      return this.value || "Not set";
    },
    getIcon() {
      let iconConfig = {};
      switch (this.value) {
        case ratingTypes.LOW:
          iconConfig = {
            icon: icons.CHECK,
            shape: shapes.CIRCLE,
            theme: themes.SUCCESS,
            size: 16
          };
          break;
        case ratingTypes.MODERATE:
          iconConfig = {
            icon: icons.EXCLAMATION,
            shape: shapes.CIRCLE,
            theme: themes.WARNING,
            size: 16
          };
          break;
        case ratingTypes.HIGH:
          iconConfig = {
            icon: icons.EXCLAMATION,
            shape: shapes.TRIANGLE,
            theme: themes.ERROR,
            size: 16
          };
          break;
        case ratingTypes.DO_NOT_ENGAGE:
          iconConfig = {
            icon: icons.EXCLAMATION,
            shape: shapes.OCTAGON,
            theme: themes.INVERSE,
            size: 16
          };
          break;
        default:
          iconConfig = {
            shape: shapes.SQUARE,
            theme: themes.NONE,
            border: border.DASHED,
            size: 16
          };
          break;
      }
      return iconConfig;
    }
  },
  methods: {
    getElevenPointScaleTheme() {
      if (parseInt(this.value) === numericScale.NUMERIC_VALUE_0) {
        return themes.GREY;
      } else if (parseInt(this.value) < numericScale.NUMERIC_VALUE_7) {
        return themes.SUCCESS;
      } else if (parseInt(this.value) < numericScale.NUMERIC_VALUE_9) {
        return themes.WARNING;
      } else {
        return themes.ERROR;
      }
    },
    getTenPointScaleTheme() {
      if (parseInt(this.value) < numericScale.NUMERIC_VALUE_5) {
        return themes.SUCCESS;
      } else if (parseInt(this.value) < numericScale.NUMERIC_VALUE_8) {
        return themes.WARNING;
      } else {
        return themes.ERROR;
      }
    },
    getTheme() {
      if (this.scaleType === customAttributeRiskScales.TEN_POINT_SCALE) {
        return this.getTenPointScaleTheme();
      }
      return this.getElevenPointScaleTheme();
    }
  }
};
</script>

<style lang="scss" scoped>
.rating-custom-attribute {
  &__numeric {
    display: inline-flex;
    border-radius: 100%;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    padding: 0;

    @each $theme, $primary-color, $secondary-color, $has-border in $themes {
      &-theme--#{$theme} {
        background-color: $primary-color;

        @if $has-border {
          border: 1px solid $chinese-silver;
        }
      }
    }
  }

  &--text-align-center {
    text-align: center;
  }

  &--text-align-left {
    text-align: left;
  }

  &--text-align-right {
    text-align: right;
  }
}
</style>
