var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"base-text__wrapper"},[(_vm.processedTextList.length)?_vm._l((_vm.processedTextList),function(textLine,index){return _c(_vm.tag,_vm._b({key:index,tag:"component",class:[
        'base-text',
        `base-text__${_vm.getSize}`,
        `base-text__theme--${_vm.getTheme}`
      ],attrs:{"data-test-id":"base-text"}},'component',_vm.$attrs,false),[_vm._v(" "+_vm._s(textLine)+" ")])}):[_c(_vm.tag,_vm._b({tag:"component",class:[
        'base-text',
        `base-text__${_vm.getSize}`,
        `base-text__theme--${_vm.getTheme}`
      ],attrs:{"data-test-id":"base-text"}},'component',_vm.$attrs,false),[_vm._t("default")],2)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }