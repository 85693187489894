<template>
  <div
    v-observe-visibility="{
      callback: callDdqApi,
      intersection: {
        threshold: 0.9
      }
    }"
  >
    <b-overlay :show="loading" rounded="sm">
      <b-card>
        <b-card-title class="text-center">{{
          this.data.ddqTitle
        }}</b-card-title>
        <highcharts
          ref="questions-bar-chart"
          :options="chartOptions"
          class="hc"
        />
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import { HTTP, makeAuthorizationHeader } from "@/utils";
import { queryObjectKeys } from "@/constants";

export default {
  name: "StandardQuestionsComponent",
  props: {
    questionId: {
      required: true,
      type: String
    },
    companyId: {
      required: true,
      type: Number
    },
    formId: {
      required: true,
      type: Number
    },
    itemsKey: {
      required: true,
      type: String
    },
    itemsIndex: {
      required: true,
      type: Number
    },
    selectedWidgets: {
      required: true,
      type: Array
    }
  },
  data() {
    return {
      data: {},
      loading: false,
      loaded: []
    };
  },
  computed: {
    chartOptions() {
      // This is assigned here so we have access to it in the chart options that
      // are functions, particularly the tooltip formatter
      const component = this;

      return {
        chart: {
          type: "bar",
          zooming: {
            mouseWheel: {
              enabled: false
            }
          }
        },
        title: {
          text: component.chartTitle
        },
        xAxis: {
          categories: component.answerCategories,
          labels: {
            style: {
              color: "#080808"
            }
          }
        },
        yAxis: {
          min: 0,
          title: {
            text: "Number of suppliers answered in this way"
          },
          gridLineWidth: 0,
          lineWidth: 1,
          tickInterval: component.tickIntervalSize,
          endOnTick: true
        },
        legend: {
          enabled: false
        },
        tooltip: {
          formatter() {
            // This is where the of 'this' to 'component' above makes all the difference
            // We need access to the computed values, but the Vue 'this' is out of scope
            // so we assign to 'component' to resolve the scope issue
            return `${component.answerCategoriesByShortened[this.x]}: ${
              this.y
            }`;
          }
        },
        colors: ["#4d9de0"],
        plotOptions: {
          bar: {
            dataLabels: {
              enabled: false
            }
          }
        },
        credits: {
          enabled: false
        },
        series: component.seriesData
      };
    },
    chartTitle() {
      return this.data?.questionHeading || "";
    },
    seriesData() {
      const self = this;
      if (this.data.answers) {
        return [
          {
            name: "Third parties",
            data: this.data.answers.map((answer) => answer.count),
            point: {
              events: {
                click() {
                  const mapDrillDownOptions = {};
                  // Longer names are shortened within the "answers" array and we need the full name for the drill down
                  const answerIndex = self.data.answers?.findIndex(
                    (answer) => self.addEllipsis(answer?.text) === this.category
                  );
                  const fullQuestion = self.data.answers[answerIndex]?.text;
                  const thirdParties = self.data.answers[
                    answerIndex
                  ]?.thirdParties?.map((thirdParty) => thirdParty?.id);

                  mapDrillDownOptions.modalTitle = self.data.questionHeading;
                  mapDrillDownOptions.tableTitle = fullQuestion;
                  mapDrillDownOptions.querySpecificData = {
                    thirdParties
                  };
                  mapDrillDownOptions.tableId =
                    queryObjectKeys.THIRD_PARTY_DDQ_QUESTIONS;

                  self.$emit("questionClicked", mapDrillDownOptions);
                }
              }
            }
          }
        ];
      }
      return {};
    },
    answerCategories() {
      if (this.data?.answers) {
        return this.data.answers.map((answer) => this.addEllipsis(answer.text));
      }
      return {};
    },
    tickIntervalSize() {
      if (this.data?.answers) {
        let answerMaxCount = Math.max(
          ...this.data.answers.map((answer) => answer.count)
        );
        if (answerMaxCount >= 100) {
          return 50;
        } else if (answerMaxCount >= 50) {
          return 10;
        } else if (answerMaxCount >= 10) {
          return 5;
        } else if (answerMaxCount >= 5) {
          return 3;
        }
        return 1;
      }
      return 1;
    },
    answerCategoriesByShortened() {
      if (this.data.answers) {
        let categories = {};
        for (const answer of this.data.answers) {
          categories[this.addEllipsis(answer.text)] = answer.text;
        }
        return categories;
      }
      return {};
    }
  },
  mounted() {
    this.selectedWidgets.map((items) => {
      if (items.key.includes("standard_question")) {
        this.loaded[items.index] = false;
      }
    });
  },
  methods: {
    callDdqApi(isVisible) {
      if (isVisible) {
        this.selectedWidgets.map((items) => {
          if (items.key == this.itemsKey) {
            this.getData();
          }
        });
      }
    },
    getData() {
      // If we've already got data, bail out and don't worry about loading anything new
      if (this.loaded[this.itemsIndex]) {
        return false;
      }

      this.loading = true;
      const apiData = {
        companyId: this.companyId,
        formId: this.formId,
        questionId: this.questionId
      };

      HTTP(
        "post",
        "dashboard/standardquestionanswers",
        apiData,
        makeAuthorizationHeader()
      ).then((response) => {
        this.data = response.data;
        this.loading = false;
        this.loaded[this.itemsIndex] = true;
      }); // TODO: decide what to do on API error?
    },
    addEllipsis: (str, length = 25) => {
      const trimmed = str.substring(0, length);
      if (trimmed !== str) {
        return `${trimmed}...`;
      }
      return str;
    }
  }
};
</script>
