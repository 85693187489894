<template>
  <div class="email-notification-step">
    <SearchSelect
      label="User email templates"
      placeholder="Select..."
      class="email-notification-step--template-name"
      name="user-email-template-name"
      :id="`user-email-template-name-${id}`"
      :options="emailNotificationOptions"
      :error="emailNotificationError"
      :searchValue="emailNotificationSearchValue"
      data-test-id="user-email-template-name"
      @change="
        $emit('change', {
          name: actionKeys.TEMPLATE_ID,
          id,
          event: $event
        })
      "
      @create="
        $emit('create', {
          name: actionKeys.TEMPLATE_ID,
          id,
          event: $event
        })
      "
      @reset="
        $emit('reset', {
          name: actionKeys.TEMPLATE_ID,
          id,
          event: $event
        })
      "
    />
    <template v-if="isEmailNotificationSelected">
      <div
        v-if="isExpectedDataAvailable"
        class="email-notification-step__expected-data"
        data-test-id="email-notification-step__expected-data"
      >
        <BaseText
          tag="h3"
          :size="typographySize.BODY_TEXT_BOLD"
          text="Expected data"
        />
        <ul
          class="email-notification-step__expected-data-list"
          data-test-id="email-notification-step__expected-data-list"
        >
          <li
            v-for="expectedData in emailNotificationExpectedData"
            :key="expectedData.id"
            class="email-notification-step__expected-data-list-element"
            data-test-id="email-notification-step__expected-data-list-element"
          >
            <div
              class="email-notification-step__expected-data-list-element--name"
            >
              <BaseText
                :text="expectedData.emailNotificationExpectedDataName"
              />
            </div>
            <SetToBlock
              v-bind="expectedData"
              class="
                email-notification-step__expected-data-list-element--set-to-block
              "
              data-test-id="email-notification-step__expected-data-list-element--set-to-block"
              @change="
                $emit('change', {
                  name: actionKeys.EXPECTED_DATA_VARIABLE,
                  id: expectedData.id,
                  event: $event
                })
              "
            />
          </li>
        </ul>
      </div>
      <div
        class="email-notification-step__email-destination"
        data-test-id="email-notification-step__email-destination"
      >
        <BaseText
          tag="h3"
          :size="typographySize.BODY_TEXT_BOLD"
          text="Email destination"
        />
        <MultiSelect
          id="email-notification-step-destination-to"
          label="To"
          :options="emailNotificationToDestinationOptions"
          name="email-notification-step-destination-to"
          description="Select to email destination"
          :showAllSelectedOptions="true"
          :error="emailDestinationToError"
          @change="
            $emit('change', {
              name: actionKeys.TO,
              id,
              event: $event
            })
          "
        />
        <MultiSelect
          id="email-notification-step-destination-cc"
          label="Cc"
          :options="emailNotificationCcDestinationOptions"
          name="email-notification-step-destination-cc"
          description="Select cc email destination"
          :showAllSelectedOptions="true"
          :error="emailDestinationCcError"
          :isRequired="false"
          @change="
            $emit('change', {
              name: actionKeys.CC,
              id,
              event: $event
            })
          "
        />
        <MultiSelect
          id="email-notification-step-destination-bcc"
          label="Bcc"
          :options="emailNotificationBccDestinationOptions"
          name="email-notification-step-destination-bcc"
          description="Select bcc email destination"
          :showAllSelectedOptions="true"
          :error="emailDestinationBccError"
          :isRequired="false"
          @change="
            $emit('change', {
              name: actionKeys.BCC,
              id,
              event: $event
            })
          "
        />
      </div>
    </template>
  </div>
</template>

<script>
import { actionKeys, typographySize } from "@/constants";
import BaseText from "@/atoms/BaseText/BaseText";
import SearchSelect from "@/molecules/SearchSelect/SearchSelect";
import SetToBlock from "@/molecules/SetToBlock/SetToBlock";
import MultiSelect from "@/molecules/MultiSelect/MultiSelect";

export default {
  name: "EmailNotificationStep",
  props: {
    emailNotificationOptions: {
      type: Array,
      default: () => []
    },
    emailNotificationError: {
      type: String,
      default: ""
    },
    emailNotificationSearchValue: {
      type: String,
      default: ""
    },
    emailNotificationExpectedData: {
      type: Array,
      default: () => []
    },
    emailNotificationToDestinationOptions: {
      type: Array,
      default: () => []
    },
    emailDestinationToError: {
      type: String,
      default: ""
    },
    emailNotificationCcDestinationOptions: {
      type: Array,
      default: () => []
    },
    emailDestinationCcError: {
      type: String,
      default: ""
    },
    emailNotificationBccDestinationOptions: {
      type: Array,
      default: () => []
    },
    emailDestinationBccError: {
      type: String,
      default: ""
    },
    id: {
      type: Number,
      require: true
    }
  },
  components: {
    SearchSelect,
    BaseText,
    SetToBlock,
    MultiSelect
  },
  data() {
    return {
      actionKeys,
      typographySize
    };
  },
  computed: {
    isEmailNotificationSelected() {
      return (
        !!this.emailNotificationSearchValue &&
        !!this.emailNotificationOptions.find(({ selected }) => selected)
      );
    },
    isExpectedDataAvailable() {
      return !!this.emailNotificationExpectedData.length;
    }
  }
};
</script>

<style lang="scss" scoped>
.email-notification-step {
  &__expected-data,
  &__email-destination {
    padding-top: 40px;
  }

  &__expected-data-list {
    list-style-type: none;
    padding: 0;

    &-element {
      display: flex;
      padding-bottom: 20px;

      &--name,
      &--set-to-block {
        width: 100%;
      }
    }
  }
}
</style>
