<template>
  <div class="due-diligence-reports">
    <b-row class="mt-5">
      <b-col cols="12">
        <b-tabs vertical small nav-class="custom-tab-css">
          <b-tab
            :lazy="$store.state.dueDiligenceTabLazyLoad"
            title="Due Diligence Reports"
          >
            <!-- Due Diligence Reports -->
            <b-overlay
              :show="
                isLoadingInstantDueDiligenceReportsData || isLoadingProducts
              "
              rounded="sm"
            >
              <b-card
                header-tag="header"
                class="border-0"
                body-class="cardBodyClass"
              >
                <template v-slot:header>
                  <b-row>
                    <b-col cols="9">
                      <h6>{{ $t("eb360.dueDiligenceRemediation.title") }}</h6>
                    </b-col>
                    <b-col cols="3" style="display: flex">
                      <CallToAction
                        v-if="products?.length"
                        class="due-diligence-reports__add-button"
                        theme="warning"
                        icon="plus"
                        @click="openUploadModal"
                      />
                      <b-button
                        class="mr-2"
                        variant="outline-info"
                        size="sm"
                        @click="getTpDueDeligenceReportStatus"
                      >
                        <font-awesome-icon icon="sync" />
                      </b-button>
                      <b-input-group size="sm">
                        <b-form-input
                          v-model="filter"
                          type="search"
                          id="filterInput"
                          placeholder="Type to Search"
                        ></b-form-input>
                        <b-input-group-append>
                          <b-button :disabled="!filter" @click="filter = ''">{{
                            $t("clearBtnText")
                          }}</b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-col>
                  </b-row>
                </template>
                <b-card-body
                  style="paddingtop: 15px"
                  class="dueScrollFlow"
                  body-class="cardBodyClass"
                >
                  <b-table
                    responsive="sm"
                    :sort-by.sync="sortBy"
                    hover
                    :filter="filter"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :items="instantDueDiligenceReportsItems"
                    :fields="instantDueDiligenceReportsfields"
                  >
                    <template #thead-top="data">
                      <b-tr class="text-center">
                        <b-th variant="info" colspan="4">{{
                          $t("eb360.dueDiligenceRemediation.initialReport")
                        }}</b-th>
                        <b-th
                          v-if="
                            data.fields.findIndex(
                              (x) => x.key == 'after_remediation_alert_status'
                            ) != -1
                          "
                          variant="primary"
                          colspan="5"
                          >{{
                            $t("eb360.dueDiligenceRemediation.remediation")
                          }}</b-th
                        >
                      </b-tr>
                    </template>
                    <template v-slot:cell(inserted_date)="data">
                      <BaseText
                        v-if="data.item.reportTypeText === reportType.PRODUCT"
                        :text="dateFormat(data.item.created_at)"
                      />
                      <BaseText
                        v-else
                        :text="dateFormat(data.item.inserted_date)"
                      />
                    </template>
                    <template v-slot:cell(product_type)="data">
                      <div
                        v-if="data.item.reportTypeText == 'OGM'"
                        style="text-transform: uppercase"
                      >
                        <div>
                          {{
                            data.item.product_type == "1"
                              ? "IDD"
                              : data.item.product_type == "2"
                              ? "IDD+"
                              : ""
                          }}
                          OGM
                          {{
                            data.item.remediation_date == null
                              ? remediationText.UNREMEDIATED_RED
                              : remediationText.REMEDIATED_RED
                          }}
                        </div>
                      </div>
                      <BaseText
                        v-if="data.item.reportTypeText === reportType.PRODUCT"
                        :text="data.item.product"
                      />
                      <p v-else style="text-transform: uppercase">
                        {{ data.item.reportTypeText }}
                      </p>
                    </template>
                    <template v-slot:cell(initial_alert_status)="data">
                      <div v-if="data.item.reportTypeText == 'OGM'">
                        <BaseIcon
                          :icon="icons.EXCLAMATION"
                          :shape="shapes.TRIANGLE"
                          :theme="themes.ERROR"
                          :size="20"
                          v-b-tooltip.hover.bottom
                          title="High Alert"
                        />
                      </div>
                      <div v-else-if="data.item.reportTypeText == 'EDD'">
                        <div>
                          <BaseIcon
                            v-bind="getIcon(data.item.edd_color)"
                            v-b-tooltip.hover.bottom.html="
                              iconData.edd.status[data.item.edd_color]
                                .tooltipEnable
                                ? iconData.edd.status[data.item.edd_color]
                                    .tooltip
                                : ''
                            "
                          />
                        </div>
                      </div>
                      <div
                        v-else-if="
                          data.item.reportTypeText !== reportType.PRODUCT
                        "
                      >
                        <div v-if="data.item.initial_alert_status === null">
                          <BaseIcon
                            v-bind="
                              getReportNotProductIcon(
                                data.item.product_type,
                                thirdPartyData
                              )
                            "
                            v-b-tooltip.hover
                            :title="
                              getReportNotProductIconTitle(
                                data.item.product_type,
                                thirdPartyData
                              )
                            "
                          />
                        </div>
                        <div v-else>
                          <BaseIcon
                            v-if="
                              parseInt(data.item.initial_alert_status) === 0
                            "
                            :icon="icons.CHECK"
                            :shape="shapes.CIRCLE"
                            :theme="themes.SUCCESS"
                            :size="20"
                            v-b-tooltip.hover.bottom
                            title="No Risk Alert"
                          />
                          <BaseIcon
                            v-else
                            :icon="icons.EXCLAMATION"
                            :shape="shapes.TRIANGLE"
                            :theme="themes.ERROR"
                            :size="20"
                            v-b-tooltip.hover.bottom
                            title="Risk Alert"
                          />
                        </div>
                      </div>
                    </template>
                    <template v-slot:cell(action)="data">
                      <b-dropdown
                        size="sm"
                        variant="outline"
                        toggle-class="text-decoration-none"
                        no-caret
                      >
                        <template #button-content>
                          <font-awesome-icon icon="ellipsis-v" />
                        </template>
                        <b-dropdown-item-button
                          class="text-secondary"
                          @click="viewPdfReport(data)"
                          v-if="!isValidReportType(data.item.reportTypeText)"
                          >View</b-dropdown-item-button
                        >
                        <b-dropdown-item-button
                          class="text-secondary"
                          @click="downloadPdfReport(data)"
                          >Download</b-dropdown-item-button
                        >
                      </b-dropdown>
                    </template>
                    <template v-slot:cell(risk_documents_inserted_date)="data">
                      <p v-if="data.item.risk_documents_inserted_date == null">
                        &nbsp;
                      </p>
                      <p v-else>
                        {{ dateFormat(data.item.risk_documents_inserted_date) }}
                      </p>
                    </template>
                    <template
                      v-slot:cell(after_remediation_alert_status)="data"
                    >
                      <BaseIcon
                        v-if="
                          data.item.after_remediation_alert_status == 5 ||
                          data.item.after_remediation_alert_status == 6
                        "
                        v-b-tooltip.hover.bottom.html="
                          iconData.afterRemediationAlertStatus.status[
                            data.item.after_remediation_alert_status
                          ].tooltipEnable
                            ? iconData.afterRemediationAlertStatus.status[
                                data.item.after_remediation_alert_status
                              ].tooltip
                            : ''
                        "
                        v-bind="
                          getIcon(data.item.after_remediation_alert_status)
                        "
                      />
                      <div
                        v-else-if="
                          (data.item.initial_alert_status == '0' ||
                            data.item.initial_alert_status == null) &&
                          data.item.reportTypeText != 'OGM'
                        "
                      ></div>
                      <BaseIcon
                        v-else-if="data.item.remediation_date == null"
                        :icon="icons.HISTORY"
                        :shape="shapes.SQUARE"
                        :theme="themes.STRONG_INVERSE"
                        :size="20"
                        v-b-tooltip.hover.bottom
                        title="In Progress"
                      />
                      <div v-else-if="data.item.reportTypeText == 'OGM'">
                        <div v-if="data.item.risk_document_id == null">
                          <BaseIcon
                            :icon="icons.HISTORY"
                            :shape="shapes.SQUARE"
                            :size="20"
                            :theme="themes.STRONG_INVERSE"
                            v-b-tooltip.hover.bottom
                            title="In Progress"
                          />
                        </div>
                        <div v-else>
                          <div
                            v-if="
                              data.item.product_type == '1' &&
                              data.item.rpt_status == '0'
                            "
                          >
                            <BaseIcon
                              :icon="icons.CHECK"
                              :shape="shapes.CIRCLE"
                              :size="20"
                              :theme="themes.SUCCESS"
                              overlay-text="R"
                              v-b-tooltip.hover.bottom.html="
                                iconData.afterOGMRemediationAlertStatus.status
                                  .Remediated_GREEN_IDD_OGM.tooltipEnable
                                  ? iconData.afterOGMRemediationAlertStatus
                                      .status.Remediated_GREEN_IDD_OGM.tooltip
                                  : ''
                              "
                            />
                          </div>
                          <div
                            v-else-if="
                              data.item.product_type == '2' &&
                              data.item.rpt_status == '0'
                            "
                          >
                            <BaseIcon
                              :icon="icons.CHECK"
                              :shape="shapes.CIRCLE"
                              :size="20"
                              :theme="themes.SUCCESS"
                              overlay-text="R"
                              v-b-tooltip.hover.bottom.html="
                                iconData.afterOGMRemediationAlertStatus.status
                                  .Remediated_GREEN_IDD_Plus_OGM.tooltipEnable
                                  ? iconData.afterOGMRemediationAlertStatus
                                      .status.Remediated_GREEN_IDD_Plus_OGM
                                      .tooltip
                                  : ''
                              "
                            />
                          </div>
                          <div
                            v-else-if="
                              data.item.product_type == '1' &&
                              data.item.rpt_status == '1'
                            "
                          >
                            <BaseIcon
                              :icon="icons.EXCLAMATION"
                              :shape="shapes.TRIANGLE"
                              :theme="themes.ERROR"
                              :size="20"
                              overlay-text="R"
                              v-b-tooltip.hover.bottom.html="
                                iconData.afterOGMRemediationAlertStatus.status
                                  .Remediated_RED_IDD_OGM.tooltipEnable
                                  ? iconData.afterOGMRemediationAlertStatus
                                      .status.Remediated_RED_IDD_OGM.tooltip
                                  : ''
                              "
                            />
                          </div>
                          <div
                            v-if="
                              data.item.product_type == '2' &&
                              data.item.rpt_status == '1'
                            "
                          >
                            <BaseIcon
                              :icon="icons.EXCLAMATION"
                              :shape="shapes.TRIANGLE"
                              :theme="themes.ERROR"
                              :size="20"
                              overlay-text="R"
                              v-b-tooltip.hover.bottom.html="
                                iconData.afterOGMRemediationAlertStatus.status
                                  .Remediated_RED_IDD_Plus_OGM.tooltipEnable
                                  ? iconData.afterOGMRemediationAlertStatus
                                      .status.Remediated_RED_IDD_Plus_OGM
                                      .tooltip
                                  : ''
                              "
                            />
                          </div>
                        </div>
                      </div>
                    </template>
                    <template v-slot:cell(remediation_action)="data">
                      <b-dropdown
                        size="sm"
                        variant="outline"
                        toggle-class="text-decoration-none"
                        no-caret
                        v-if="
                          data.item.after_remediation_alert_status == '6' ||
                          data.item.after_remediation_alert_status == '5'
                        "
                      >
                        <template #button-content>
                          <font-awesome-icon icon="ellipsis-v" />
                        </template>
                        <b-dropdown-item-button
                          class="text-secondary"
                          @click="downloadRemediatedReport(data)"
                          >Download</b-dropdown-item-button
                        >
                      </b-dropdown>
                      <b-dropdown
                        size="sm"
                        variant="outline"
                        toggle-class="text-decoration-none"
                        no-caret
                        v-else-if="
                          data.item.reportTypeText == 'OGM' &&
                          data.item.risk_document_id != null
                        "
                      >
                        <template #button-content>
                          <font-awesome-icon icon="ellipsis-v" />
                        </template>
                        <b-dropdown-item-button
                          class="text-secondary"
                          @click="downloadRemediatedOGMReport(data)"
                          >Download</b-dropdown-item-button
                        >
                      </b-dropdown>
                    </template>
                  </b-table>
                  <b-row>
                    <b-col cols="2">
                      <b-form-select
                        v-model="perPage"
                        id="perPageSelect"
                        size="sm"
                        :options="pageOptions"
                      ></b-form-select>
                    </b-col>
                    <b-col>
                      <b-pagination
                        class="float-right"
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="perPage"
                        first-text="First"
                        prev-text="Prev"
                        next-text="Next"
                        last-text="Last"
                        aria-controls="my-table"
                      ></b-pagination>
                    </b-col>
                  </b-row>
                </b-card-body>
                <InputErrorMessage :error="productsError" />
              </b-card>
            </b-overlay>
          </b-tab>

          <b-tab
            :lazy="$store.state.archivedDueDiligenceTabLazyLoad"
            title="Archived Due Diligence Reports"
          >
            <!-- Archived Due Diligence Reports -->
            <ArchivedDueDiligenceReportTab
              :companyId="companyId"
              :thirdPartyId="thirdPartyId"
              :thirdPartyData="thirdPartyData"
            />
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>

    <b-modal
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
      size="lg"
      :title="$t('eb360.dueDiligenceRemediation.addIdd')"
      v-model="showInstantDueDiligenceReportsModal"
    >
      <p>{{ $t("eb360.dueDiligenceRemediation.addModal") }}</p>
    </b-modal>
    <Modal
      v-if="isUploadReportModalVisible"
      title="Add Due Diligence Report"
      @close-modal="closeUploadReportModal"
      class="due-diligence-reports__modal"
    >
      <template v-slot>
        <BaseLoader v-if="isReportModalUploading" />
        <div
          :class="
            isReportModalUploading
              ? 'due-diligence-reports__modal-dropzone'
              : 'due-diligence-reports__modal-template'
          "
        >
          <vue-dropzone
            ref="uploadReportDropzone"
            id="dropzone"
            :options="dropzoneOptions"
            :useCustomSlot="true"
            @vdropzone-sending="sendingEvent"
            @vdropzone-file-added="onUploadPreview"
            @vdropzone-success="vsuccess"
          >
            <BaseText
              class="due-diligence-reports__modal-template-text"
              text="Drag and drop to upload content!"
            />
            <BaseText
              class="due-diligence-reports__modal-template-sub-text"
              text="...or click to select a file from your computer"
            />
          </vue-dropzone>
          <div class="due-diligence-reports__modal-template-dropdown">
            <Select
              id="products"
              name="products"
              label="Select Report Type"
              :options="productsOptions"
              @change="setProduct"
              :error="errorMessages.productId"
            />
          </div>
        </div>
      </template>
      <template v-slot:left>
        <CallToAction
          theme="error"
          value="Close"
          @click="closeUploadReportModal"
        />
      </template>
      <template v-slot:right>
        <CallToAction
          theme="primary"
          value="Submit"
          @click="checkValidation()"
        />
      </template>
    </Modal>
  </div>
</template>

<script>
import { HTTP, makeAuthorizationHeader } from "@/utils";
import moment from "moment";
import "moment/locale/es";
import iconJson from "@/utils/icon.json";
import ArchivedDueDiligenceReportTab from "./archivedDueDiligenceReportTab.vue";
import {
  reportType,
  riskAlertText,
  productType,
  tpRiskLevel,
  remediationText,
  endpoints,
  typographySize,
  validationKeys,
  emptyOption,
  IDD_COLORS,
  themes,
  icons,
  shapes,
  actionStatusValue,
  defaultIcons,
  iconSizes
} from "@/constants";
import BaseText from "@/atoms/BaseText/BaseText";
import Modal from "@/molecules/Modal/Modal";
import vue2Dropzone from "@/utils/VueDropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import { config } from "@/configs";
import { thirdPartyService } from "@/services";
import BaseLoader from "@/atoms/BaseLoader/BaseLoader";
import CallToAction from "@/atoms/CallToAction/CallToAction";
import { requiredIf } from "vuelidate/lib/validators";
import { validate } from "@/mixins";
import Select from "@/molecules/Select/Select";
import { cloneDeep } from "lodash";
import InputErrorMessage from "@/molecules/InputErrorMessage/InputErrorMessage";
import { mapState } from "vuex";
import BaseIcon from "@/atoms/BaseIcon/BaseIcon";

export default {
  name: "DueDiligenceRemediationMonitoringTabComponent",
  mixins: [validate],
  components: {
    ArchivedDueDiligenceReportTab,
    BaseText,
    Modal,
    vueDropzone: vue2Dropzone,
    BaseLoader,
    CallToAction,
    Select,
    InputErrorMessage,
    BaseIcon
  },
  data() {
    return {
      themes,
      icons,
      shapes,
      showInstantDueDiligenceReportsModal: false,
      eb2AppUrl: process.env.VUE_APP_BASE_URL,
      isLoadingInstantDueDiligenceReportsData: false,
      isLoadingRecentMonitoringResultsData: false,
      dueDiligenceReportsData: [],
      recentMonitoringData: [],
      remediatedData: [],
      isLoadingData: false,
      filter: null,
      pageOptions: [5, 10, 25, 50, 100],
      sortBy: "",
      perPage: 5,
      currentPage: 1,
      totalRows: 0,
      instantDueDiligenceReportsItems: [],
      instantDueDiligenceReportsfields: [
        {
          key: "inserted_date",
          label: this.$t("eb360.associateTabComponent.createdAt"),
          sortable: true
        },
        {
          key: "product_type",
          label: this.$t("eb360.documentsTabComponent.type"),
          sortable: true
        },
        {
          key: "initial_alert_status",
          label: this.$t("toast.title"),
          sortable: true
        },
        {
          key: "action",
          label: this.$t("eb360.associateDetailModal.action"),
          sortable: false
        }
      ],
      headerConfigData: null,
      iconData: iconJson,
      reportType,
      remediationText,
      isUploadReportModalVisible: false,
      dropzoneOptions: {
        url: `${config.API_URL}${endpoints.THIRD_PARTY_UPLOAD_ORDER_REPORT}`,
        acceptedFiles: ".pdf",
        thumbnailWidth: 150,
        maxFilesize: 100,
        maxFiles: 1,
        autoProcessQueue: false,
        addRemoveLinks: true,
        headers: makeAuthorizationHeader()
      },
      isReportModalUploading: false,
      form: this.makeFormObj(),
      products: null,
      selectedProduct: null,
      isLoadingProducts: false,
      typographySize,
      productsError: [],
      inputModelList: ["productId"],
      errorMessages: {
        productId: undefined
      },
      productId: null,
      productsOptions: []
    };
  },
  validations: {
    productId: {
      [validationKeys.REQUIRED]: requiredIf(function () {
        return this.isUploadReportModalVisible;
      })
    }
  },
  computed: {
    ...mapState({
      userId: (state) => state.user.userId
    }),
    rows() {
      return this.instantDueDiligenceReportsItems.length;
    }
  },
  mounted() {
    this.isLoadingInstantDueDiligenceReportsData = true;
    this.getheaderConfigData();
    this.getProducts();
  },
  methods: {
    getReportNotProductIcon(selectedProductType, { risk_level, idd_color }) {
      const parseProductType = parseInt(selectedProductType);
      const highAlertIcon = defaultIcons.HIGH_ALERT
      const lowAlertIcon = defaultIcons.CHECK

      if (parseProductType === productType.IDD) {
        return parseInt(risk_level) === tpRiskLevel.HIGH
          ? highAlertIcon
          : lowAlertIcon;
      } else if (parseProductType === productType.IDD_PLUS) {
        return parseInt(idd_color) === IDD_COLORS.GREEN
          ? lowAlertIcon
          : highAlertIcon;
      }
    },
    getReportNotProductIconTitle(
      selectedProductType,
      { risk_level, idd_color }
    ) {
      const parseProductType = parseInt(selectedProductType);
      const highAlertText = riskAlertText.RISK_ALERT_TEXT;
      const lowAlertText = riskAlertText.NO_RISK_ALERT_TEXT;

      if (parseProductType === productType.IDD) {
        return parseInt(risk_level) === tpRiskLevel.HIGH
          ? highAlertText
          : lowAlertText;
      } else if (parseProductType === productType.IDD_PLUS) {
        return parseInt(idd_color) === IDD_COLORS.GREEN
          ? lowAlertText
          : highAlertText;
      }
    },
    makeFormObj() {
      return {
        fileName: null,
        fileType: null
      };
    },
    openUploadModal() {
      this.isUploadReportModalVisible = true;
    },
    closeUploadReportModal() {
      this.resetErrorMessages();
      this.isUploadReportModalVisible = false;
      this.form = this.makeFormObj();
      this.selectedProduct = null;
      this.productId = null;
      this.isReportModalUploading = false;
      this.makeProductOptions();
    },
    setProduct(val) {
      this.productsOptions.forEach((product) => {
        if (parseInt(product.value) === parseInt(val)) {
          product.selected = true;
          this.selectedProduct = product;
        } else {
          delete product.selected;
        }
      });
      this.productId = val;
    },
    vsuccess(_, response) {
      this.$bvToast.toast(response.message, {
        title: "Done",
        noAutoHide: false,
        appendToast: true,
        variant: "success"
      });
      this.getTpDueDeligenceReportStatus();
      this.getProducts();
      this.closeUploadReportModal();
    },
    sendingEvent(_, __, formData) {
      formData.append("thirdPartyId", this.thirdPartyId);
      formData.append("companyId", this.companyId);
      formData.append("memberId", this.userId);
      formData.append("productId", this.selectedProduct.value);
      formData.append("product", this.selectedProduct.text);
    },
    onUploadPreview(file) {
      if (file) {
        this.form.fileName = file.name;
        this.form.fileType = file.type;
      }
    },
    checkValidation() {
      this.validateInputModels(this.inputModelList);
      if (!this.$v.$invalid) {
        if (this.$refs.uploadReportDropzone.getQueuedFiles().length > 0) {
          this.isReportModalUploading = true;
          this.$refs.uploadReportDropzone.processQueue();
        }
      }
    },
    async getProducts() {
      this.isLoadingProducts = true;
      try {
        const { data = {} } = await thirdPartyService.fetchThirdPartyProducts(
          this.thirdPartyId
        );
        this.products = data;
        this.productsError = [];
        this.makeProductOptions();
      } catch (error) {
        this.products = [];
        this.productsError = [
          "Unfortunately something has gone wrong, please try again later.",
          "Status: Thirdparty Product"
        ];
      } finally {
        this.isLoadingProducts = false;
      }
    },
    makeProductOptions() {
      this.productsOptions = [
        {
          ...emptyOption,
          text: "---Report Type---"
        },
        ...cloneDeep(this.products)
      ];
    },
    dateFormat(date) {
      if (date) {
        moment.locale("en");
        return moment.utc(date).local().format("LLLL");
      }
    },
    async getheaderConfigData() {
      try {
        this.isLoading = true;
        let result = await HTTP(
          "get",
          `v1/get-header-config/${this.companyId}/${this.userId}`,
          null,
          makeAuthorizationHeader(),
          null,
          true
        );
        if (result.data && result.status == 200) {
          this.headerConfigData = result.data.data;
          if (
            (this.headerConfigData.autoremidd &&
              this.headerConfigData.autoremidd == 1) ||
            (this.headerConfigData.autoremiddplus &&
              this.headerConfigData.autoremiddplus)
          ) {
            this.instantDueDiligenceReportsfields.push(
              {
                key: "risk_documents_inserted_date",
                label: this.$t("eb360.dueDiligenceRemediation.date"),
                sortable: true
              },
              {
                key: "after_remediation_alert_status",
                label: this.$t("eb360.reviewResult.status"),
                sortable: true
              },
              {
                key: "remediation_action",
                label: this.$t("eb360.associateDetailModal.action"),
                sortable: false
              }
              // { key: "report_status", label: this.$t( 'eb360.reviewResult.status' ), sortable: true }
            );
          }
        }
        this.getTpDueDeligenceReportStatus();
        // this.getTpEDDReports();
        this.isLoading = false;
      } catch {
        this.isLoading = false;
      }
    },
    downloadPdfReport(data) {
      let url = data.item.reportUrl ? data.item.reportUrl : "/";
      let apiUrl = process.env.VUE_APP_API_URL;
      apiUrl = apiUrl.substring(0, apiUrl.length - 4);
      window.open(`${apiUrl}${url}`, "_blank");
      return false;
    },
    isValidReportType(reportTypeText) {
      return (
        reportTypeText === reportType.EDD ||
        reportTypeText === reportType.PRODUCT
      );
    },
    viewPdfReport(data) {
      let url = data.item.reportHtmlUrl ? data.item.reportHtmlUrl : "/";
      let apiUrl = process.env.VUE_APP_API_URL;
      apiUrl = apiUrl.substring(0, apiUrl.length - 4);
      window.open(`${apiUrl}${url}`, "_blank");
      return false;
    },
    downloadRemediatedReport(data) {
      let url = data.item.remediatedReport
        ? data.item.remediatedReport.url
        : "/";
      if (data.item.remediatedReport) {
        let apiUrl = process.env.VUE_APP_API_URL;
        apiUrl = apiUrl.substring(0, apiUrl.length - 4);
        window.open(`${apiUrl}${url}`, "_blank");
        return false;
      }
    },
    downloadRemediatedOGMReport(data) {
      if (data.item.remediation_date) {
        let url = data.item.remediatedReport
          ? data.item.remediatedReport.url
          : "/";
        if (data.item.remediatedReport) {
          let apiUrl = process.env.VUE_APP_API_URL;
          apiUrl = apiUrl.substring(0, apiUrl.length - 4);
          window.open(`${apiUrl}${url}`, "_blank");
          return false;
        }
      } else {
        let url = data.item.reportUrl ? data.item.reportUrl : "/";
        let apiUrl = process.env.VUE_APP_API_URL;
        apiUrl = apiUrl.substring(0, apiUrl.length - 4);
        window.open(`${apiUrl}${url}`, "_blank");
        return false;
      }
    },
    async getTpDueDeligenceReportStatus() {
      let formData = {
        thirdPartyId: this.thirdPartyId,
        memberId: this.userId,
        srch_id: this.thirdPartyData?.srch_tpis_latest?.id,
        company_id: this.thirdPartyData.comp_id
      };
      try {
        this.isLoadingInstantDueDiligenceReportsData = true;
        let result = await HTTP(
          "post",
          "thirdparty/instant/reports",
          formData,
          makeAuthorizationHeader()
        );
        if (result && result.data.success == 1 && result.status == 200) {
          let eddReports =
            result.data.eddReports && result.data.eddReports.length
              ? result.data.eddReports
              : [];
          let ogmReports =
            result.data.ogmReports && result.data.ogmReports.length
              ? result.data.ogmReports
              : [];
          const productReport = result.data?.productReport || [];
          this.instantDueDiligenceReportsItems = result.data.tpi_reports;
          if (eddReports && eddReports.length) {
            this.instantDueDiligenceReportsItems.push(...eddReports);
          }
          if (productReport?.length) {
            this.instantDueDiligenceReportsItems.push(...productReport);
          }
          if (
            this.headerConfigData.specialFeature.autoremiddplusmoni &&
            parseInt(this.headerConfigData.specialFeature.autoremiddplusmoni) ==
              1 &&
            ogmReports &&
            ogmReports.length
          ) {
            this.instantDueDiligenceReportsItems.push(...ogmReports);
          }
        } else {
          this.instantDueDiligenceReportsItems = [];
        }
        this.$store.state.dueDiligenceTabLazyLoad = false;
        this.removeItems();
      } catch (error) {
        this.isLoadingInstantDueDiligenceReportsData = false;
      }
    },
    removeItems() {
      let riskLevel = this.thirdPartyData.risk_level;
      let iddColor = this.thirdPartyData.idd_color;
      if (
        (riskLevel == 0 || riskLevel == "0") &&
        this.instantDueDiligenceReportsItems.length > 0
      ) {
        for (
          let i = this.instantDueDiligenceReportsItems.length - 1;
          i >= 0;
          i--
        ) {
          if (this.instantDueDiligenceReportsItems[i].reportTypeText == "Idd") {
            this.instantDueDiligenceReportsItems.splice(i, 1);
          }
        }
      }

      if (
        (iddColor == 0 || iddColor == "0") &&
        this.instantDueDiligenceReportsItems.length > 0
      ) {
        for (
          let i = this.instantDueDiligenceReportsItems.length - 1;
          i >= 0;
          i--
        ) {
          if (
            this.instantDueDiligenceReportsItems[i].reportTypeText == "Idd Plus"
          ) {
            this.instantDueDiligenceReportsItems.splice(i, 1);
          }
        }
      }
      this.isLoadingInstantDueDiligenceReportsData = false;
      this.$store.state.dueDiligenceRemediationMonitoringTabLazyLoad = false;
    },
    getIcon(actionStatus) {
      if (actionStatus === actionStatusValue.OK) {
        return {
          icon: icons.CHECK,
          shape: shapes.CIRCLE,
          theme: themes.SUCCESS_INVERSE_WHITE,
          size: iconSizes.MEDIUM
        };
      }

      return {
        icon: icons.EXCLAMATION,
        shape: shapes.TRIANGLE,
        theme: themes.ERROR,
        size: iconSizes.MEDIUM
      };
    }
  },
  props: {
    companyId: Number,
    isDisabled: Boolean,
    thirdPartyId: Number,
    thirdPartyData: Object
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/custom.scss";

.due-diligence-reports {
  &:deep(.due-diligence-reports__modal .modal) {
    max-width: 700px;
  }
  &:deep(.due-diligence-reports__add-button) {
    margin-right: 8px;
  }
  &:deep(.due-diligence-reports__modal-template-text) {
    margin-bottom: 0.5rem;
    font-size: 1.75rem;
    line-height: 1.2;
    color: $spanish-gray;
  }
  &:deep(.due-diligence-reports__modal-template-sub-text) {
    color: $spanish-gray;
  }
  &__modal {
    &-template {
      max-height: calc(100vh - 370px);
      overflow-y: auto;
      padding: 20px;
      &-dropdown {
        margin: 20px 0px;
      }
    }
    &-dropzone {
      opacity: 0;
    }
  }
}

.page-item .page-link {
  color: $black-olive;
}
.page-item.active .page-link {
  background-color: $black-olive !important;
  border-color: $black-olive !important;
  color: $white !important;
}
.cardBodyClass {
  padding: 0px;
}
</style>
