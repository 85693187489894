<template>
  <div class="tp-profile-header">
    <div class="d-flex">
      <div class="tp-profile-header__icon">
        <BaseIcon :icon="getIcon" :size="80" />
      </div>
      <div class="text-left ml-5">
        <div>
          <h2 class="d-flex">
            <strong data-test-id="third-party-profile__heading">
              {{ thirdPartyData.comp_name_eng || "" }}
            </strong>
            <p class="ml-2" v-if="isDisabled">*Archived*</p>
          </h2>
          <a
            class="tp-profile-header__link"
            :href="thirdPartyHref()"
            target="_blank"
          >
            {{ thirdPartyData.website || "" }}
          </a>
          <p data-test-id="third-party-profile__country">
            {{ thirdPartyData.country || "" }}
          </p>
        </div>
      </div>
      <div class="ml-auto">
        <b-btn v-if="isDisabled" class="tp-profile-header__button">
          <font-awesome-icon
            class="edit-icon-style"
            v-b-tooltip.hover
            title="Configure third party"
            size="4x"
            icon="pen"
          />
        </b-btn>
        <BaseLoader v-else-if="isLoading" />
        <font-awesome-icon
          v-else
          v-b-tooltip.hover
          title="Configure third party"
          class="edit-icon-style"
          size="4x"
          icon="pen"
          @click="onEditThirdPartyModal"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { thirdPartyType, actionName, icons } from "@/constants";
import { mapState, mapActions } from "vuex";
import BaseLoader from "@/atoms/BaseLoader/BaseLoader.vue";
import BaseIcon from "@/atoms/BaseIcon/BaseIcon";

export default {
  name: "ThirdPartyProfileHeader",
  components: {
    BaseLoader,
    BaseIcon
  },
  data() {
    return {
      isLoading: false
    };
  },
  props: {
    thirdPartyData: {
      type: Object,
      default: () => ({})
    },
    isDisabled: {
      type: Boolean,
      isRequire: true
    },
    isDnbModal: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    ...mapState({
      isAddThirdPartyDnbModalVisible: (state) =>
        state.thirdParty.isAddThirdPartyDnbModalVisible,
      isAddThirdPartyModalNewVisible: (state) =>
        state.thirdParty.isAddThirdPartyModalNewVisible
    }),
    getIcon() {
      const icon =
        this.thirdPartyData?.tpi_type === thirdPartyType.INDIVIDUAL
          ? icons.USER
          : icons.BUILDING;

      return icon;
    }
  },
  watch: {
    isAddThirdPartyDnbModalVisible(){
      this.isLoading = false;
    },
    isAddThirdPartyModalNewVisible(){
      this.isLoading = false;
    }
  },
  methods: {
    ...mapActions({
      fetchPlatformFeature: actionName.THIRD_PARTY.FETCH_PLATFORM_FEATURE
    }),
    thirdPartyHref() {
      const { website } = this.thirdPartyData;
      if (website) {
        return website.match(/https/g) ? website : `https://${website}`;
      } else {
        return "";
      }
    },
    onEditThirdPartyModal(){
      this.isLoading = true;
      this.fetchPlatformFeature(this.thirdPartyData);
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/custom.scss";

.tp-profile-header {

  &__link {
    overflow-wrap: anywhere;
    width: 90%;
  }

  &__button {
    background-color: $white;
    border-color: $white;
  }
}
</style>
