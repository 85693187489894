var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"email-notification-step"},[_c('SearchSelect',{staticClass:"email-notification-step--template-name",attrs:{"label":"User email templates","placeholder":"Select...","name":"user-email-template-name","id":`user-email-template-name-${_vm.id}`,"options":_vm.emailNotificationOptions,"error":_vm.emailNotificationError,"searchValue":_vm.emailNotificationSearchValue,"data-test-id":"user-email-template-name"},on:{"change":function($event){return _vm.$emit('change', {
        name: _vm.actionKeys.TEMPLATE_ID,
        id: _vm.id,
        event: $event
      })},"create":function($event){return _vm.$emit('create', {
        name: _vm.actionKeys.TEMPLATE_ID,
        id: _vm.id,
        event: $event
      })},"reset":function($event){return _vm.$emit('reset', {
        name: _vm.actionKeys.TEMPLATE_ID,
        id: _vm.id,
        event: $event
      })}}}),(_vm.isEmailNotificationSelected)?[(_vm.isExpectedDataAvailable)?_c('div',{staticClass:"email-notification-step__expected-data",attrs:{"data-test-id":"email-notification-step__expected-data"}},[_c('BaseText',{attrs:{"tag":"h3","size":_vm.typographySize.BODY_TEXT_BOLD,"text":"Expected data"}}),_c('ul',{staticClass:"email-notification-step__expected-data-list",attrs:{"data-test-id":"email-notification-step__expected-data-list"}},_vm._l((_vm.emailNotificationExpectedData),function(expectedData){return _c('li',{key:expectedData.id,staticClass:"email-notification-step__expected-data-list-element",attrs:{"data-test-id":"email-notification-step__expected-data-list-element"}},[_c('div',{staticClass:"email-notification-step__expected-data-list-element--name"},[_c('BaseText',{attrs:{"text":expectedData.emailNotificationExpectedDataName}})],1),_c('SetToBlock',_vm._b({staticClass:"email-notification-step__expected-data-list-element--set-to-block",attrs:{"data-test-id":"email-notification-step__expected-data-list-element--set-to-block"},on:{"change":function($event){return _vm.$emit('change', {
                name: _vm.actionKeys.EXPECTED_DATA_VARIABLE,
                id: expectedData.id,
                event: $event
              })}}},'SetToBlock',expectedData,false))],1)}),0)],1):_vm._e(),_c('div',{staticClass:"email-notification-step__email-destination",attrs:{"data-test-id":"email-notification-step__email-destination"}},[_c('BaseText',{attrs:{"tag":"h3","size":_vm.typographySize.BODY_TEXT_BOLD,"text":"Email destination"}}),_c('MultiSelect',{attrs:{"id":"email-notification-step-destination-to","label":"To","options":_vm.emailNotificationToDestinationOptions,"name":"email-notification-step-destination-to","description":"Select to email destination","showAllSelectedOptions":true,"error":_vm.emailDestinationToError},on:{"change":function($event){return _vm.$emit('change', {
            name: _vm.actionKeys.TO,
            id: _vm.id,
            event: $event
          })}}}),_c('MultiSelect',{attrs:{"id":"email-notification-step-destination-cc","label":"Cc","options":_vm.emailNotificationCcDestinationOptions,"name":"email-notification-step-destination-cc","description":"Select cc email destination","showAllSelectedOptions":true,"error":_vm.emailDestinationCcError,"isRequired":false},on:{"change":function($event){return _vm.$emit('change', {
            name: _vm.actionKeys.CC,
            id: _vm.id,
            event: $event
          })}}}),_c('MultiSelect',{attrs:{"id":"email-notification-step-destination-bcc","label":"Bcc","options":_vm.emailNotificationBccDestinationOptions,"name":"email-notification-step-destination-bcc","description":"Select bcc email destination","showAllSelectedOptions":true,"error":_vm.emailDestinationBccError,"isRequired":false},on:{"change":function($event){return _vm.$emit('change', {
            name: _vm.actionKeys.BCC,
            id: _vm.id,
            event: $event
          })}}})],1)]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }