import {
  actionKeys,
  actionKeysApi,
  variableType,
  variableAssignmentType,
  variableScope,
  userEmailTemplatesDestinationTypes,
  actionStepType
} from "@/constants";
import {
  makeUserEmailTemplateDestinationEmailDTO,
  makeUserEmailTemplateDestinationRoleDTO,
  makeUserEmailTemplateDestinationUserDTO
} from "@/services/userEmailTemplates/userEmailTemplates.dto";
import { makeUUID, getSelectedOption } from "@/utils";

const makeUserEmailTemplateDestinationLocalVariableAPIDTO = ({
  valueDataType,
  value
}) => ({
  [actionKeysApi.DATA]: {
    [actionKeysApi.DATA]: {
      [actionKeysApi.DATA]: {
        [actionKeysApi.NAME]: value
      },
      [actionKeysApi.TYPE]: valueDataType
    },
    [actionKeysApi.TYPE]: variableScope.LOCAL
  },
  [actionKeysApi.TYPE]: userEmailTemplatesDestinationTypes.VARIABLE
});

const makeUserEmailTemplateDestinationEntityVariableAPIDTO = ({
  value = "",
  entities = []
} = {}) => {
  const entity = entities?.find(({ data }) => data.data.name === value) || {};
  return {
    [actionKeysApi.DATA]: {
      [actionKeysApi.DATA]: {
        [actionKeysApi.DATA]: {
          ...(!!entity && entity?.data?.data)
        },
        [actionKeysApi.TYPE]: entity?.data?.type
      },
      [actionKeysApi.TYPE]: entity?.type
    },
    [actionKeysApi.TYPE]: userEmailTemplatesDestinationTypes.VARIABLE
  };
};

const makeUserEmailTemplateDestinationVariableDTO = (emailDestination) => ({
  [actionKeys.TYPE]: emailDestination?.[actionKeysApi.TYPE],
  [actionKeys.DATA]: {
    [actionKeys.DATA]: {
      [actionKeys.DATA]: {
        [actionKeys.NAME]:
          emailDestination?.[actionKeysApi.DATA]?.[actionKeysApi.DATA]?.[
            actionKeysApi.DATA
          ]?.[actionKeysApi.NAME]
      },
      [actionKeys.TYPE]:
        emailDestination?.[actionKeysApi.DATA]?.[actionKeysApi.DATA]?.[
          actionKeysApi.TYPE
        ]
    },
    [actionKeys.TYPE]:
      emailDestination?.[actionKeysApi.DATA]?.[actionKeysApi.TYPE]
  }
});

const makeNotificationEmailExpectedDataLiteralDTO = (expectedData) => ({
  [actionKeys.ID]: makeUUID(),
  [actionKeys.TRIGGER_DATA]: {
    [actionKeys.DATA]: {
      [actionKeys.DATA]: {
        [actionKeys.VALUE]:
          expectedData?.[actionKeysApi.TRIGGER_DATA]?.[actionKeysApi.DATA]?.[
            actionKeysApi.DATA
          ]?.[actionKeysApi.VALUE]
      },
      [actionKeys.TYPE]:
        expectedData?.[actionKeysApi.TRIGGER_DATA]?.[actionKeysApi.DATA]?.[
          actionKeysApi.TYPE
        ] || variableType.STRING
    },
    [actionKeys.TYPE]:
      expectedData?.[actionKeysApi.TRIGGER_DATA]?.[actionKeysApi.TYPE] ||
      variableAssignmentType.LITERAL
  },
  [actionKeys.TRIGGERED_EXPECTED_DATA_VARIABLE_NAME]:
    expectedData?.[actionKeysApi.TRIGGERED_EXPECTED_DATA_VARIABLE_NAME] || ""
});
const makeNotificationEmailExpectedDataLiteralAPIDTO = ({
  value = "",
  variableName = "",
  valueType = "",
  valueDataType = ""
} = {}) => ({
  [actionKeysApi.TRIGGER_DATA]: {
    [actionKeysApi.DATA]: {
      [actionKeysApi.DATA]: {
        [actionKeysApi.VALUE]: value
      },
      [actionKeysApi.TYPE]: valueDataType
    },
    [actionKeysApi.TYPE]: valueType
  },
  [actionKeysApi.TRIGGERED_EXPECTED_DATA_VARIABLE_NAME]: variableName
});

const makeNotificationEmailExpectedDataVariableLocalDTO = (data) => ({
  [actionKeys.ID]: makeUUID(),
  [actionKeys.TRIGGER_DATA]: {
    [actionKeys.DATA]: {
      [actionKeys.DATA]: {
        [actionKeys.DATA]: {
          [actionKeys.NAME]:
            data?.[actionKeysApi.TRIGGER_DATA]?.[actionKeysApi.DATA]?.[
              actionKeysApi.DATA
            ]?.[actionKeysApi.DATA]?.[actionKeysApi.NAME]
        },
        [actionKeys.TYPE]:
          data?.[actionKeysApi.TRIGGER_DATA]?.[actionKeysApi.DATA]?.[
            actionKeysApi.DATA
          ]?.[actionKeysApi.TYPE] || ""
      },
      [actionKeys.TYPE]:
        data?.[actionKeysApi.TRIGGER_DATA]?.[actionKeysApi.DATA]?.[
          actionKeysApi.TYPE
        ] || variableScope.LOCAL
    },
    [actionKeys.TYPE]:
      data?.[actionKeysApi.TRIGGER_DATA]?.[actionKeysApi.TYPE] ||
      variableType.VARIABLE
  },
  [actionKeys.TRIGGERED_EXPECTED_DATA_VARIABLE_NAME]:
    data?.[actionKeysApi.TRIGGERED_EXPECTED_DATA_VARIABLE_NAME]
});
const makeNotificationEmailExpectedDataVariableLocalAPIDTO = ({
  variableName = "",
  value = "",
  valueType = "",
  valueDataType = ""
} = {}) => ({
  [actionKeysApi.TRIGGER_DATA]: {
    [actionKeysApi.DATA]: {
      [actionKeysApi.DATA]: {
        [actionKeysApi.DATA]: {
          [actionKeysApi.NAME]: value
        },
        [actionKeysApi.TYPE]: valueDataType
      },
      [actionKeysApi.TYPE]: valueType
    },
    [actionKeysApi.TYPE]: variableType.VARIABLE
  },
  [actionKeysApi.TRIGGERED_EXPECTED_DATA_VARIABLE_NAME]: variableName
});

const makeNotificationEmailExpectedDataVariableEntityDTO = (expectedData) => ({
  [actionKeys.ID]: makeUUID(),
  [actionKeys.TRIGGER_DATA]: {
    [actionKeys.DATA]: {
      [actionKeys.DATA]: {
        [actionKeys.DATA]: {
          [actionKeys.NAME]:
            expectedData?.[actionKeysApi.TRIGGER_DATA]?.[actionKeysApi.DATA]?.[
              actionKeysApi.DATA
            ]?.[actionKeysApi.DATA]?.[actionKeysApi.NAME]
        },
        [actionKeys.TYPE]:
          expectedData?.[actionKeysApi.TRIGGER_DATA]?.[actionKeysApi.DATA]?.[
            actionKeysApi.DATA
          ]?.[actionKeysApi.TYPE] || ""
      },
      [actionKeys.TYPE]:
        expectedData?.[actionKeysApi.TRIGGER_DATA]?.[actionKeysApi.DATA]?.[
          actionKeysApi.TYPE
        ] || variableScope.ENTITY
    },
    [actionKeys.TYPE]:
      expectedData?.[actionKeysApi.TRIGGER_DATA]?.[actionKeysApi.TYPE] ||
      variableType.VARIABLE
  },
  [actionKeys.TRIGGERED_EXPECTED_DATA_VARIABLE_NAME]:
    expectedData?.[actionKeysApi.TRIGGERED_EXPECTED_DATA_VARIABLE_NAME]
});
const makeNotificationEmailExpectedDataVariableEntityAPIDTO = ({
  entity = {},
  valueDataType = "",
  valueType = "",
  variableName = ""
} = {}) => ({
  [actionKeysApi.TRIGGER_DATA]: {
    [actionKeysApi.DATA]: {
      [actionKeysApi.DATA]: {
        [actionKeysApi.DATA]: {
          ...(!!entity && entity?.data?.data)
        },
        [actionKeysApi.TYPE]: valueDataType
      },
      [actionKeysApi.TYPE]: valueType
    },
    [actionKeysApi.TYPE]: variableType.VARIABLE
  },
  [actionKeysApi.TRIGGERED_EXPECTED_DATA_VARIABLE_NAME]: variableName
});

const makeNotificationEmailExpectedDataVariableAPIDTO = ({
  value,
  variableName,
  valueDataType,
  valueType,
  entities,
  _makeNotificationEmailExpectedDataVariableLocalAPIDTO = makeNotificationEmailExpectedDataVariableLocalAPIDTO,
  _makeNotificationEmailExpectedDataVariableEntityAPIDTO = makeNotificationEmailExpectedDataVariableEntityAPIDTO
}) => {
  const entity = entities.find((entity) => entity.data.data.name === value);
  if (entity) {
    return _makeNotificationEmailExpectedDataVariableEntityAPIDTO({
      entity,
      variableName,
      valueDataType,
      valueType
    });
  } else {
    return _makeNotificationEmailExpectedDataVariableLocalAPIDTO({
      value,
      variableName,
      valueDataType,
      valueType: variableScope.LOCAL
    });
  }
};
const makeNotificationEmailExpectedDataVariableDTO = ({
  expectedData,
  _makeNotificationEmailExpectedDataVariableLocalDTO = makeNotificationEmailExpectedDataVariableLocalDTO,
  _makeNotificationEmailExpectedDataVariableEntityDTO = makeNotificationEmailExpectedDataVariableEntityDTO
}) => {
  if (
    expectedData?.[actionKeysApi.TRIGGER_DATA]?.[actionKeysApi.DATA]?.[
      actionKeysApi.TYPE
    ] === variableScope.LOCAL
  ) {
    return _makeNotificationEmailExpectedDataVariableLocalDTO(expectedData);
  } else if (
    expectedData?.[actionKeysApi.TRIGGER_DATA]?.[actionKeysApi.DATA]?.[
      actionKeysApi.TYPE
    ] === variableScope.ENTITY
  ) {
    return _makeNotificationEmailExpectedDataVariableEntityDTO(expectedData);
  }
};

const makeNotificationEmailExpectedDataAPIDTO = ({
  expectedData,
  entities,
  _makeNotificationEmailExpectedDataVariableAPIDTO = makeNotificationEmailExpectedDataVariableAPIDTO,
  _makeNotificationEmailExpectedDataLiteralAPIDTO = makeNotificationEmailExpectedDataLiteralAPIDTO
} = {}) => {
  const { value: valueType } = expectedData.getSelectedValueTypeOption();

  if (valueType === variableAssignmentType.ENTITY) {
    return _makeNotificationEmailExpectedDataVariableAPIDTO({
      value: expectedData.componentValue,
      variableName: expectedData.emailNotificationExpectedDataName,
      valueDataType: expectedData.valueDataType,
      valueType,
      entities
    });
  } else if (valueType === variableAssignmentType.LITERAL) {
    return _makeNotificationEmailExpectedDataLiteralAPIDTO({
      value: expectedData.componentValue,
      variableName: expectedData.emailNotificationExpectedDataName,
      valueDataType: expectedData.valueDataType,
      valueType
    });
  }
};
const makeNotificationEmailExpectedDataDTO = ({
  expectedData,
  _makeNotificationEmailExpectedDataVariableDTO = makeNotificationEmailExpectedDataVariableDTO,
  _makeNotificationEmailExpectedDataLiteralDTO = makeNotificationEmailExpectedDataLiteralDTO
}) => {
  if (
    expectedData?.[actionKeysApi.TRIGGER_DATA]?.[actionKeysApi.TYPE] ===
    variableType.VARIABLE
  ) {
    return _makeNotificationEmailExpectedDataVariableDTO({ expectedData });
  } else if (
    expectedData?.[actionKeysApi.TRIGGER_DATA]?.[actionKeysApi.TYPE] ===
    variableAssignmentType.LITERAL
  ) {
    return _makeNotificationEmailExpectedDataLiteralDTO(expectedData);
  }
};

const makeEmailNotificationDestinationEmailAPIDTO = (emailDestination) => ({
  [actionKeysApi.TYPE]: userEmailTemplatesDestinationTypes.EMAIL,
  [actionKeysApi.VALUE]: { [actionKeysApi.EMAIL]: emailDestination }
});

const makeEmailNotificationDestinationRoleAPIDTO = ({ roleId, companyId }) => ({
  [actionKeysApi.TYPE]: userEmailTemplatesDestinationTypes.ROLE,
  [actionKeysApi.VALUE]: {
    [actionKeysApi.COMPANY_ID]: companyId,
    [actionKeysApi.ROLE_ID]: roleId
  }
});

const makeEmailNotificationDestinationUserAPIDTO = (userId) => ({
  [actionKeysApi.TYPE]: userEmailTemplatesDestinationTypes.USER,
  [actionKeysApi.VALUE]: { [actionKeysApi.USER_ID]: userId }
});

const makeNotificationEmailDestinationAPIDTO = ({
  emailDestinations = [],
  entities = [],
  _makeUserEmailTemplateDestinationEmailAPIDTO = makeEmailNotificationDestinationEmailAPIDTO,
  _makeUserEmailTemplateDestinationRoleAPIDTO = makeEmailNotificationDestinationRoleAPIDTO,
  _makeUserEmailTemplateDestinationUserAPIDTO = makeEmailNotificationDestinationUserAPIDTO,
  _makeUserEmailTemplateDestinationEntityVariableAPIDTO = makeUserEmailTemplateDestinationEntityVariableAPIDTO,
  _makeUserEmailTemplateDestinationLocalVariableAPIDTO = makeUserEmailTemplateDestinationLocalVariableAPIDTO
} = {}) =>
  emailDestinations?.reduce((acc, emailDestination) => {
    const { type, isEntity, isVariable, companyId, selected, value } =
      emailDestination;
    if (selected) {
      if (type === userEmailTemplatesDestinationTypes.EMAIL) {
        acc.push(_makeUserEmailTemplateDestinationEmailAPIDTO(value));
      } else if (type === userEmailTemplatesDestinationTypes.ROLE) {
        acc.push(
          _makeUserEmailTemplateDestinationRoleAPIDTO({
            roleId: value,
            companyId
          })
        );
      } else if (type === userEmailTemplatesDestinationTypes.USER) {
        acc.push(_makeUserEmailTemplateDestinationUserAPIDTO(value));
      } else if (isVariable) {
        if (isEntity) {
          acc.push(
            _makeUserEmailTemplateDestinationEntityVariableAPIDTO({
              value,
              entities
            })
          );
        } else {
          acc.push(
            _makeUserEmailTemplateDestinationLocalVariableAPIDTO({
              value,
              valueDataType: type
            })
          );
        }
      }
    }

    return acc;
  }, []) || [];
const makeNotificationEmailDestinationDTO = ({
  emailDestinations = [],
  _makeUserEmailTemplateDestinationEmailDTO = makeUserEmailTemplateDestinationEmailDTO,
  _makeUserEmailTemplateDestinationRoleDTO = makeUserEmailTemplateDestinationRoleDTO,
  _makeUserEmailTemplateDestinationUserDTO = makeUserEmailTemplateDestinationUserDTO,
  _makeUserEmailTemplateDestinationVariableDTO = makeUserEmailTemplateDestinationVariableDTO
} = {}) =>
  emailDestinations?.map((emailDestination) => {
    if (
      emailDestination[actionKeysApi.TYPE] ===
      userEmailTemplatesDestinationTypes.EMAIL
    ) {
      return _makeUserEmailTemplateDestinationEmailDTO(emailDestination);
    } else if (
      emailDestination[actionKeysApi.TYPE] ===
      userEmailTemplatesDestinationTypes.ROLE
    ) {
      return _makeUserEmailTemplateDestinationRoleDTO(emailDestination);
    } else if (
      emailDestination[actionKeysApi.TYPE] ===
      userEmailTemplatesDestinationTypes.USER
    ) {
      return _makeUserEmailTemplateDestinationUserDTO(emailDestination);
    } else if (emailDestination[actionKeysApi.TYPE] === variableType.VARIABLE) {
      return _makeUserEmailTemplateDestinationVariableDTO(emailDestination);
    }
  }) || [];

const makeNotificationEmailStepAPIDTO = ({
  functionStep = {},
  entities = [],
  _makeNotificationEmailExpectedDataAPIDTO = makeNotificationEmailExpectedDataAPIDTO,
  _makeNotificationEmailDestinationAPIDTO = makeNotificationEmailDestinationAPIDTO
} = {}) => ({
  [actionKeysApi.COMMENT]: functionStep?.comment,
  [actionKeysApi.STEP_TYPE]: actionStepType.NOTIFICATION,
  [actionKeysApi.SPECIFICATION]: {
    [actionKeysApi.TYPE]: functionStep?.stepType,
    [actionKeysApi.TEMPLATE_ID]: getSelectedOption(
      functionStep?.emailNotificationOptions
    )?.value,
    [actionKeysApi.EXPECTED_DATA_MAPPING]:
      functionStep?.emailNotificationExpectedData.map((expectedData) =>
        _makeNotificationEmailExpectedDataAPIDTO({ expectedData, entities })
      ),
    [actionKeysApi.TO]: _makeNotificationEmailDestinationAPIDTO({
      emailDestinations: functionStep.emailNotificationToDestinationOptions,
      entities
    }),
    [actionKeysApi.CC]: _makeNotificationEmailDestinationAPIDTO({
      emailDestinations: functionStep.emailNotificationCcDestinationOptions,
      entities
    }),
    [actionKeysApi.BCC]: _makeNotificationEmailDestinationAPIDTO({
      emailDestinations: functionStep.emailNotificationBccDestinationOptions,
      entities
    })
  }
});
const makeNotificationEmailStepDTO = ({
  functionStep = {},
  _makeNotificationEmailExpectedDataDTO = makeNotificationEmailExpectedDataDTO,
  _makeNotificationEmailDestinationDTO = makeNotificationEmailDestinationDTO
}) => ({
  [actionKeys.COMMENT]: functionStep?.[actionKeysApi.COMMENT],
  [actionKeys.STEP_TYPE]:
    functionStep?.[actionKeysApi.SPECIFICATION]?.[actionKeysApi.TYPE],
  [actionKeys.SPECIFICATION]: {
    [actionKeys.TYPE]: functionStep?.[actionKeysApi.STEP_TYPE],
    [actionKeys.TEMPLATE_ID]:
      functionStep?.[actionKeysApi.SPECIFICATION]?.[actionKeysApi.TEMPLATE_ID],
    [actionKeys.EXPECTED_DATA_MAPPING]: functionStep?.[
      actionKeysApi.SPECIFICATION
    ]?.[actionKeysApi.EXPECTED_DATA_MAPPING].map((expectedData) =>
      _makeNotificationEmailExpectedDataDTO({ expectedData })
    ),
    [actionKeys.TO]: _makeNotificationEmailDestinationDTO({
      emailDestinations:
        functionStep?.[actionKeysApi.SPECIFICATION]?.[actionKeysApi.TO]
    }),
    [actionKeys.CC]: _makeNotificationEmailDestinationDTO({
      emailDestinations:
        functionStep?.[actionKeysApi.SPECIFICATION]?.[actionKeysApi.CC]
    }),
    [actionKeys.BCC]: _makeNotificationEmailDestinationDTO({
      emailDestinations:
        functionStep?.[actionKeysApi.SPECIFICATION]?.[actionKeysApi.BCC]
    })
  }
});

export {
  makeUserEmailTemplateDestinationLocalVariableAPIDTO,
  makeUserEmailTemplateDestinationEntityVariableAPIDTO,
  makeUserEmailTemplateDestinationVariableDTO,
  makeNotificationEmailStepDTO,
  makeNotificationEmailStepAPIDTO,
  makeNotificationEmailExpectedDataDTO,
  makeNotificationEmailExpectedDataAPIDTO,
  makeNotificationEmailDestinationDTO,
  makeNotificationEmailDestinationAPIDTO,
  makeNotificationEmailExpectedDataLiteralDTO,
  makeNotificationEmailExpectedDataLiteralAPIDTO,
  makeNotificationEmailExpectedDataVariableLocalDTO,
  makeNotificationEmailExpectedDataVariableLocalAPIDTO,
  makeNotificationEmailExpectedDataVariableEntityDTO,
  makeNotificationEmailExpectedDataVariableEntityAPIDTO,
  makeNotificationEmailExpectedDataVariableAPIDTO,
  makeNotificationEmailExpectedDataVariableDTO
};
