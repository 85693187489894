<template>
  <div
    class="ultimate-beneficial-owners"
    data-test-id="ultimate-beneficial-owners"
  >
    <InputErrorMessage
      v-if="showCreateThirdPartyError"
      error="Something went wrong, please try again. If the issue persists please contact support."
    />
    <BaseLoader v-if="isUBODataLoading" />
    <DataTable
      v-else-if="hasTableRows"
      :ag-grid-options="agGridOptions"
      :search-filter-bar-options="searchFilterOptions"
      grid-height="calc(100vh - 440px)"
      ref="data-table"
      @gridReady="onGridReady"
      @click="onDataTableClick"
    />
    <div
      v-else
      class="ultimate-beneficial-owners__not-available-text"
      data-test-id="ultimate-beneficial-owners__not-available-text"
    >
      <BaseText :text="thirdPartyUBOError" :theme="themes.ERROR_INVERSE" />
    </div>
  </div>
</template>

<script>
import BaseText from "@/atoms/BaseText/BaseText";
import {
  actionName,
  dataTableEvents,
  mutationName,
  shapes,
  themes,
  ultimateBeneficialOwnersHeaders,
  ultimateBeneficialOwnersKeysDTO,
  ultimateBeneficialOwnersTableColumnKeys,
  timers
} from "@/constants";
import DataTable from "@/organisms/DataTable/DataTable";
import Checkbox from "@/molecules/Checkbox/Checkbox";
import {
  getRiskIconBasedOnStatus,
  makeUUID,
  sizeColumnsToGridWidth
} from "@/utils";
import CallToAction from "@/atoms/CallToAction/CallToAction";
import BaseLoader from "@/atoms/BaseLoader/BaseLoader";
import { mapActions, mapMutations, mapState } from "vuex";
import { isEqual } from "lodash";
import InputErrorMessage from "@/molecules/InputErrorMessage/InputErrorMessage";
import {
  makeUBOQueryParameters,
  navigateToUBOProfile,
  orderIDD,
  updateAllRowSelections,
  updateRowSelection
} from "@/organisms/UltimateBeneficialOwnersList/UltimateBeneficialOwnersList.logic";
import IconWithText from "@/molecules/IconWithText/IconWithText";

export default {
  name: "UltimateBeneficialOwners",
  components: {
    InputErrorMessage,
    BaseLoader,
    DataTable,
    BaseText
  },
  data() {
    return {
      gridAPI: null,
      agGridOptions: {
        gridOptions: {
          enableBrowserTooltips: true,
          suppressRowClickSelection: true,
          rowSelection: "multiple",
          suppressAutoSizing: true
        },
        rowData: [],
        columnDefs: [],
        getRowId: (params) => params.data.id,
        rowHeight: 90
      },
      searchFilterOptions: {
        actionButtons: [
          {
            id: dataTableEvents.ORDER_IDD,
            value: "Order IDD",
            isLoading: false,
            isSuccess: false,
            isError: false,
            isDisabled: true
          }
        ]
      },
      themes,
      showCreateThirdPartyError: false,
      columnInitialWidthMap: {
        [ultimateBeneficialOwnersTableColumnKeys.SELECT]: 75,
        [ultimateBeneficialOwnersTableColumnKeys.BENEFICIARY_TYPE]: 135,
        [ultimateBeneficialOwnersTableColumnKeys.BENEFICIARY_OWNERSHIP_PERCENTAGE]: 190,
        [ultimateBeneficialOwnersTableColumnKeys.DIRECT_OWNERSHIP_PERCENTAGE]: 175,
        [ultimateBeneficialOwnersTableColumnKeys.INDIRECT_OWNERSHIP_PERCENTAGE]: 175,
        [ultimateBeneficialOwnersTableColumnKeys.PERSON_OF_SIGNIFICANT_CONTROL]: 175,
        [ultimateBeneficialOwnersTableColumnKeys.VIEW_PROFILE]: 90,
        [ultimateBeneficialOwnersTableColumnKeys.DEGREE_OF_SEPARATION]: 120,
        [ultimateBeneficialOwnersTableColumnKeys.COUNTRY]: 200,
        [ultimateBeneficialOwnersTableColumnKeys.RISK_STATUS]: 140
      },
      numberColumnHeaders: [
        ultimateBeneficialOwnersTableColumnKeys.BENEFICIARY_OWNERSHIP_PERCENTAGE,
        ultimateBeneficialOwnersTableColumnKeys.DIRECT_OWNERSHIP_PERCENTAGE,
        ultimateBeneficialOwnersTableColumnKeys.INDIRECT_OWNERSHIP_PERCENTAGE,
        ultimateBeneficialOwnersTableColumnKeys.DEGREE_OF_SEPARATION
      ]
    };
  },
  props: {
    thirdPartyId: {
      type: Number,
      default: 0
    },
    companyId: {
      type: Number,
      default: 0
    }
  },
  computed: {
    ...mapState({
      isUBODataLoading: (state) => state.thirdParty.isUBODataLoading,
      thirdPartyUBOList: (state) => state.thirdParty.thirdPartyUBOList,
      thirdPartyUBOError: (state) => state.thirdParty.thirdPartyUBOError,
      createThirdPartyError: (state) => state.thirdParty.createThirdPartyError,
      isCreatingThirdPartySuccessful: (state) =>
        state.thirdParty.isCreatingThirdPartySuccessful,
      isCreatingThirdParty: (state) => state.thirdParty.isCreatingThirdParty,
      userId: (state) => state.user.userId
    }),
    hasTableRows() {
      return !!this.agGridOptions.rowData.length;
    }
  },
  watch: {
    companyId() {
      this.fetchThirdPartiesUBOs(
        makeUBOQueryParameters({
          thirdPartyId: this.thirdPartyId,
          companyId: this.companyId
        })
      );
    },
    thirdPartyId() {
      this.fetchThirdPartiesUBOs(
        makeUBOQueryParameters({
          thirdPartyId: this.thirdPartyId,
          companyId: this.companyId
        })
      );
    },
    thirdPartyUBOList: {
      deep: true,
      handler(newValue, oldValue) {
        if (!isEqual(newValue, oldValue)) {
          this.makeUBOTableRows(newValue);
        }
      }
    },
    createThirdPartyError(newValue) {
      if (newValue) {
        setTimeout(() => {
          this.setCreateThirdPartyError(false);
        }, timers.MODERATE);

        this.showCreateThirdPartyError = true;
        setTimeout(() => {
          this.showCreateThirdPartyError = false;
        }, timers.MODERATE_SLOW);
      }

      this.searchFilterOptions.actionButtons[0].isError = newValue;
    },
    isCreatingThirdPartySuccessful(newValue) {
      if (newValue) {
        setTimeout(() => {
          this.setIsCreatingThirdPartySuccessful(false);
        }, timers.MODERATE);
      }

      this.searchFilterOptions.actionButtons[0].isSuccess = newValue;
    },
    isCreatingThirdParty(newValue) {
      this.searchFilterOptions.actionButtons[0].isLoading = newValue;
    }
  },
  created() {
    this.makeUBOTableHeaders();
    if (
      !this.thirdPartyUBOList?.length &&
      this.companyId &&
      this.thirdPartyId
    ) {
      this.fetchThirdPartiesUBOs(
        makeUBOQueryParameters({
          thirdPartyId: this.thirdPartyId,
          companyId: this.companyId
        })
      );
    } else {
      this.makeUBOTableRows(this.thirdPartyUBOList);
    }
  },
  methods: {
    orderIDD,
    ...mapActions({
      fetchThirdPartiesUBOs: actionName.THIRD_PARTY.FETCH_THIRD_PARTIES_UBOS,
      createThirdPartyFromUBO:
        actionName.THIRD_PARTY.CREATE_THIRD_PARTY_FROM_UBO
    }),
    ...mapMutations({
      setCreateThirdPartyError:
        mutationName.THIRD_PARTY.SET_CREATE_THIRD_PARTY_ERROR,
      setIsCreatingThirdPartySuccessful:
        mutationName.THIRD_PARTY.SET_IS_CREATING_THIRD_PARTY_SUCCESSFUL
    }),
    onGridReady({ api, columnApi }) {
      this.gridAPI = api;
      this.sizeColumns(columnApi);
      sizeColumnsToGridWidth({
        api,
        columnApi,
        sizingElement: this.$refs?.["data-table"]?.$el,
        makeColumnLimits: this.makeColumnLimits
      });
    },
    makeColumnLimits() {
      return Object.keys(this.columnInitialWidthMap).map((key) => ({
        key,
        maxWidth: this.columnInitialWidthMap[key]
      }));
    },
    isNumberColumn(UBOKey) {
      return !!this.numberColumnHeaders.includes(
        ultimateBeneficialOwnersTableColumnKeys[UBOKey]
      );
    },
    sizeColumns(columnApi) {
      columnApi.autoSizeColumns([
        ultimateBeneficialOwnersKeysDTO.BENEFICIARY_NAME,
        ultimateBeneficialOwnersKeysDTO.BUSINESS_OWNERSHIP_TYPE
      ]);
    },
    makeUBOSelectHeaderColumn() {
      return {
        field: ultimateBeneficialOwnersTableColumnKeys.SELECT,
        pinned: "left",
        cellRenderer: "BaseGridComponentWrapper",
        headerComponent: "BaseGridComponentWrapper",
        headerClass: "ag-cell-center",
        cellClass: "ag-cell-center",
        headerComponentParams: {
          value: {
            component: Checkbox,
            componentOptions: {
              label: "Select all",
              labelHidden: true,
              checked: false
            },
            listeners: {
              click: () => {
                updateAllRowSelections({
                  gridAPI: this.gridAPI,
                  agGridOptions: this.agGridOptions,
                  setActionButtonDisabledState:
                    this.setActionButtonDisabledState
                });
              }
            }
          }
        },
        valueFormatter: (params) => params.value.componentOptions.checked,
        width:
          this.columnInitialWidthMap[
            ultimateBeneficialOwnersTableColumnKeys.SELECT
          ]
      };
    },
    makeUBOViewProfileHeaderColumn() {
      return {
        field: ultimateBeneficialOwnersTableColumnKeys.VIEW_PROFILE,
        headerName: ultimateBeneficialOwnersHeaders.VIEW_PROFILE,
        pinned: "right",
        cellRenderer: "BaseGridComponentWrapper",
        headerClass: "ag-cell-center",
        cellClass: "ag-cell-center",
        valueFormatter: () => null,
        width:
          this.columnInitialWidthMap[
            ultimateBeneficialOwnersTableColumnKeys.VIEW_PROFILE
          ]
      };
    },
    makeUBORiskStatusHeaderColumn(UBOKey) {
      return {
        headerName: ultimateBeneficialOwnersHeaders[UBOKey],
        field: ultimateBeneficialOwnersTableColumnKeys[UBOKey],
        headerTooltip: ultimateBeneficialOwnersHeaders[UBOKey],
        width:
          this.columnInitialWidthMap[
            ultimateBeneficialOwnersTableColumnKeys[UBOKey]
          ],
        cellRenderer: "BaseGridComponentWrapper",
        headerClass: "ag-cell-center",
        cellClass: "ag-cell-center",
        valueFormatter: (params) => params.value
      };
    },
    makeUBOHeaderColumn(UBOKey) {
      return {
        ...(ultimateBeneficialOwnersKeysDTO[UBOKey] ===
          ultimateBeneficialOwnersKeysDTO.BENEFICIARY_NAME && {
          pinned: "left"
        }),
        headerName: ultimateBeneficialOwnersHeaders[UBOKey],
        field: ultimateBeneficialOwnersTableColumnKeys[UBOKey],
        headerTooltip: ultimateBeneficialOwnersHeaders[UBOKey],
        ...(this.columnInitialWidthMap[
          ultimateBeneficialOwnersTableColumnKeys[UBOKey]
        ] && {
          width:
            this.columnInitialWidthMap[
              ultimateBeneficialOwnersTableColumnKeys[UBOKey]
            ]
        }),
        ...(this.isNumberColumn(UBOKey) && {
          type: "numericColumn"
        })
      };
    },
    makeUBOTableHeaders() {
      this.agGridOptions.columnDefs = Object.keys(
        ultimateBeneficialOwnersHeaders
      ).map((UBOKey) => {
        if (UBOKey === "SELECT") {
          return this.makeUBOSelectHeaderColumn();
        } else if (UBOKey === "VIEW_PROFILE") {
          return this.makeUBOViewProfileHeaderColumn(UBOKey);
        } else if (UBOKey === "RISK_STATUS") {
          return this.makeUBORiskStatusHeaderColumn(UBOKey);
        } else {
          return this.makeUBOHeaderColumn(UBOKey);
        }
      });
    },
    makeUBOSelectRow(rowId) {
      return {
        component: Checkbox,
        componentOptions: {
          label: "Select UBO",
          labelHidden: true,
          checked: false
        },
        listeners: {
          click: () => {
            updateRowSelection({
              rowId,
              gridAPI: this.gridAPI,
              agGridOptions: this.agGridOptions,
              setActionButtonDisabledState: this.setActionButtonDisabledState
            });
          },
          change: () => {
            updateRowSelection({
              rowId,
              gridAPI: this.gridAPI,
              agGridOptions: this.agGridOptions,
              setActionButtonDisabledState: this.setActionButtonDisabledState
            });
          }
        },
        valueFormatter: (params) => params.value.componentOptions.checked
      };
    },
    makeUBOViewProfileRow(UBOData) {
      return {
        component: CallToAction,
        componentOptions: {
          icon: "file",
          size: 24,
          theme: themes.SECONDARY,
          shape: shapes.NONE
        },
        listeners: {
          click: () =>
            navigateToUBOProfile({
              router: this.$router,
              memberId: UBOData.memberId,
              thirdPartyId: this.thirdPartyId
            })
        },
        valueFormatter: () => null
      };
    },
    makeUBORiskStatusRow(riskStatus) {
      return {
        component: IconWithText,
        componentOptions: {
          ...getRiskIconBasedOnStatus({ status: riskStatus, remediated: true })
        }
      };
    },
    makeUBOTableRows(thirdPartyUBOData) {
      this.agGridOptions.rowData = thirdPartyUBOData.map((UBOData) => {
        const rowId = makeUUID();
        const keys = Object.keys(UBOData);
        const rowData = keys.reduce((accumulation, key) => {
          accumulation[key] =
            UBOData[key] || UBOData[key] === false || UBOData[key] === 0
              ? `${UBOData[key]}`
              : "-";

          return accumulation;
        }, {});

        return {
          ...rowData,
          [ultimateBeneficialOwnersTableColumnKeys.SELECT]:
            this.makeUBOSelectRow(rowId),
          [ultimateBeneficialOwnersTableColumnKeys.VIEW_PROFILE]:
            this.makeUBOViewProfileRow(UBOData),
          [ultimateBeneficialOwnersTableColumnKeys.RISK_STATUS]:
            this.makeUBORiskStatusRow(
              UBOData[ultimateBeneficialOwnersKeysDTO.RISK_STATUS]
            ),
          [ultimateBeneficialOwnersTableColumnKeys.ID]: rowId
        };
      });
    },
    setActionButtonDisabledState(selectedRowsLength = 0) {
      const currentValue = this.searchFilterOptions.actionButtons[0].isDisabled;
      const newValue = !selectedRowsLength;

      if (newValue !== currentValue) {
        this.searchFilterOptions.actionButtons[0].isDisabled = newValue;
      }
    },
    onDataTableClick({ type = "", event = {} }) {
      if (type === dataTableEvents.SEARCH_FILTER_BAR) {
        if (event.id === dataTableEvents.ORDER_IDD) {
          this.showCreateThirdPartyError = false;
          orderIDD({
            gridAPI: this.gridAPI,
            thirdPartyId: this.thirdPartyId,
            createThirdPartyFromUBO: this.createThirdPartyFromUBO
          });
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.ultimate-beneficial-owners {
  overflow: hidden;

  &__not-available-text {
    text-align: center;
    font-size: 20px;
  }
}
</style>
