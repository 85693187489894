<template>
  <div class="premium-corporate-data">
    <ul data-test-id="premium-corporate-data__list">
      <li class="premium-corporate-data__wrapper">
        <BaseText
          :text="['Company Name:', getValue('primaryName')]"
          class="premium-corporate-data__wrapper-title"
        />
      </li>
      <li class="premium-corporate-data__wrapper">
        <BaseText
          :text="[
            'Operating Status:',
            getValue('dunsControlStatus.operatingStatus.description')
          ]"
          class="premium-corporate-data__wrapper-title"
        />
      </li>
      <li class="premium-corporate-data__wrapper">
        <BaseText
          :text="['Country:', getValue('primaryAddress.addressCountry.name')]"
          class="premium-corporate-data__wrapper-title"
        />
      </li>
      <li class="premium-corporate-data__wrapper">
        <BaseText
          :text="['URL:', getValue('websiteAddress[0].url')]"
          class="premium-corporate-data__wrapper-title"
        />
      </li>
      <li class="premium-corporate-data__wrapper">
        <BaseText
          :text="['Ownership Date:', getValue('controlOwnershipDate')]"
          class="premium-corporate-data__wrapper-title"
        />
      </li>
      <li class="premium-corporate-data__wrapper">
        <BaseText
          :text="[
            'Ownership Type:',
            getValue('controlOwnershipType.description')
          ]"
          class="premium-corporate-data__wrapper-title"
        />
      </li>
      <div>
        <div class="premium-corporate-data__wrapper">
          <BaseText
            :text="['No. of Employees']"
            class="premium-corporate-data__wrapper-title"
          />
        </div>
        <div class="premium-corporate-data__table-wrapper">
          <StickyTable
            :table-headers="makeNumberOfEmployeesHeaders"
            :table-rows="makeNumberOfEmployeesRows"
          />
        </div>
      </div>
      <li class="premium-corporate-data__wrapper">
        <BaseText
          :text="[
            'Street Address 1:',
            getValue('primaryAddress.streetAddress.line1')
          ]"
          class="premium-corporate-data__wrapper-title"
        />
      </li>
      <li class="premium-corporate-data__wrapper">
        <BaseText
          :text="[
            'Street Address 2:',
            getValue('primaryAddress.streetAddress.line2')
          ]"
          class="premium-corporate-data__wrapper-title"
        />
      </li>
      <li class="premium-corporate-data__wrapper">
        <BaseText
          :text="[
            'Street Address 3:',
            getValue('primaryAddress.streetAddress.line3')
          ]"
          class="premium-corporate-data__wrapper-title"
        />
      </li>
      <li class="premium-corporate-data__wrapper">
        <BaseText
          :text="[
            'Street Address 4:',
            getValue('primaryAddress.streetAddress.line4')
          ]"
          class="premium-corporate-data__wrapper-title"
        />
      </li>
      <li class="premium-corporate-data__wrapper">
        <BaseText
          :text="['Country:', getValue('primaryAddress.addressCountry.name')]"
          class="premium-corporate-data__wrapper-title"
        />
      </li>
      <li class="premium-corporate-data__wrapper">
        <BaseText
          :text="[
            'State/Region:',
            getValue('primaryAddress.addressRegion.name')
          ]"
          class="premium-corporate-data__wrapper-title"
        />
      </li>
      <li class="premium-corporate-data__wrapper">
        <BaseText
          :text="['Postal Code:', getValue('primaryAddress.postalCode')]"
          class="premium-corporate-data__wrapper-title"
        />
      </li>
      <div>
        <div class="premium-corporate-data__wrapper">
          <div class="premium-corporate-data__icon-wrapper">
            <font-awesome-icon
              v-if="isIndustriesVisible"
              @click="setIndustriesVisibility"
              icon="angle-up"
            />
            <font-awesome-icon
              v-else
              @click="setIndustriesVisibility"
              icon="angle-down"
            />
            <BaseText
              :text="['Industries:']"
              class="premium-corporate-data__icon-wrapper-title"
            />
          </div>
        </div>
        <TransitionExpand>
          <div v-if="isIndustriesVisible">
            <div class="premium-corporate-data__table-wrapper">
              <BaseText
                :text="['Industry']"
                class="premium-corporate-data__table-wrapper-title"
              />
              <div
                v-for="(industryCode, index) in getValue('industryCodes')"
                :key="index"
              >
                <BaseText :text="[industryCode.description]" />
              </div>
            </div>
          </div>
        </TransitionExpand>
      </div>
      <li class="premium-corporate-data__wrapper">
        <BaseText
          :text="['Business Type:', getValue('businessEntityType.description')]"
          class="premium-corporate-data__wrapper-title"
        />
      </li>
      <div>
        <div class="premium-corporate-data__wrapper">
          <div class="premium-corporate-data__icon-wrapper">
            <font-awesome-icon
              v-if="isRegistrationNumbersVisible"
              @click="setRegistrationNumbersVisibility"
              icon="angle-up"
            />
            <font-awesome-icon
              v-else
              @click="setRegistrationNumbersVisibility"
              icon="angle-down"
            />
            <BaseText
              :text="['Registration Numbers:']"
              class="premium-corporate-data__icon-wrapper-title"
            />
          </div>
        </div>
        <TransitionExpand>
          <div
            v-if="isRegistrationNumbersVisible"
            class="premium-corporate-data__collpase"
          >
            <div class="premium-corporate-data__table-wrapper">
              <StickyTable
                :table-headers="makeRegistrationNumbersHeaders"
                :table-rows="makeRegistrationNumbersRows"
              />
            </div>
          </div>
        </TransitionExpand>
      </div>
      <div>
        <div class="premium-corporate-data__wrapper">
          <div class="premium-corporate-data__icon-wrapper">
            <font-awesome-icon
              v-if="isCompanyNamesVisible"
              @click="setCompanyNamesVisibility"
              icon="angle-up"
            />
            <font-awesome-icon
              v-else
              @click="setCompanyNamesVisibility"
              icon="angle-down"
            />
            <BaseText
              :text="['Company Names:']"
              class="premium-corporate-data__icon-wrapper-title"
            />
          </div>
        </div>
        <TransitionExpand>
          <div v-if="isCompanyNamesVisible">
            <div class="premium-corporate-data__table-wrapper">
              <BaseText
                :text="['Alternate Company Name']"
                class="premium-corporate-data__table-wrapper-title"
              />
              <div
                v-for="(companyName, index) in getValue('tradeStyleNames')"
                :key="index"
              >
                <BaseText :text="[companyName.name]" />
              </div>
            </div>
          </div>
        </TransitionExpand>
      </div>
      <div>
        <div class="premium-corporate-data__wrapper">
          <div class="premium-corporate-data__icon-wrapper">
            <font-awesome-icon
              v-if="isSeniorPrincipalsVisible"
              @click="setSeniorPrincipalsVisibility"
              icon="angle-up"
            />
            <font-awesome-icon
              v-else
              @click="setSeniorPrincipalsVisibility"
              icon="angle-down"
            />
            <BaseText
              :text="['Senior Principals:']"
              class="premium-corporate-data__icon-wrapper-title"
            />
          </div>
        </div>
        <TransitionExpand>
          <div
            class="premium-corporate-data__collpase"
            v-if="isSeniorPrincipalsVisible"
          >
            <div class="premium-corporate-data__table-wrapper">
              <StickyTable
                :table-headers="makeSeniorPrincipalsHeaders"
                :table-rows="makeSeniorPrincipalsRows"
              />
            </div>
          </div>
        </TransitionExpand>
      </div>
      <div>
        <div class="premium-corporate-data__wrapper">
          <div class="premium-corporate-data__icon-wrapper">
            <font-awesome-icon
              v-if="isPrincipalsVisible"
              @click="setPrincipalsVisibility"
              icon="angle-up"
            />
            <font-awesome-icon
              v-else
              @click="setPrincipalsVisibility"
              icon="angle-down"
            />
            <BaseText
              :text="['Principals:']"
              class="premium-corporate-data__icon-wrapper-title"
            />
          </div>
        </div>
        <TransitionExpand>
          <div
            class="premium-corporate-data__collpase"
            v-if="isPrincipalsVisible"
          >
            <div class="premium-corporate-data__table-wrapper">
              <StickyTable
                :table-headers="makeCurrentPrincipalsHeaders"
                :table-rows="makeCurrentPrincipalsRows"
              />
            </div>
          </div>
        </TransitionExpand>
      </div>
      <li class="premium-corporate-data__wrapper">
        <BaseText
          :text="['Financial Information']"
          class="premium-corporate-data__wrapper-title"
        />
      </li>
      <li class="premium-corporate-data__wrapper">
        <BaseText
          :text="['Accountants:', getValue('financials[0].accountantName')]"
          class="premium-corporate-data__wrapper-title"
        />
      </li>
      <li class="premium-corporate-data__wrapper">
        <BaseText
          :text="[
            'Yearly Revenue:',
            getValue('financials[0].yearlyRevenue[0].value')
          ]"
          class="premium-corporate-data__wrapper-title"
        />
      </li>
      <li class="premium-corporate-data__wrapper">
        <BaseText
          :text="[
            'Currency:',
            getValue('financials.[0].yearlyRevenue.[0].currency')
          ]"
          class="premium-corporate-data__wrapper-title"
        />
      </li>
      <div>
        <div class="premium-corporate-data__wrapper">
          <BaseText
            :text="['Growth Rate:']"
            class="premium-corporate-data__wrapper-title"
          />
        </div>
        <div
          v-for="(trend, index) in getValue(
            'financials[0].yearlyRevenue[0].trend'
          )"
          :key="index"
          class="premium-corporate-data__growth-rate-section"
        >
          <BaseText
            :text="[trend.timePeriod.description, ':-', trend.growthRate]"
          />
        </div>
      </div>
    </ul>
  </div>
</template>

<script>
import StickyTable from "@/molecules/StickyTable/StickyTable";
import {
  typographySize,
  registrationNumbersKeys,
  registrationNumbersTableHeaders,
  numberOfEmployeesKeys,
  numberOfEmployeesTableHeaders,
  seniorPrincipalsKeys,
  seniorPrincipalsTableHeaders
} from "@/constants";
import BaseText from "@/atoms/BaseText/BaseText";
import TransitionExpand from "@/atoms/TransitionExpand/TransitionExpand";
import { get } from "lodash";

export default {
  name: "PremiumCorporateData",
  components: {
    StickyTable,
    BaseText,
    TransitionExpand
  },
  props: {
    premiumCorporateData: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      isIndustriesVisible: true,
      isRegistrationNumbersVisible: true,
      isCompanyNamesVisible: true,
      isSeniorPrincipalsVisible: true,
      isPrincipalsVisible: true
    };
  },
  computed: {
    makeRegistrationNumbersListForTable() {
      return this.premiumCorporateData?.registrationNumbers?.length
        ? this.premiumCorporateData.registrationNumbers.map(
            ({ registrationNumber, typeDescription }) => ({
              [registrationNumbersKeys.TYPE]: typeDescription,
              [registrationNumbersKeys.NUMBER]: registrationNumber
            })
          )
        : [];
    },
    makeRegistrationNumbersHeaders() {
      return this.makeRegistrationNumbersListForTable.length
        ? Object.keys(this.makeRegistrationNumbersListForTable[0]).reduce(
            (acc, key) => {
              if (registrationNumbersTableHeaders[key]) {
                acc.push({
                  value: key,
                  component: BaseText,
                  componentOptions: {
                    tag: "span",
                    text: registrationNumbersTableHeaders[key],
                    size: typographySize.BODY_TEXT_BOLD,
                    styles: {
                      margin: "0"
                    }
                  },
                  styles: {
                    padding: "5px"
                  }
                });
              }
              return acc;
            },
            []
          )
        : [];
    },
    makeRegistrationNumbersRows() {
      return this.makeRegistrationNumbersListForTable.length
        ? this.makeRegistrationNumbersListForTable.map((action) =>
            Object.keys(action).reduce((acc, actionKey) => {
              acc[actionKey] = {
                component: BaseText,
                componentOptions: {
                  tag: "span",
                  text: action[actionKey],
                  styles: {
                    padding: "0px 5px"
                  }
                }
              };
              return acc;
            }, {})
          )
        : [];
    },
    makeNumberOfEmployeesListForTable() {
      return this.premiumCorporateData?.numberOfEmployees?.length
        ? this.premiumCorporateData.numberOfEmployees.map(
            ({ informationScopeDescription, value }) => ({
              [numberOfEmployeesKeys.DESCRIPTION]: informationScopeDescription,
              [numberOfEmployeesKeys.VALUE]: value
            })
          )
        : [];
    },
    makeNumberOfEmployeesHeaders() {
      return this.makeNumberOfEmployeesListForTable.length
        ? Object.keys(this.makeNumberOfEmployeesListForTable[0]).reduce(
            (acc, key) => {
              if (numberOfEmployeesTableHeaders[key]) {
                acc.push({
                  value: key,
                  component: BaseText,
                  componentOptions: {
                    tag: "span",
                    text: numberOfEmployeesTableHeaders[key],
                    size: typographySize.BODY_TEXT_BOLD,
                    styles: {
                      margin: "0"
                    }
                  },
                  styles: {
                    padding: "5px"
                  }
                });
              }
              return acc;
            },
            []
          )
        : [];
    },
    makeNumberOfEmployeesRows() {
      return this.makeNumberOfEmployeesListForTable.length
        ? this.makeNumberOfEmployeesListForTable.map((action) =>
            Object.keys(action).reduce((acc, actionKey) => {
              acc[actionKey] = {
                component: BaseText,
                componentOptions: {
                  tag: "span",
                  text: action[actionKey],
                  styles: {
                    padding: "0px 5px"
                  }
                }
              };
              return acc;
            }, {})
          )
        : [];
    },
    makeSeniorPrincipalsListForTable() {
      return this.premiumCorporateData?.mostSeniorPrincipals?.length
        ? this.premiumCorporateData.mostSeniorPrincipals.map(
            ({ jobTitles, namePrefix, fullName, nameSuffix, gender }) => ({
              [seniorPrincipalsKeys.TITLE]: jobTitles?.[0]?.title,
              [seniorPrincipalsKeys.NAME_PREFIX]: namePrefix,
              [seniorPrincipalsKeys.FULL_NAME]: fullName,
              [seniorPrincipalsKeys.NAME_SUFFIX]: nameSuffix,
              [seniorPrincipalsKeys.GENDER]: gender
            })
          )
        : [];
    },
    makeSeniorPrincipalsHeaders() {
      return this.makeSeniorPrincipalsListForTable.length
        ? Object.keys(this.makeSeniorPrincipalsListForTable[0]).reduce(
            (acc, key) => {
              if (seniorPrincipalsTableHeaders[key]) {
                acc.push({
                  value: key,
                  component: BaseText,
                  componentOptions: {
                    tag: "span",
                    text: seniorPrincipalsTableHeaders[key],
                    size: typographySize.BODY_TEXT_BOLD,
                    styles: {
                      margin: "0"
                    }
                  },
                  styles: {
                    padding: "5px"
                  }
                });
              }
              return acc;
            },
            []
          )
        : [];
    },
    makeSeniorPrincipalsRows() {
      return this.makeSeniorPrincipalsListForTable.length
        ? this.makeSeniorPrincipalsListForTable.map((action) =>
            Object.keys(action).reduce((acc, actionKey) => {
              acc[actionKey] = {
                component: BaseText,
                componentOptions: {
                  tag: "span",
                  text: action[actionKey],
                  styles: {
                    padding: "0px 5px"
                  }
                }
              };
              return acc;
            }, {})
          )
        : [];
    },
    makeCurrentPrincipalsListForTable() {
      return this.premiumCorporateData?.currentPrincipals?.length
        ? this.premiumCorporateData.currentPrincipals.map(
            ({ jobTitles, namePrefix, fullName, nameSuffix, gender }) => ({
              [seniorPrincipalsKeys.TITLE]: jobTitles?.[0]?.title,
              [seniorPrincipalsKeys.NAME_PREFIX]: namePrefix,
              [seniorPrincipalsKeys.FULL_NAME]: fullName,
              [seniorPrincipalsKeys.NAME_SUFFIX]: nameSuffix,
              [seniorPrincipalsKeys.GENDER]: gender
            })
          )
        : [];
    },
    makeCurrentPrincipalsHeaders() {
      return this.makeCurrentPrincipalsListForTable.length
        ? Object.keys(this.makeCurrentPrincipalsListForTable[0]).reduce(
            (acc, key) => {
              if (seniorPrincipalsTableHeaders[key]) {
                acc.push({
                  value: key,
                  component: BaseText,
                  componentOptions: {
                    tag: "span",
                    text: seniorPrincipalsTableHeaders[key],
                    size: typographySize.BODY_TEXT_BOLD,
                    styles: {
                      margin: "0"
                    }
                  },
                  styles: {
                    padding: "5px"
                  }
                });
              }
              return acc;
            },
            []
          )
        : [];
    },
    makeCurrentPrincipalsRows() {
      return this.makeCurrentPrincipalsListForTable.length
        ? this.makeCurrentPrincipalsListForTable.map((action) =>
            Object.keys(action).reduce((acc, actionKey) => {
              acc[actionKey] = {
                component: BaseText,
                componentOptions: {
                  tag: "span",
                  text: action[actionKey],
                  styles: {
                    padding: "0px 5px"
                  }
                }
              };
              return acc;
            }, {})
          )
        : [];
    }
  },
  methods: {
    getValue(valuePath) {
      return get(this.premiumCorporateData, valuePath, "");
    },
    setPrincipalsVisibility() {
      this.isPrincipalsVisible = !this.isPrincipalsVisible;
    },
    setRegistrationNumbersVisibility() {
      this.isRegistrationNumbersVisible = !this.isRegistrationNumbersVisible;
    },
    setCompanyNamesVisibility() {
      this.isCompanyNamesVisible = !this.isCompanyNamesVisible;
    },
    setSeniorPrincipalsVisibility() {
      this.isSeniorPrincipalsVisible = !this.isSeniorPrincipalsVisible;
    },
    setIndustriesVisibility() {
      this.isIndustriesVisible = !this.isIndustriesVisible;
    }
  }
};
</script>

<style lang="scss" scoped>
.premium-corporate-data {
  &:deep(.premium-corporate-data__disable-text) {
    text-align: center;
    font-style: italic;
  }
  &:deep(.premium-corporate-data__wrapper-title:nth-child(1)),
  &:deep(.premium-corporate-data__wrapper-title:nth-child(2)) {
    margin: 10px;
  }
  &:deep(.premium-corporate-data__wrapper-title:nth-child(1)) {
    @include body-text-bold;
  }
  &:deep(.premium-corporate-data__icon-wrapper-title) {
    @include body-text-bold;
    margin-left: 5px;
  }
  &:deep(.premium-corporate-data__table-wrapper-title) {
    @include body-text-bold;
  }
  &__wrapper-title {
    ::v-deep {
      p:nth-child(1) {
        @include body-text-bold;
      }
    }
  }
  &__wrapper {
    display: flex;
  }
  &__collpase {
    padding-bottom: 10px;
  }
  &__table-wrapper {
    padding-left: 80px;
  }
  &__icon-wrapper {
    display: flex;
  }
  &__growth-rate-section {
    display: flex;
    padding-left: 80px;
  }
}
</style>
