<!-- Review result component -->
<template>
  <div>
    <b-overlay :show="isLoading" rounded="sm">
      <div class="text-left">
        <h5>
          <font-awesome-icon
            v-if="companyCollapseOne"
            @click="companyCollapseOne = !companyCollapseOne"
            icon="minus"
          />
          <font-awesome-icon
            v-else
            @click="companyCollapseOne = !companyCollapseOne"
            icon="plus"
          />
          {{ searchReportData.thirdPartyName }}
        </h5>
        <b-collapse class="p-2" v-model="companyCollapseOne">
          <div>
            <div v-if="searchReportData.oc_orginfos_srch20.length">
              <div
                v-for="(
                  orgInfos, orgInfosIndex
                ) in searchReportData.oc_orginfos_srch20"
                :key="orgInfosIndex"
              >
                <p v-if="orgInfos.name">
                  <b>Entity Name: </b>{{ orgInfos.name }}
                </p>
                <p v-if="orgInfos.current_status">
                  <b>Status: </b>{{ orgInfos.current_status }}
                </p>
                <p v-if="orgInfos.company_type">
                  <b>Company Type: </b>{{ orgInfos.company_type }}
                </p>
                <p v-if="orgInfos.registered_address">
                  <b>Address: </b>{{ orgInfos.registered_address }}
                </p>
                <p v-if="orgInfos.jurisdiction_code">
                  <b>Jurisdiction: </b>{{ getCountryName() }}
                </p>
                <p v-if="orgInfos.incorporation_date">
                  <b>Incorporation Date: </b>{{ orgInfos.incorporation_date }}
                </p>
                <p v-if="orgInfos.dissolution_date">
                  <b>Dissolution Date: </b>{{ orgInfos.dissolution_date }}
                </p>
                <p v-if="orgInfos.registry_url">
                  <b>Registry URL: </b>{{ orgInfos.registry_url }}
                </p>
                <p v-if="orgInfos.previous_names">
                  <b>Previous Names: </b>{{ orgInfos.previous_names }}
                </p>
                <p v-if="orgInfos.filings">
                  <b>Filings: </b>{{ orgInfoFilings(orgInfos) }}
                </p>
                <hr />
              </div>
            </div>
            <b-row>
              <b-col cols="6">
                <!-- IDD report start -->
                <b-row style="align-items: center">
                  <b-col cols="2">
                    <BaseIcon
                      v-if="
                        searchReportData.shield_vendor_infos.risk_level == 1 ||
                        searchReportData.shield_vendor_infos.risk_level == 3 ||
                        searchReportData.shield_vendor_infos.risk_level == 5 ||
                        searchReportData.shield_vendor_infos.risk_level == 6
                      "
                      v-b-tooltip.hover.bottom.html="
                        iconData.instantDueDeligence.status[
                          searchReportData.shield_vendor_infos.risk_level
                        ].tooltipEnable
                          ? iconData.instantDueDeligence.status[
                              searchReportData.shield_vendor_infos.risk_level
                            ].tooltip
                          : ''
                      "
                      v-bind="
                        getIcon(searchReportData.shield_vendor_infos.risk_level)
                      "
                    />
                  </b-col>
                  <b-col cols="10">
                    <p><b>Instant Due Dilligence Report </b></p>
                    <b-btn
                      size="sm"
                      variant="outline-primary"
                      @click="downloadIDDReport(searchReportData)"
                    >
                      <font-awesome-icon icon="file-pdf" />
                    </b-btn>
                    <b-btn
                      style="paddingleft: 5px"
                      size="md"
                      variant="link"
                      @click="downloadIDDReport(searchReportData)"
                      >Download</b-btn
                    >
                  </b-col>
                </b-row>
                <!-- IDD report end -->
              </b-col>
              <b-col cols="6">
                <div
                  v-if="
                    searchReportData.shield_vendor_infos.idd_color &&
                    searchReportData.shield_vendor_infos.idd_color == 0
                  "
                ></div>
                <!-- IDD+ report start -->
                <b-row v-else>
                  <b-col cols="2">
                    <BaseIcon
                      v-if="
                        searchReportData.shield_vendor_infos.idd_color == 1 ||
                        searchReportData.shield_vendor_infos.idd_color == 3 ||
                        searchReportData.shield_vendor_infos.idd_color == 5 ||
                        searchReportData.shield_vendor_infos.idd_color == 6
                      "
                      v-b-tooltip.hover.bottom.html="
                        iconData.instantDueDeligencePlus.status[
                          searchReportData.shield_vendor_infos.idd_color
                        ].tooltipEnable
                          ? iconData.instantDueDeligencePlus.status[
                              searchReportData.shield_vendor_infos.idd_color
                            ].tooltip
                          : ''
                      "
                      v-bind="
                        getIcon(searchReportData.shield_vendor_infos.idd_color)
                      "
                    />
                  </b-col>
                  <b-col cols="10">
                    <p><b>Instant Due Diligence PLUS Report </b></p>
                    <b-btn
                      size="sm"
                      variant="outline-primary"
                      @click="downloadIDDPlusReport(searchReportData)"
                    >
                      <font-awesome-icon icon="file-pdf" />
                    </b-btn>
                    <b-btn
                      style="paddingleft: 5px"
                      size="md"
                      variant="link"
                      @click="downloadIDDPlusReport(searchReportData)"
                      >Download</b-btn
                    >
                  </b-col>
                </b-row>
                <!-- IDD+ report end -->
              </b-col>
            </b-row>
          </div>
        </b-collapse>
      </div>
      <!-- Third party alerts start -->
      <hr v-if="searchReportData.srch_tpi_rdc_indivi_alrt.length" />
      <div
        class="text-left"
        v-if="searchReportData.srch_tpi_rdc_indivi_alrt.length"
      >
        <h5>
          <font-awesome-icon
            v-if="thirdPartyAlertsCollapse"
            @click="thirdPartyAlertsCollapse = !thirdPartyAlertsCollapse"
            icon="minus"
          />
          <font-awesome-icon
            v-else
            @click="thirdPartyAlertsCollapse = !thirdPartyAlertsCollapse"
            icon="plus"
          />&nbsp;Third Party Alerts
        </h5>
        <b-collapse class="p-2" v-model="thirdPartyAlertsCollapse">
          <div
            style="
              padding-left: 20px;
              padding-top: 15px;
              height: 40rem;
              overflow-y: auto;
            "
          >
            <div
              v-for="(
                indiviAlrt, indiviAlrtIndex
              ) in searchReportData.srch_tpi_rdc_indivi_alrt"
              :key="indiviAlrtIndex"
            >
              <div>
                <p>
                  <b>Source Name: </b
                  ><span
                    v-html="
                      indiviAlrt.ent_src_name ? indiviAlrt.ent_src_name : ''
                    "
                  ></span>
                </p>
                <p>
                  <b>Entity Name: </b
                  ><span
                    v-html="indiviAlrt.ent_name ? indiviAlrt.ent_name : ''"
                  ></span>
                </p>
                <p>
                  <b>Entity Type: </b
                  ><span
                    v-html="indiviAlrt.ent_tp ? indiviAlrt.ent_tp : ''"
                  ></span>
                </p>
                <p>
                  <b>Date of Birth: </b
                  >{{ getDateOfBirthDate(indiviAlrt.ent_DateofBirth) }}
                </p>
              </div>
              <div
                v-if="
                  indiviAlrt.ent_alias &&
                  isJson(indiviAlrt.ent_alias) &&
                  JSON.parse(indiviAlrt.ent_alias).length
                "
              >
                <p><b>Aliases: </b></p>
                <div
                  v-for="(entAlias, entAliasIndex) in JSON.parse(
                    indiviAlrt.ent_alias
                  )"
                  :key="entAliasIndex"
                >
                  <p>{{ entAliasIndex + 1 }}:</p>
                  <div class="ml-4">
                    <p v-html="entAlias"></p>
                  </div>
                </div>
              </div>
              <div
                v-if="
                  indiviAlrt.ent_posi &&
                  isJson(indiviAlrt.ent_posi) &&
                  JSON.parse(indiviAlrt.ent_posi).length
                "
              >
                <p><b>Position: </b></p>
                <div
                  v-for="(entPosi, entPosiIndex) in JSON.parse(
                    indiviAlrt.ent_posi
                  )"
                  :key="entPosiIndex"
                >
                  <p>{{ entPosiIndex + 1 }}:</p>
                  <div class="ml-4">
                    <p v-html="entPosi"></p>
                  </div>
                </div>
              </div>
              <div
                v-if="
                  indiviAlrt.ent_evnt &&
                  isJson(indiviAlrt.ent_evnt) &&
                  JSON.parse(indiviAlrt.ent_evnt).length
                "
              >
                <p><b>Risk Alert Information: </b></p>
                <div
                  v-for="(entEvnt, entEvntIndex) in JSON.parse(
                    indiviAlrt.ent_evnt
                  )"
                  :key="entEvntIndex"
                >
                  <p>{{ entEvntIndex + 1 }}:</p>
                  <div class="ml-4">
                    <p>
                      <b>Category: </b><span v-html="entEvnt.category"></span>
                    </p>
                    <p>
                      <b>Sub Category: </b
                      ><span v-html="entEvnt.subCategory"></span>
                    </p>
                    <p>
                      <b>Description: </b
                      ><span v-html="entEvnt.description"></span>
                    </p>
                    <p><b>Date: </b>{{ entEvnt.date }}</p>
                    <p>
                      <b>Source Name: </b>{{ entEvnt["source name"] || "N/A" }}
                    </p>
                    <p><b>Source URL: </b>{{ entEvnt["source url"] }}</p>
                    <p><b>Source Date: </b>{{ entEvnt["source date"] }}</p>
                  </div>
                </div>
              </div>
              <div
                v-if="
                  indiviAlrt.ent_address &&
                  isJson(indiviAlrt.ent_address) &&
                  JSON.parse(indiviAlrt.ent_address).length &&
                  JSON.parse(indiviAlrt.ent_address)[0]
                "
              >
                <p><b>Addresses: </b></p>
                <div
                  v-for="(entAddress, entAddressIndex) in JSON.parse(
                    indiviAlrt.ent_address
                  )"
                  :key="entAddressIndex"
                >
                  <p>{{ entAddressIndex + 1 }}:</p>
                  <div class="ml-4">
                    <p v-html="entAddress"></p>
                  </div>
                </div>
              </div>
              <div
                v-if="
                  indiviAlrt.ent_sources &&
                  isJson(indiviAlrt.ent_sources) &&
                  JSON.parse(indiviAlrt.ent_sources).length
                "
              >
                <div v-if="JSON.parse(indiviAlrt.ent_sources)[0].name">
                  <div v-if="JSON.parse(indiviAlrt.ent_sources)[0].name != ''">
                    <p><b>Sources Information: </b></p>
                    <div
                      v-for="(entSources, entSourcesIndex) in JSON.parse(
                        indiviAlrt.ent_sources
                      )"
                      :key="entSourcesIndex"
                    >
                      <p>{{ entSourcesIndex + 1 }}:</p>
                      <div class="ml-4">
                        <p>
                          <b>Name: </b><span v-html="entSources.name"></span>
                        </p>
                        <p><b>URL: </b><span v-html="entSources.url"></span></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="
                  indiviAlrt.ent_attri &&
                  isJson(indiviAlrt.ent_attri) &&
                  JSON.parse(indiviAlrt.ent_attri).length
                "
              >
                <p><b>Additional Information: </b></p>
                <div
                  v-for="(entAttri, entAttriIndex) in JSON.parse(
                    indiviAlrt.ent_attri
                  )"
                  :key="entAttriIndex"
                >
                  <p>
                    <b>{{ entAttri.code }}: </b>{{ entAttri.value }}
                  </p>
                </div>
              </div>
              <hr
                v-if="
                  indiviAlrtIndex <
                  searchReportData.srch_tpi_rdc_indivi_alrt.length - 1
                "
              />
            </div>
          </div>
        </b-collapse>
      </div>
      <hr />
      <!-- Third party alerts end -->

      <!-- Associates start -->
      <div class="text-left" v-if="searchReportData.srch_tpi_assos">
        <div
          v-for="(assosiate, assosiateIndex) in searchReportData.srch_tpi_assos"
          :key="assosiateIndex"
        >
          <b-row>
            <b-col cols="6">
              <h5>
                <font-awesome-icon
                  v-if="assosiate.isCollapsed"
                  @click="assosiate.isCollapsed = !assosiate.isCollapsed"
                  icon="minus"
                />
                <font-awesome-icon
                  v-else
                  @click="assosiate.isCollapsed = !assosiate.isCollapsed"
                  icon="plus"
                />
                {{ assosiate.asso_name }}
              </h5>
            </b-col>
            <b-col cols="6">
              <div class="float-right">
                <BaseIcon
                  v-b-tooltip.hover.bottom.html="
                    iconData.associates.status[
                      assosiate.srch_asso_rdc_indivi_alrt &&
                      assosiate.srch_asso_rdc_indivi_alrt.length == 0
                        ? 1
                        : 2
                    ].tooltipEnable
                      ? iconData.associates.status[
                          assosiate.srch_asso_rdc_indivi_alrt &&
                          assosiate.srch_asso_rdc_indivi_alrt.length == 0
                            ? 1
                            : 2
                        ].tooltip
                      : ''
                  "
                  v-bind="
                    getAssociatesIcon(assosiate.srch_asso_rdc_indivi_alrt)
                  "
                />
              </div>
            </b-col>
          </b-row>
          <b-collapse class="p-2" v-model="assosiate.isCollapsed">
            <div class="mt-2 text-left">
              <div
                :style="
                  assosiate.srch_asso_rdc_indivi_alrt.length
                    ? 'padding-left: 20px; padding-top: 15px; height: 40rem; overflow-y: auto;'
                    : 'padding-left: 20px; padding-top: 15px;'
                "
              >
                <div v-if="searchReportData.shield_vendor_infos">
                  <div
                    v-if="searchReportData.shield_vendor_infos.tpi_type == 'P'"
                  >
                    <div v-if="searchReportData.oc_orginfos_srch20.length">
                      <div
                        v-for="(
                          orgInfos, orgInfosIndex
                        ) in searchReportData.oc_orginfos_srch20"
                        :key="orgInfosIndex"
                      >
                        <p v-if="orgInfos.name">
                          <b>Entity Name: </b>{{ orgInfos.name }}
                        </p>
                        <p v-if="orgInfos.company_number">
                          <b>Company Number: </b>{{ orgInfos.company_number }}
                        </p>
                        <p v-if="orgInfos.current_status">
                          <b>Status: </b>{{ orgInfos.current_status }}
                        </p>
                        <p v-if="orgInfos.company_type">
                          <b>Company Type: </b>{{ orgInfos.company_type }}
                        </p>
                        <p v-if="orgInfos.registered_address">
                          <b>Address: </b>{{ orgInfos.registered_address }}
                        </p>
                        <p v-if="orgInfos.jurisdiction_code">
                          <b>Jurisdiction: </b>{{ getCountryName() }}
                        </p>
                        <p v-if="orgInfos.incorporation_date">
                          <b>Incorporation Date: </b
                          >{{ orgInfos.incorporation_date }}
                        </p>
                        <p v-if="orgInfos.dissolution_date">
                          <b>Dissolution Date: </b
                          >{{ orgInfos.dissolution_date }}
                        </p>
                        <p v-if="orgInfos.registry_url">
                          <b>Registry URL: </b>{{ orgInfos.registry_url }}
                        </p>
                        <p v-if="orgInfos.previous_names">
                          <b>Previous Names: </b>{{ orgInfos.previous_names }}
                        </p>
                        <p v-if="orgInfos.filings">
                          <b>Filings: </b>{{ orgInfos.filings }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-if="
                    assosiate.asso_type == 2 &&
                    JSON.parse(assosiate.meta_data) &&
                    (JSON.parse(assosiate.meta_data).birthDate ||
                      JSON.parse(assosiate.meta_data).address ||
                      JSON.parse(assosiate.meta_data).residenceCountryName ||
                      (JSON.parse(assosiate.meta_data).businessEntityType &&
                        JSON.parse(assosiate.meta_data).businessEntityType
                          .description))
                  "
                >
                  <div>
                    <p>
                      <b>Business Entity Type: </b
                      >{{
                        JSON.parse(assosiate.meta_data).businessEntityType
                          ? JSON.parse(assosiate.meta_data).businessEntityType
                              .description
                            ? JSON.parse(assosiate.meta_data).businessEntityType
                                .description
                            : "N/A"
                          : "N/A"
                      }}
                    </p>
                    <p>
                      <b>Country: </b
                      >{{
                        JSON.parse(assosiate.meta_data).residenceCountryName
                          ? JSON.parse(assosiate.meta_data).residenceCountryName
                          : "N/A"
                      }}
                    </p>
                    <p>
                      <b>Date of Birth: </b
                      >{{
                        JSON.parse(assosiate.meta_data).birthDate
                          ? JSON.parse(assosiate.meta_data).birthDate
                          : "N/A"
                      }}
                    </p>
                  </div>
                </div>
                <div
                  v-else-if="assosiate.asso_type == 1 && assosiate.asso_posi"
                >
                  <p><b>Position: </b>{{ assosiate.asso_posi || "N/A" }}</p>
                </div>
                <div v-if="assosiate.srch_asso_rdc_indivi_alrt.length">
                  <div
                    v-for="(
                      indiviAlrt, indiviAlrtIndex
                    ) in assosiate.srch_asso_rdc_indivi_alrt"
                    :key="indiviAlrtIndex"
                  >
                    <div>
                      <p>
                        <b>Source Name: </b
                        ><span
                          v-html="
                            indiviAlrt.ent_src_name
                              ? indiviAlrt.ent_src_name
                              : ''
                          "
                        ></span>
                      </p>
                      <p>
                        <b>Entity Name: </b
                        ><span
                          v-html="
                            indiviAlrt.ent_name ? indiviAlrt.ent_name : ''
                          "
                        ></span>
                      </p>
                      <p>
                        <b>Entity Type: </b
                        ><span
                          v-html="indiviAlrt.ent_tp ? indiviAlrt.ent_tp : ''"
                        ></span>
                      </p>
                      <p>
                        <b>Date of Birth: </b
                        >{{
                          indiviAlrt.ent_DateofBirth
                            ? getDateOfBirthDate(indiviAlrt.ent_DateofBirth)
                            : null
                        }}
                      </p>
                    </div>
                    <div
                      v-if="
                        indiviAlrt.ent_alias &&
                        isJson(indiviAlrt.ent_alias) &&
                        JSON.parse(indiviAlrt.ent_alias).length
                      "
                    >
                      <p><b>Aliases: </b></p>
                      <div
                        v-for="(entAlias, entAliasIndex) in JSON.parse(
                          indiviAlrt.ent_alias
                        )"
                        :key="entAliasIndex"
                      >
                        <p>{{ entAliasIndex + 1 }}:</p>
                        <div class="ml-4">
                          <p v-html="entAlias"></p>
                        </div>
                      </div>
                    </div>
                    <div v-if="indiviAlrt.ent_pepPersonalDetails">
                      <div
                        v-if="
                          isJson(indiviAlrt.ent_pepPersonalDetails) &&
                          JSON.parse(indiviAlrt.ent_pepPersonalDetails).length
                        "
                      >
                        <div
                          v-for="(
                            entPersonalDetails, entPersonalDetailsIndex
                          ) in JSON.parse(indiviAlrt.ent_pepPersonalDetails)"
                          :key="entPersonalDetailsIndex"
                        >
                          <p>
                            <b>{{ entPersonalDetails.code }}:</b>&nbsp;<span
                              v-html="entPersonalDetails.value"
                            ></span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="
                        indiviAlrt.ent_posi &&
                        isJson(indiviAlrt.ent_posi) &&
                        JSON.parse(indiviAlrt.ent_posi).length
                      "
                    >
                      <p><b>Position: </b></p>
                      <div
                        v-for="(entPosi, entPosiIndex) in JSON.parse(
                          indiviAlrt.ent_posi
                        )"
                        :key="entPosiIndex"
                      >
                        <p>{{ entPosiIndex + 1 }}:</p>
                        <div class="ml-4">
                          <p v-html="entPosi"></p>
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="
                        indiviAlrt.ent_evnt &&
                        isJson(indiviAlrt.ent_evnt) &&
                        JSON.parse(indiviAlrt.ent_evnt).length
                      "
                    >
                      <p><b>Risk Alert Information: </b></p>
                      <div
                        v-for="(entEvnt, entEvntIndex) in JSON.parse(
                          indiviAlrt.ent_evnt
                        )"
                        :key="entEvntIndex"
                      >
                        <p>{{ entEvntIndex + 1 }}:</p>
                        <div class="ml-4">
                          <p>
                            <b>Category: </b
                            ><span v-html="entEvnt.category"></span>
                          </p>
                          <p>
                            <b>Sub Category: </b
                            ><span v-html="entEvnt.subCategory"></span>
                          </p>
                          <p>
                            <b>Description: </b
                            ><span v-html="entEvnt.description"></span>
                          </p>
                          <p><b>Date: </b>{{ entEvnt.date }}</p>
                          <p>
                            <b>Source URL: </b
                            >{{ entEvnt["source url"] || "N/A" }}
                          </p>
                          <p>
                            <b>Source Date: </b
                            >{{ entEvnt["source date"] || "N/A" }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="
                        indiviAlrt.ent_address &&
                        isJson(indiviAlrt.ent_address) &&
                        JSON.parse(indiviAlrt.ent_address).length &&
                        JSON.parse(indiviAlrt.ent_address)[0]
                      "
                    >
                      <p><b>Addresses: </b></p>
                      <div
                        v-for="(entAddress, entAddressIndex) in JSON.parse(
                          indiviAlrt.ent_address
                        )"
                        :key="entAddressIndex"
                      >
                        <p>{{ entAddressIndex + 1 }}:</p>
                        <div class="ml-4">
                          <p v-html="entAddress"></p>
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="
                        indiviAlrt.ent_sources &&
                        isJson(indiviAlrt.ent_sources) &&
                        JSON.parse(indiviAlrt.ent_sources).length
                      "
                    >
                      <div v-if="JSON.parse(indiviAlrt.ent_sources)[0].name">
                        <div
                          v-if="
                            JSON.parse(indiviAlrt.ent_sources)[0].name != ''
                          "
                        >
                          <p><b>Sources Information: </b></p>
                          <div
                            v-for="(entSources, entSourcesIndex) in JSON.parse(
                              indiviAlrt.ent_sources
                            )"
                            :key="entSourcesIndex"
                          >
                            <p>{{ entSourcesIndex + 1 }}:</p>
                            <div class="ml-4">
                              <p>
                                <b>Name: </b
                                ><span v-html="entSources.name"></span>
                              </p>
                              <p>
                                <b>URL: </b
                                ><span v-html="entSources.url"></span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="
                        indiviAlrt.ent_attri &&
                        isJson(indiviAlrt.ent_attri) &&
                        JSON.parse(indiviAlrt.ent_attri).length
                      "
                    >
                      <p><b>Additional Information: </b></p>
                      <div
                        v-for="(entAttri, entAttriIndex) in JSON.parse(
                          indiviAlrt.ent_attri
                        )"
                        :key="entAttriIndex"
                      >
                        <p>
                          <b>{{ entAttri.code }}:</b>&nbsp;<span
                            v-html="entAttri.value"
                          ></span>
                        </p>
                      </div>
                    </div>
                    <hr
                      v-if="
                        indiviAlrtIndex <
                        assosiate.srch_asso_rdc_indivi_alrt.length - 1
                      "
                    />
                  </div>
                </div>
              </div>
            </div>
          </b-collapse>
          <hr
            v-if="assosiateIndex < searchReportData.srch_tpi_assos.length - 1"
          />
        </div>
      </div>
      <!-- Associates end -->
    </b-overlay>
  </div>
</template>

<script>
import iconJson from "@/utils/icon.json";
import { mapState } from "vuex";
import BaseIcon from "@/atoms/BaseIcon/BaseIcon";
import { themes, icons, shapes, iconSizes } from "@/constants";
import { getIcon } from "@/utils";

export default {
  name: "ReviewResult",
  components: {
    BaseIcon
  },
  data() {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL,
      companyCollapseOne: true,
      thirdPartyAlertsCollapse: false,
      companyCollapseTwo: false,
      showTpSearchDetail: false,
      showAssosSearchDetail: true,
      assosSrchAssoRdcIndiviAlrt: [],
      isLoading: false,
      staticFields: [
        {
          key: "subject_name",
          label: this.$t("eb360.reviewResult.subjectName")
        },
        { key: "entity_type", label: this.$t("eb360.reviewResult.entity") },
        { key: "country", label: this.$t("eb360.reviewResult.country") },
        {
          key: "report_result",
          label: this.$t("eb360.reviewResult.asrPlusReport")
        },
        {
          key: "download",
          label: this.$t("eb360.reviewResult.asrPlusDownload")
        }
      ],
      tableItems: [],
      tpName: null,
      tpCountry: null,
      selectedEntityType: null,
      iconData: iconJson,
      getIcon
    };
  },
  props: {
    searchReportData: Object,
    tpDnbData: Object,
    tpDnbSelectedAltaresTableItemData: Object,
    beneficialOwnershipRegistrationData: Object,
    OCStatesData: Array,
    showNoteText: Boolean
  },
  computed: {
    ...mapState({
      countries: (state) => state.country.countries
    })
  },
  methods: {
    downloadIDDReport(data) {
      let url = data.reportIddUrl ? `${data.reportIddUrl}` : "#";
      // if(this.apiUrl == '')
      let apiUrl = process.env.VUE_APP_API_URL;
      apiUrl = apiUrl.substring(0, apiUrl.length - 4);
      window.open(`${apiUrl}${url}`, "_blank");
      return false;
    },
    downloadIDDPlusReport(data) {
      let url = data.reportIddPlusUrl ? `${data.reportIddPlusUrl}` : "#";
      let apiUrl = process.env.VUE_APP_API_URL;
      apiUrl = apiUrl.substring(0, apiUrl.length - 4);
      window.open(`${apiUrl}${url}`, "_blank");
      return false;
    },
    removeSlashFromText(name) {
      let value = name.replace(/\\/g, "");
      return value;
    },
    isJson(item) {
      item = typeof item !== "string" ? JSON.stringify(item) : item;

      try {
        JSON.parse(item);
      } catch (e) {
        return false;
      }

      return true;
    },
    orgInfoFilings(orgInfos) {
      let filings = orgInfos.filings;
      let result = filings.replace(";;", ", ");
      return result;
    },
    getCountryName() {
      let jurisdictionCode =
        this.searchReportData.oc_orginfos_srch20[0].jurisdiction_code;
      let osStatesIndex = this.OCStatesData.findIndex(
        (x) => x.code == jurisdictionCode
      );
      if (this.countries) {
        if (osStatesIndex === -1) {
          let countryNameIndex = this.countries.findIndex(
            ({ isoCode1 }) => isoCode1.toLowerCase() === jurisdictionCode
          );
          if (countryNameIndex !== -1) {
            let countryName = this.countries[countryNameIndex].name;
            return countryName;
          }
        } else {
          let countryNameIndex = this.countries.findIndex(
            ({ isoCode1 }) =>
              isoCode1.toLowerCase() === jurisdictionCode.substring(0, 2)
          );
          if (countryNameIndex !== -1) {
            let countryName = `${this.OCStatesData[osStatesIndex].state}(${this.countries[countryNameIndex].name})`;
            return countryName;
          }
        }
      }
    },
    openOrderAsrPlusPage() {
      window.open(
        `${process.env.VUE_APP_BASE_URL}order_screening_report?srch_id=${this.srchId}&mnl=true`,
        "_blank"
      );
    },
    showHideCollapseRow(value) {
      if (value == "close") {
        this.showAssosSearchDetail = true;
      } else if (value == "open") {
        this.showAssosSearchDetail = false;
      }
    },
    showAssociateCollapse(assosiate) {
      if (this.showAssosSearchDetail) {
        this.showAssosSearchDetail = false;
        this.assosSrchAssoRdcIndiviAlrt = [];
      } else {
        this.showAssosSearchDetail = true;
        this.assosSrchAssoRdcIndiviAlrt = assosiate.srch_asso_rdc_indivi_alrt
          ? assosiate.srch_asso_rdc_indivi_alrt
          : [];
      }
    },
    getDateOfBirthDate(data) {
      let value = JSON.parse(data);
      return value.dob[0];
    },
    getAssociatesIcon(associate) {
      if (associate?.length) {
        return {
          icon: icons.EXCLAMATION,
          shape: shapes.TRIANGLE,
          theme: themes.ERROR,
          size: iconSizes.MEDIUM
        };
      }

      return {
        icon: icons.CHECK,
        shape: shapes.CIRCLE,
        theme: themes.SUCCESS_INVERSE_WHITE,
        size: iconSizes.MEDIUM
      };
    },
  }
};
</script>

<style lang="scss" scoped>
.result-download-btn {
  color: $white;
  background-color: $light-french-beige;
  border-color: $light-french-beige;
}
.result-download-btn:not(:disabled):not(.disabled):active:focus,
.result-download-btn:not(:disabled):not(.disabled).active:focus,
.show > .result-download-btn.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem $light-french-beige;
}
.result-download-btn:not(:disabled):not(.disabled):active,
.result-download-btn:not(:disabled):not(.disabled).active,
.show > .result-download-btn.dropdown-toggle {
  color: $white;
  background-color: $light-french-beige;
  border-color: $light-french-beige;
}
.result-download-btn:hover {
  color: $white;
  background-color: $light-french-beige;
  border-color: $light-french-beige;
}
.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
</style>
