<template>
  <div class="base-grid-component-wrapper">
    <component
      v-if="getComponent()"
      :is="getComponent()"
      v-bind="getComponentOptions()"
      v-on="getListeners()"
      data-test-id="base-grid-component-wrapper__component"
    />
  </div>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "BaseGridComponentWrapper",
  methods: {
    getComponentOptions() {
      return this.params?.value.componentOptions || null;
    },
    getComponent() {
      return this.params?.value?.component || null;
    },
    getListeners() {
      return this.params?.value?.listeners || null;
    }
  }
});
</script>

<style lang="scss" scoped>
.base-grid-component-wrapper {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  position: relative;
}
</style>
