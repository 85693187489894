<template>
  <section>
    <BaseLoader v-if="isLoading" />
    <template v-else>
      <a v-if="errorMessage" target="_blank" :href="platformRequestSupportLink">
        {{ errorMessage }}
      </a>
      <router-view
        v-else
        @fetchAction="fetchActionWrapper"
        @resetAction="resetAction"
        :action="action"
      />
    </template>
  </section>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import { actionName, actionKeysApi, mutationName } from "@/constants";

import BaseLoader from "@/atoms/BaseLoader/BaseLoader";

export default {
  name: "ActionsTemplate",
  data() {
    return {
      isLoading: false,
      errorMessage: ""
    };
  },
  components: {
    BaseLoader
  },
  computed: {
    ...mapState({
      action: (state) => state.actions.action,
      platformRequestSupportLink: (state) =>
        state.platformSettings.platformRequestSupportLink
    })
  },
  methods: {
    ...mapActions({
      fetchActions: actionName.ACTIONS.FETCH_ACTIONS,
      fetchAction: actionName.ACTIONS.FETCH_ACTION,
      fetchExpectedDataEntities:
        actionName.ACTIONS.FETCH_EXPECTED_DATA_ENTITIES,
      fetchRoles: actionName.ROLES.FETCH_ROLES,
      fetchUserEmailTemplates:
        actionName.USER_EMAIL_TEMPLATES.FETCH_USER_EMAIL_TEMPLATES
    }),
    ...mapMutations({
      resetAction: mutationName.ACTIONS.RESET_ACTION,
      resetActions: mutationName.ACTIONS.RESET_ACTIONS,
      resetRoles: mutationName.ROLES.RESET_ROLES,
      resetUserEmailTemplates:
        mutationName.USER_EMAIL_TEMPLATES.RESET_USER_EMAIL_TEMPLATES
    }),
    resetHTTPfeedback() {
      this.errorMessage = "";
      this.isLoading = true;
    },
    async fetchActionsWrapper() {
      try {
        this.resetHTTPfeedback();
        await this.fetchActions({
          excluded_fields: [actionKeysApi.FUNCTION_BODY],
          with_options: true
        });
      } catch (error) {
        this.fetchActionFailure();
      } finally {
        this.isLoading = false;
      }
    },
    fetchActionFailure() {
      this.errorMessage = "Please contact support.";
    },
    async fetchActionWrapper(id) {
      try {
        this.resetHTTPfeedback();
        await this.fetchAction(id);
      } catch (error) {
        this.fetchActionFailure();
      } finally {
        this.isLoading = false;
      }
    },
    async fetchExpectedDataEntitiesWrapper() {
      try {
        this.resetHTTPfeedback();
        await this.fetchExpectedDataEntities();
      } catch ({ response }) {
        this.errorMessage = this.fetchExpectedDataEntitiesFailure(response);
      } finally {
        this.isLoading = false;
      }
    },
    fetchExpectedDataEntitiesFailure({ status } = {}) {
      const description = status
        ? ` There was an error with status ${status}.`
        : "";
      return `Failed to retrieve the available ddqs.${description}`;
    }
  },
  async created() {
    await this.fetchExpectedDataEntitiesWrapper();

    if (this.$route?.params?.id) {
      await this.fetchActionWrapper(this.$route?.params?.id);
    }

    await Promise.allSettled([
      this.fetchActionsWrapper(),
      this.fetchUserEmailTemplates(),
      this.fetchRoles()
    ]);
  },
  beforeRouteLeave(_, __, next) {
    this.resetActions();
    this.resetRoles();
    this.resetUserEmailTemplates();
    next();
  }
};
</script>
