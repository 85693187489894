<template>
  <div class="third-party-details">
    <b-overlay :show="isLoadingData" rounded="sm">
      <b-tabs vertical small nav-class="custom-tab-css">
        <b-tab
          data-test-id="third-party-details__detail-tab"
          class="third-party-details__details-tab"
          title="DETAILS"
        >
          <b-card class="third-party-details__details-tab-card">
            <b-card-title class="third-party-details__details-tab-card-title">
              <b-row>
                <b-col data-test-id="third-party-details" cols="3"
                  >Details</b-col
                >
                <b-col data-test-id="third-party-details__listing" cols="9">
                  <div class="text-right">
                    <b-btn
                      v-if="isEditThirdPartyDetail"
                      size="sm"
                      variant="outline-danger"
                      :disabled="isDisabled"
                      class="mr-2"
                      @click="resetThirdPartyForm"
                    >
                      Dismiss
                    </b-btn>
                    <b-btn
                      v-if="isEditThirdPartyDetail"
                      :disabled="isDisabled"
                      size="sm"
                      variant="outline-success"
                      @click="saveThirdPartyDetail"
                    >
                      Save
                    </b-btn>
                  </div>
                </b-col>
              </b-row>
            </b-card-title>
            <b-card-body
              class="scrollFlow third-party-details__details-tab-card-body"
            >
              <b-row>
                <b-col cols="6">
                  <h6>Third Party Name:</h6>
                </b-col>
                <b-col cols="6">
                  <h6>
                    {{ getThirdPartyData.companyName }}
                  </h6>
                </b-col>
              </b-row>
              <br />
              <b-row>
                <b-col cols="6">
                  <h6>Uploaded Date:</h6>
                </b-col>
                <b-col cols="6">
                  <h6>
                    {{ getThirdPartyData.dateCreation }}
                  </h6>
                </b-col>
              </b-row>
              <br />
              <b-row>
                <b-col cols="6">
                  <h6>Created By:</h6>
                </b-col>
                <b-col cols="6">
                  <h6>
                    {{ getThirdPartyData.thirdPartyCreatedBy }}
                  </h6>
                </b-col>
              </b-row>
              <br />
              <b-row>
                <b-col cols="6">
                  <h6>Country/Territory of Registration:</h6>
                </b-col>
                <b-col cols="6">
                  <h6>
                    {{ getThirdPartyData.country }}
                  </h6>
                </b-col>
              </b-row>
              <br />

              <b-row>
                <b-col cols="6">
                  <h6>Address:</h6>
                </b-col>
                <b-col cols="6">
                  <b-form-group v-if="isEditThirdPartyDetail">
                    <b-form-textarea
                      id="textarea"
                      v-model="thirdPartyDetailForm.addressTextArea"
                      rows="3"
                      max-rows="6"
                    />
                  </b-form-group>
                  <h6 v-else>
                    {{ getThirdPartyData.contactAddress }}
                  </h6>
                </b-col>
              </b-row>
              <br />
              <b-row>
                <b-col cols="6">
                  <h6>City:</h6>
                </b-col>
                <b-col cols="6">
                  <b-form-group class="m-0" v-if="isEditThirdPartyDetail">
                    <b-form-input
                      size="sm"
                      v-model="thirdPartyDetailForm.city"
                    />
                  </b-form-group>
                  <h6 v-else>
                    {{ getThirdPartyData.city }}
                  </h6>
                </b-col>
              </b-row>
              <br />
              <b-row>
                <b-col cols="6">
                  <h6>State:</h6>
                </b-col>
                <b-col cols="6">
                  <b-form-group class="m-0" v-if="isEditThirdPartyDetail">
                    <b-form-input
                      size="sm"
                      v-model="thirdPartyDetailForm.state"
                    />
                  </b-form-group>
                  <h6 v-else>
                    {{ getThirdPartyData.state }}
                  </h6>
                </b-col>
              </b-row>
              <br />
              <b-row>
                <b-col cols="6">
                  <h6>Postcode/Zip Code:</h6>
                </b-col>
                <b-col cols="6">
                  <b-form-group class="m-0" v-if="isEditThirdPartyDetail">
                    <b-form-input
                      size="sm"
                      v-model="thirdPartyDetailForm.postCode"
                    />
                  </b-form-group>
                  <h6 v-else>
                    {{ getThirdPartyData.postalCode }}
                  </h6>
                </b-col>
              </b-row>
              <br />
              <b-row>
                <b-col cols="6">
                  <h6>Contact Number:</h6>
                </b-col>
                <b-col cols="6">
                  <b-form-group class="m-0" v-if="isEditThirdPartyDetail">
                    <b-form-input
                      size="sm"
                      v-model="thirdPartyDetailForm.contactNumber"
                    />
                  </b-form-group>
                  <h6 v-else>
                    {{ getThirdPartyData.contactPhone }}
                  </h6>
                </b-col>
              </b-row>
              <br />
              <b-row>
                <b-col cols="6">
                  <h6>URL:</h6>
                </b-col>
                <b-col cols="6">
                  <b-form-group v-if="isEditThirdPartyDetail" class="m-0">
                    <b-form-input
                      size="sm"
                      v-model="thirdPartyDetailForm.url"
                    />
                  </b-form-group>
                  <h6 v-else>
                    <a
                      class="third-party-details__details-tab-card-body-link"
                      :href="getThirdPartyData.url"
                      target="_blank"
                    >
                      {{ getThirdPartyData.website }}
                    </a>
                  </h6>
                </b-col>
              </b-row>
              <br />
              <b-row>
                <b-col cols="6">
                  <h6>DUNS Number:</h6>
                </b-col>
                <b-col cols="6">
                  <b-form-group v-if="isEditThirdPartyDetail" class="m-0">
                    <b-form-input
                      size="sm"
                      v-model="thirdPartyDetailForm.dunsCompanyNumber"
                    />
                  </b-form-group>
                  <h6 v-else>
                    {{ getThirdPartyData.companyIdNumber }}
                  </h6>
                </b-col>
              </b-row>
              <br />
              <b-row>
                <b-col cols="6">
                  <h6>Registration Number:</h6>
                </b-col>
                <b-col cols="6">
                  <b-form-group class="m-0" v-if="isEditThirdPartyDetail">
                    <b-form-input
                      size="sm"
                      v-model="thirdPartyDetailForm.registrationNumber"
                    />
                  </b-form-group>
                  <h6 v-else>
                    {{ getThirdPartyData.registrationNumber }}
                  </h6>
                </b-col>
              </b-row>
              <br />
              <b-row>
                <b-col cols="6">
                  <h6>
                    System Owner:
                    <Tooltip
                      text="System Owner is the user who will receive default notifications from the platform"
                    />
                  </h6>
                </b-col>
                <b-col cols="6">
                  <h6>
                    {{ getThirdPartyData.userId }}
                  </h6>
                </b-col>
              </b-row>
              <br />
              <b-row>
                <b-col cols="6">
                  <h6>EID:</h6>
                </b-col>
                <b-col cols="6">
                  <h6>
                    {{ getThirdPartyData.id }}
                  </h6>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-tab>
        <b-tab
          class="third-party-details__custom-attributes-tab"
          title="CUSTOM ATTRIBUTES"
          data-test-id="third-party-details__custom-attribute-tab"
        >
          <b-card class="third-party-details__custom-attributes-tab-card">
            <b-card-title
              data-test-id="third-party-details__custom-attributes-title"
              class="third-party-details__custom-attributes-tab-card-title"
            >
              Custom Attributes
            </b-card-title>
            <b-card-body
              class="
                scrollFlow
                third-party-details__custom-attributes-tab-card-body
              "
            >
              <b-table-simple
                v-if="customAttributes.length"
                data-test-id="third-party-details__custom-attributes-table"
                borderless
              >
                <b-thead>
                  <b-tr>
                    <b-th>Title</b-th>
                    <b-th>Value</b-th>
                  </b-tr>
                </b-thead>
                <b-tbody
                  v-for="(attributes, index) in makeUiCustomAttributes"
                  :key="index"
                >
                  <b-tr
                    v-if="
                      parseInt(attributes.tp_is_edit) === editStatus.EDIT_ON
                    "
                  >
                    <b-td
                      >{{ attributes.tp_customatt }}
                      <Tooltip
                        v-if="attributes.description"
                        :text="attributes.description"
                    /></b-td>
                    <b-td>
                      <p
                        v-if="
                          [attributeType.IMAGE, attributeType.FILE].includes(
                            attributes.tp_type
                          ) && attributes.tp_values
                        "
                        class="
                          text-primary
                          third-party-details__custom-attributes-tab-card-body-table-file
                        "
                        @click="openImage(attributes.hash_id)"
                      >
                        <u>{{ attributes.meta_data.fileName }}</u>
                      </p>
                      <p
                        v-else-if="attributes.tp_type === attributeType.URL"
                        @click="openUrl(attributes.tp_values)"
                        class="
                          text-primary
                          third-party-details__custom-attributes-tab-card-body-table-file
                        "
                      >
                        {{ attributes.tp_values }}
                      </p>
                      <p
                        v-else-if="attributes.tp_type === attributeType.EMAIL"
                        @click="openEmail(attributes.tp_values)"
                        class="
                          text-primary
                          third-party-details__custom-attributes-tab-table-file
                        "
                      >
                        {{ attributes.tp_values }}
                      </p>
                      <p
                        v-else-if="
                          attributes.tp_type === attributeType.HTML_STRING &&
                          attributes.tp_values
                        "
                        v-html="attributes.tp_values"
                        class="
                          third-party-details__custom-attributes-tab-table-html-string
                        "
                      />
                      <BaseText
                        v-else-if="
                          [
                            attributeType.MULTISELECT,
                            attributeType.DATE,
                            attributeType.MULTISELECT_COUNTRIES
                          ].includes(attributes.tp_type)
                        "
                        :text="attributes.tp_values || notAvailableText.NA"
                      />
                      <RatingCustomAttribute
                        v-else-if="attributes.tp_type === attributeType.RATING"
                        :value="attributes.value"
                        :text-align="textAlign.LEFT"
                        :scale-type="
                          getCustomAttributeRatingScaleTypeId({
                            attributeName: attributes.name,
                            customAttributeList: customAttributes
                          })
                        "
                      />
                      <p
                        v-else-if="attributes.tp_values"
                        class="
                          third-party-details__custom-attributes-tab-table-html-string
                        "
                      >
                        {{ attributes.tp_values }}
                      </p>
                      <p v-else class="text-muted">{{ notAvailableText.NA }}</p>
                    </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
              <div v-else>
                <p>Custom Attributes does not exist for this company.</p>
              </div>
            </b-card-body>
          </b-card>
        </b-tab>
        <b-tab
          title="CONTACTS"
          :lazy="$store.state.contactsTabLazyLoad"
          data-test-id="third-party-details__contacts-tab"
        >
          <ContactsTabComponent
            :isDisabled="isDisabled"
            :companyId="companyId"
            :thirdPartyId="thirdPartyId"
          />
        </b-tab>
        <b-tab
          title="ASSOCIATES"
          :lazy="$store.state.associatesTabLazyLoad"
          :active="showAssociatesTab"
          data-test-id="third-party-details__associates-tab"
        >
          <AssociatesTabComponent
            :companyId="companyId"
            :isDisabled="isDisabled"
            :thirdPartyId="thirdPartyId"
            :thirdPartyData="thirdPartyData"
            :search20Id="search20Id"
          />
        </b-tab>
      </b-tabs>
    </b-overlay>
  </div>
</template>

<script>
import {
  getCustomAttributeRatingScaleTypeId,
  HTTP,
  isValidResponse,
  makeAuthorizationHeader
} from "@/utils";
import {
  attributeType,
  editStatus,
  endpoints,
  getterName,
  notAvailableText,
  textAlign
} from "@/constants";
import moment from "moment";
import "moment/locale/es";
import AssociatesTabComponent from "@/components/eb2/thirdpartyProfilePageComponents/associatesTab/associatesTabComponent";
import ContactsTabComponent from "@/components/eb2/thirdpartyProfilePageComponents/contactsTab/contactsTabComponent";
import RatingCustomAttribute from "@/organisms/RatingCustomAttribute/RatingCustomAttribute";
import BaseText from "@/atoms/BaseText/BaseText";
import { makeCustomAttributeDTO } from "@/organisms/RatingCustomAttribute/RatingCustomAttribute.dto";
import Tooltip from "@/molecules/Tooltip/Tooltip";
import { mapGetters } from "vuex";

export default {
  name: "ThirdPartyDetails",
  components: {
    AssociatesTabComponent,
    ContactsTabComponent,
    RatingCustomAttribute,
    Tooltip,
    BaseText
  },
  props: {
    companyId: {
      required: true,
      type: Number
    },
    thirdPartyId: {
      required: true,
      type: Number
    },
    thirdPartyData: {
      required: true,
      type: Object
    },
    isDisabled: {
      required: true,
      type: Boolean
    },
    lazyLoad: {
      required: true,
      type: Boolean
    },
    customAttributes: {
      required: true,
      type: Array
    }
  },
  data() {
    return {
      isEditThirdPartyDetail: false,
      isEditContractDetail: false,
      isLoadingData: false,
      ddqQuestionsReportingData: null,
      adressInput: null,
      thirdPartyContactList: null,
      search20Id: null,
      thirdPartyDetailForm: {
        addressTextArea: null,
        url: null,
        city: null,
        state: null,
        postCode: null,
        dunsCompanyNumber: null,
        registrationNumber: null,
        contactNumber: null
      },
      contractForm: this.makeContactForm(),
      optionsCurrency: [],
      selectedCurrencyId: null,
      sortBy: "tp_sequence",
      sortDesc: false,
      showAssociatesTab: false,
      attributeType,
      editStatus,
      notAvailableText,
      textAlign,
      getCustomAttributeRatingScaleTypeId
    };
  },
  computed: {
    getThirdPartyData() {
      const {
        comp_name_eng,
        contact_phone,
        created_date,
        third_party_created_by,
        country,
        contact_address,
        city,
        state,
        postal_code,
        website,
        inf_company_id_number,
        registration_number,
        id,
        contract_valid_from,
        contract_expires_at,
        net_value,
        iso,
        onboarding_created_at,
        user_id
      } = this.thirdPartyData;
      const defaultVal = ".....";
      let url = "";

      if (website) {
        url = website.match(/https/g) ? website : `https://${website}`;
      }

      return {
        companyName: comp_name_eng || defaultVal,
        contactPhone: contact_phone || defaultVal,
        dateCreation: created_date
          ? this.setDateFormat(created_date)
          : defaultVal,
        thirdPartyCreatedBy: third_party_created_by || defaultVal,
        country: country || defaultVal,
        contactAddress: contact_address || defaultVal,
        city: city || defaultVal,
        state: state || defaultVal,
        postalCode: postal_code || defaultVal,
        website: website || defaultVal,
        companyIdNumber: inf_company_id_number || defaultVal,
        registrationNumber: registration_number || defaultVal,
        id: id || defaultVal,
        contractValidFrom: contract_valid_from
          ? this.setDateFormat(contract_valid_from)
          : defaultVal,
        contractExpiresAt: contract_expires_at
          ? this.setDateFormat(contract_expires_at)
          : defaultVal,
        netValue: net_value || defaultVal,
        onboardingCreatedAt: onboarding_created_at
          ? this.setDateFormat(onboarding_created_at)
          : defaultVal,
        iso: iso || defaultVal,
        url: url || defaultVal,
        userId: this.getUserFromId(user_id)
      };
    },
    uniqueCustomAttributeList() {
      return this.thirdPartyData.tp_custom_att_searches?.reduce(
        (acc, current) => {
          const index = acc.findIndex(
            ({ tp_customatt_id }) => tp_customatt_id === current.tp_customatt_id
          );
          const isMultipleSelection =
            this.thirdPartyData.tp_custom_att_searches.filter(
              ({ tp_customatt_id }) =>
                tp_customatt_id === current.tp_customatt_id
            ).length > 1;
          if (index === -1) {
            if (isMultipleSelection) {
              acc.push({
                ...current,
                tp_customatt_values: [current.tp_customatt_values],
                tp_values_meta: [current.tp_values_meta]
              });
            } else {
              acc.push(current);
            }
          } else {
            acc[index].tp_customatt_values.push(current.tp_customatt_values);
            acc[index].tp_values_meta.push(current.tp_values_meta);
          }
          return acc;
        },
        []
      );
    },
    makeUiCustomAttributes() {
      return this.customAttributes.reduce((acc, customAttribute) => {
        const availableCustomAttribute = this.uniqueCustomAttributeList?.find(
          (attribute) =>
            customAttribute?.id === parseInt(attribute?.tp_customatt_id)
        );
        if (
          customAttribute?.id ===
          parseInt(availableCustomAttribute?.tp_customatt_id)
        ) {
          if (customAttribute.tp_type === attributeType.MULTISELECT) {
            acc.push({
              ...customAttribute,
              tp_values: availableCustomAttribute.tp_values_meta,
              ...makeCustomAttributeDTO(availableCustomAttribute)
            });
          } else if (
            customAttribute.tp_type === attributeType.MULTISELECT_COUNTRIES
          ) {
            acc.push({
              ...customAttribute,
              tp_values: this.parseCountriesData(availableCustomAttribute),
              ...makeCustomAttributeDTO(availableCustomAttribute)
            });
          } else if (customAttribute.tp_type === attributeType.BOOLEAN) {
            acc.push({
              ...customAttribute,
              tp_values:
                parseInt(availableCustomAttribute?.tp_customatt_values) === 1
                  ? "Yes"
                  : "No",
              ...makeCustomAttributeDTO(availableCustomAttribute)
            });
          } else if (
            [attributeType.USER, attributeType.COUNTRY_LIST].includes(
              customAttribute.tp_type
            )
          ) {
            acc.push({
              ...customAttribute,
              tp_values: availableCustomAttribute.tp_customatt_values
                ? JSON.parse(availableCustomAttribute.tp_customatt_values).value
                : "",
              ...makeCustomAttributeDTO(availableCustomAttribute)
            });
          } else if (
            [attributeType.IMAGE, attributeType.FILE].includes(
              customAttribute.tp_type
            )
          ) {
            acc.push({
              ...customAttribute,
              hash_id: availableCustomAttribute.hash_id,
              meta_data: availableCustomAttribute.meta_data
                ? JSON.parse(availableCustomAttribute.meta_data)
                : "",
              tp_values: availableCustomAttribute.tp_customatt_values,
              ...makeCustomAttributeDTO(availableCustomAttribute)
            });
          } else if (customAttribute.tp_type === attributeType.RATING) {
            acc.push({
              ...customAttribute,
              ...makeCustomAttributeDTO(availableCustomAttribute)
            });
          } else {
            acc.push({
              ...customAttribute,
              tp_values:
                availableCustomAttribute?.tp_values_meta ||
                availableCustomAttribute.tp_customatt_values,
              ...makeCustomAttributeDTO(availableCustomAttribute)
            });
          }
        } else {
          acc.push({
            ...customAttribute,
            ...makeCustomAttributeDTO(customAttribute)
          });
        }
        return acc;
      }, []);
    },
    ...mapGetters({
      getPlatformUserListOptions:
        getterName.PLATFORM_SETTINGS.GET_PLATFORM_USER_LIST_OPTIONS
    })
  },
  methods: {
    parseCountriesData(availableCustomAttribute) {
      return Array.isArray(availableCustomAttribute.tp_customatt_values)
        ? availableCustomAttribute.tp_customatt_values.map(
            (country) => JSON.parse(country).value
          )
        : JSON.parse(availableCustomAttribute.tp_customatt_values).value;
    },
    makeContactForm() {
      return {
        validFrom: null,
        expires: null,
        netValue: null,
        currency: null
      };
    },
    resetContactForm() {
      this.isEditContractDetail = false;
      this.contractForm = this.makeContactForm();
      this.selectedCurrencyId = null;
    },
    setDateFormat(value) {
      moment.locale("en");
      return moment(value).format("DD/MM/YYYY");
    },
    customFormatter(date) {
      moment.locale("en");
      return moment(date).format("MMMM DD YYYY");
    },
    selectCurrency(value) {
      this.selectedCurrencyId = value.id;
    },
    async getCurrencyOptions() {
      try {
        this.isLoadingData = true;
        let result = await HTTP(
          "get",
          endpoints.CURRENCIES_GET,
          null,
          makeAuthorizationHeader()
        );
        if (
          parseInt(result?.data?.success) === 1 &&
          parseInt(result?.data?.httpStatus) === 200
        ) {
          this.optionsCurrency = result?.data?.currencies;
        } else {
          this.optionsCurrency = [];
        }
      } catch (error) {
        this.optionsCurrency = [];
        return [error];
      } finally {
        this.isLoadingData = false;
      }
    },
    makeContactDetails() {
      return {
        thirdPartyId: this.thirdPartyId,
        companyId: this.companyId,
        srch_id: this.thirdPartyData.srch_id,
        onboarding_id: this.thirdPartyData.onboarding_id,
        currency_id: this.selectedCurrencyId,
        valid_from: this.contractForm.validFrom,
        expires_at: this.contractForm.expires,
        net_value: this.contractForm.netValue
      };
    },
    async saveContactDetails() {
      try {
        this.isLoadingData = true;
        await HTTP(
          "post",
          endpoints.THIRD_PARTY_SAVE_CONTACT,
          this.makeContactDetails(),
          makeAuthorizationHeader()
        );
        this.getThirdPartyContacts();
        this.resetContactForm();
        this.$emit("getMyDetailsThirdParty", "");
      } catch (error) {
        return error;
      } finally {
        this.isLoadingData = false;
      }
    },
    onEditContract() {
      this.isEditContractDetail = true;
      this.contractForm = {
        validFrom: this.thirdPartyData.contract_valid_from,
        expires: this.thirdPartyData.contract_expires_at,
        netValue: this.thirdPartyData.net_value,
        currency: {
          id: this.thirdPartyData.currency_id,
          iso: this.thirdPartyData.iso,
          currency_name: this.thirdPartyData.currency_name
        }
      };
      this.selectedCurrencyId = this.thirdPartyData.currency_id;
    },
    onEditThirdPartyDetail() {
      this.isEditThirdPartyDetail = true;
      this.thirdPartyDetailForm = {
        addressTextArea: this.thirdPartyData.contact_address,
        url: this.thirdPartyData.website,
        city: this.thirdPartyData.city,
        state: this.thirdPartyData.state,
        postCode: this.thirdPartyData.postal_code,
        dunsCompanyNumber: this.thirdPartyData.inf_company_id_number,
        registrationNumber: this.thirdPartyData.registration_number,
        contactNumber: this.thirdPartyData.contact_phone
      };
    },
    resetThirdPartyForm() {
      this.isEditThirdPartyDetail = false;
      this.thirdPartyDetailForm = {
        addressTextArea: null,
        url: null,
        city: null,
        state: null,
        postCode: null,
        dunsCompanyNumber: null,
        registrationNumber: null,
        contactNumber: null
      };
    },
    makeThirdPartyDetails() {
      return {
        thirdPartyId: this.thirdPartyId,
        url: this.thirdPartyDetailForm.url,
        address: this.thirdPartyDetailForm.addressTextArea,
        city: this.thirdPartyDetailForm.city,
        state: this.thirdPartyDetailForm.state,
        postal_code: this.thirdPartyDetailForm.postCode,
        inf_company_id_number: this.thirdPartyDetailForm.dunsCompanyNumber,
        registration_number: this.thirdPartyDetailForm.registrationNumber
      };
    },
    async saveThirdPartyDetail() {
      try {
        this.isLoadingData = true;
        await HTTP(
          "post",
          endpoints.THIRD_PARTY_UPDATE_THIRD_PARTY,
          this.makeThirdPartyDetails(),
          makeAuthorizationHeader()
        );
        this.getThirdPartyContacts();
        this.resetThirdPartyForm();
        this.$emit("getMyDetailsThirdParty", "");
      } catch (error) {
        return error;
      } finally {
        this.isLoadingData = false;
      }
    },
    async getThirdPartyContacts() {
      try {
        this.isLoadingData = true;
        let { data = {} } = await HTTP(
          "post",
          endpoints.THIRD_PARTY_CONTACT_LIST,
          {
            thirdPartyId: this.thirdPartyId
          },
          makeAuthorizationHeader()
        );
        this.thirdPartyContactList =
          data.response.length && parseInt(data.status) === 1
            ? data.response
            : null;
      } catch (error) {
        return error;
      } finally {
        this.isLoadingData = false;
      }
    },
    makeDDQQuestionsReportingFormObj() {
      return {
        type: 1,
        companyId: this.companyId,
        thirdPartyId: this.thirdPartyId
      };
    },
    async fetchDdqQuestionsReport() {
      const formData = this.makeDDQQuestionsReportingFormObj();
      try {
        this.isLoadingData = true;
        const result = await HTTP(
          "post",
          endpoints.THIRD_PARTY_QUESTION_REPORT,
          formData,
          makeAuthorizationHeader()
        );
        this.ddqQuestionsReportingData = isValidResponse(result)
          ? result.data.response
          : null;
      } catch (error) {
        this.ddqQuestionsReportingData = null;
        return error;
      } finally {
        this.isLoadingData = false;
        if (this.lazyLoad) {
          this.$store.state.ThirdPartyDetailsAssocTabLazyLoad = false;
        }
      }
    },
    openImage(hashId) {
      let urlAddress = `${process.env.VUE_APP_BASE_URL}/download_documents.php?docid=${hashId}`;
      window.open(urlAddress);
    },
    openUrl(url) {
      window.open(url);
    },
    openEmail(email) {
      window.location.href = `mailto: + ${email}`;
    },
    getUserFromId(userId) {
      return (
        this.getPlatformUserListOptions.find(
          (user) => user?.value === parseInt(userId)
        )?.text || ""
      );
    }
  },
  mounted() {
    this.getThirdPartyContacts();
    this.fetchDdqQuestionsReport();
    this.getCurrencyOptions();
    if (this.$route.hash === "#details") {
      this.showAssociatesTab = true;
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/styles/custom.scss";

.third-party-details {
  &__details-tab {
    &-card {
      border: 0;
    }
    &-card-title {
      font-size: 18px;
    }
    &-card-body {
      &-link {
        overflow-wrap: anywhere;
        width: 90%;
      }
    }
  }
  &__contract-details-tab {
    &-card {
      border: 0;
    }
    &-card-title {
      font-size: 18px;
    }
    &-card-body {
      height: 350px;
      overflow: hidden auto;
    }
    &-form-date-picker-wrapper {
      height: calc(1.5em + 0.5rem + 2px);
      padding: 0.25rem 0.5rem;
      font-size: 0.875rem;
      line-height: 1.5;
      border-radius: 0.2rem;
    }
    &-form-date-picker-calender {
      width: 260px !important;
      overflow-y: auto;
      height: 323px !important;
      background: $chinese-silver !important;
    }
    &-form-date-picker-input {
      width: 100%;
      border: none;
    }
  }
  &__custom-attributes-tab {
    &-card {
      border: 0;
    }
    &-card-title {
      font-size: 18px;
    }
    &-card-body {
      overflow: hidden auto;
      height: calc(100vh - 230px);
    }
    &-table-file {
      cursor: pointer;
      width: 500px;
    }
    &-table-html-string {
      width: 500px;
    }
  }
  &__self-reported-data-tab {
    &-card {
      border: 0;
    }
    &-card-title {
      font-size: 18px;
    }
    &-card-body {
      height: 350px;
      overflow: hidden auto;
    }
  }
}
</style>
