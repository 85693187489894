import { render, staticRenderFns } from "./UltimateBeneficialOwnersList.vue?vue&type=template&id=fa1513ea&scoped=true"
import script from "./UltimateBeneficialOwnersList.vue?vue&type=script&lang=js"
export * from "./UltimateBeneficialOwnersList.vue?vue&type=script&lang=js"
import style0 from "./UltimateBeneficialOwnersList.vue?vue&type=style&index=0&id=fa1513ea&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fa1513ea",
  null
  
)

export default component.exports