import {
  actionStatus,
  actionStatusValue,
  ratingTypeValue,
  iconSizes,
  defaultIcons
} from "@/constants";

const getDefaultIconByStatus = (status) => {
  switch (status) {
    case actionStatusValue.OK:
    case actionStatus.OK:
      return defaultIcons.CHECK;
    case actionStatusValue.HIGH_ALERT:
    case actionStatus.HIGH_ALERT:
      return defaultIcons.HIGH_ALERT;
    case actionStatusValue.REMEDIATED_OK:
    case actionStatus.REMEDIATED_OK:
      return defaultIcons.REMEDIATED_OK;
    case actionStatusValue.REMEDIATED_HIGH_ALERT:
    case actionStatus.REMEDIATED_HIGH_ALERT:
      return defaultIcons.REMEDIATED_HIGH_ALERT;
    case actionStatusValue.IN_PROGRESS:
    case actionStatus.IN_PROGRESS:
      return defaultIcons.IN_PROGRESS;
    case actionStatusValue.WARNING_ALERT:
    case actionStatus.WARNING_ALERT:
      return defaultIcons.WARNING_ALERT;
    default:
      return defaultIcons.CHECK;
  }
};

const getIcon = (status, iconSize = iconSizes.DEFAULT) => {
  const defaultIcon = getDefaultIconByStatus(status);
  return {
    ...defaultIcon,
    size: iconSize
  };
};

const getRiskRatingIconByStatus = (status) => {
   switch (parseInt(status)) {
     case ratingTypeValue.UNDEFINED:
      return defaultIcons.SQUARE_DASHED;
     case ratingTypeValue.LOW:
      return defaultIcons.CHECK;
     case ratingTypeValue.MODERATE:
      return defaultIcons.WARNING_ALERT;
     case ratingTypeValue.HIGH:
       return defaultIcons.HIGH_ALERT;
     case ratingTypeValue.DO_NOT_ENGAGE:
      return defaultIcons.DO_NO_ENGAGE;
     default:
      return defaultIcons.SQUARE_DASHED;
   }
};
const getRiskRatingIcon = (status, iconSize = iconSizes.DEFAULT) => {
   const riskRatingIcon = getRiskRatingIconByStatus(status);
   return {
     ...riskRatingIcon,
     size: iconSize
   };
};

export { getDefaultIconByStatus, getRiskRatingIconByStatus, getIcon, getRiskRatingIcon };
