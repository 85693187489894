<template>
  <div>
    <!-- bulk perform actions modal start -->
    <b-modal
      hide-header-close
      no-close-on-backdrop
      no-close-on-esc
      title="Run on all selected Third Parties"
      v-model="showBulkActionsModal"
      :scrollable="taskSectionView ? false : true"
      :size="taskSectionView ? 'false' : 'lg'"
      class="bulk-perform-modal"
    >
      <div>
        <div v-if="!submiting && !responseSuccess">
          <div v-if="taskSectionView">
            <!-- list view start -->
            <div class="cols-4-height-styles" v-if="changeListView">
              <!-- Card available actions start -->
              <b-card
                style="border: 0px !important"
                no-body
                header-tag="header"
                class="mb-3"
              >
                <!-- available actions header -->
                <template #header>
                  <div class="d-flex" style="justify-content: space-between">
                    <div>
                      <h6>Available Actions</h6>
                    </div>
                    <div>
                      <font-awesome-icon
                        v-if="changeListView"
                        @click="changeListView = !changeListView"
                        icon="th-large"
                      />
                      <font-awesome-icon
                        v-else
                        @click="changeListView = !changeListView"
                        icon="list"
                      />
                    </div>
                  </div>
                </template>

                <!-- available actions content -->
                <div>
                  <b-list-group>
                    <b-list-group-item
                      v-for="(action, index) in allActionsAvailable"
                      :key="index"
                      @click="onSelectAvailableActions(action)"
                      v-show="!action.isHidden"
                      size="sm"
                      :disabled="
                        isArchived
                          ? true
                          : thirdPartyData.length == 0
                          ? action.isDisabled
                          : false
                      "
                      :style="{
                        backgroundColor: action.isActive ? '#d0f2d2' : 'white',
                        color: 'black',
                        width: '100%',
                        border: '0px'
                      }"
                    >
                      <div style="display: flex; align-items: center">
                        <div>
                          <BaseIcon
                            v-if="isArchived"
                            :shape="shapes.SQUARE"
                            :border="border.DASHED"
                            :size="25"
                          />
                          <CallToAction
                            v-else
                            type="button"
                            :icon="icons.PLUS"
                            :icon-size="35"
                            :icon-space="space.NONE"
                            :theme="selectedActionTheme(action.isActive)"
                            :has-icon-with-text="true"
                            @click="
                              action.actionType == 'edd'
                                ? onSelectExtraActionOccurEdd(action)
                                : null
                            "
                          />
                        </div>
                        <div style="margin-left: 20px">
                          <div v-if="action.actionType == 'edd'">
                            <p @click="onSelectExtraActionOccurEdd(action)">
                              <b
                                :style="{
                                  color: isArchived ? 'lightgray' : ''
                                }"
                                class="d-flex"
                                >{{ action.btnText }}</b
                              >
                            </p>
                            <b-dropdown
                              :disabled="isArchived"
                              variant="light"
                              size="lg"
                              :text="
                                selecteditem
                                  ? selecteditem
                                  : 'Select Report Type'
                              "
                            >
                              <b-overlay :show="isLoadingPackages">
                                <div
                                  class="dropdown-item bg-white text-body"
                                  v-for="(item, index) in listOfEDD"
                                  :key="index"
                                >
                                  <div style="display: flex">
                                    <b-dropdown-item
                                      @click="setSelectedItem(item)"
                                      >{{ item.name }}</b-dropdown-item
                                    >
                                  </div>
                                </div>
                              </b-overlay>
                            </b-dropdown>
                            <b-overlay
                              :show="isLoadingElements || isLoadingPackages"
                            >
                              <b-list-group flush>
                                <b-list-group-item
                                  v-for="element in allElements"
                                  :key="element.id"
                                  class="mb-1"
                                  style="display: flex"
                                >
                                  <font-awesome-icon
                                    class="m-2"
                                    :icon="
                                      element.package_products_id > 0
                                        ? 'check'
                                        : 'times'
                                    "
                                    :style="{
                                      color:
                                        element.package_products_id > 0
                                          ? '#28a745'
                                          : '#CBCFD1'
                                    }"
                                  />
                                  <p
                                    :style="{
                                      color:
                                        element.package_products_id > 0
                                          ? ''
                                          : '#CBCFD1'
                                    }"
                                  >
                                    {{ element.product_name }}
                                  </p>
                                </b-list-group-item>
                              </b-list-group>
                              <div v-if="!isLoadingPackages">
                                <div
                                  class="mb-1"
                                  v-if="
                                    parseInt(
                                      selectedItemArray.client_packages_tat
                                    ) > 0
                                  "
                                  style="display: flex"
                                >
                                  <font-awesome-icon
                                    class="m-1"
                                    icon="user-friends"
                                  />
                                  <p>
                                    Includes upto 2 associates. [X] associates
                                    are indicated on the third party record.
                                    Additional fees may apply
                                  </p>
                                </div>
                                <div
                                  class="mb-1"
                                  v-if="
                                    parseInt(
                                      selectedItemArray.client_packages_tat
                                    ) > 0
                                  "
                                  style="display: flex"
                                >
                                  <font-awesome-icon class="m-1" icon="clock" />
                                  <p>
                                    {{ selectedItemArray.client_packages_tat }}
                                    Days Turn Around Time
                                  </p>
                                </div>
                              </div>
                            </b-overlay>
                          </div>
                          <p v-else>
                            <b
                              :style="{ color: isArchived ? 'lightgray' : '' }"
                              class="d-flex"
                              >{{ action.btnText }}</b
                            >
                          </p>
                        </div>
                      </div>
                    </b-list-group-item>
                  </b-list-group>
                </div>
              </b-card>
              <!-- Card available actions end -->

              <!-- Card Other actions available (but not subscribed) start -->
              <b-card
                style="border: 0px"
                class="mb-3"
                no-body
                header-tag="header"
              >
                <!-- unavailable actions header -->
                <template #header>
                  <div class="d-flex" style="justify-content: space-between">
                    <div>
                      <h6>Other actions available (but not subscribed)</h6>
                    </div>
                    <div></div>
                  </div>
                </template>

                <!-- unavailable actions content -->
                <div>
                  <b-list-group>
                    <b-list-group-item
                      v-for="(action, index) in actionsNotAvailable"
                      :key="index"
                    >
                      <div style="display: flex">
                        <BaseIcon
                          :shape="shapes.SQUARE"
                          :border="border.DASHED"
                          :size="25"
                        />
                        <div style="margin-left: 20px">
                          <b class="d-flex" style="color: lightgray"
                            >{{ action.btnText }}
                          </b>
                        </div>
                      </div>
                    </b-list-group-item>
                  </b-list-group>
                </div>
              </b-card>
              <!-- Card Other actions available (but not subscribed) end -->
            </div>
            <!-- list view end -->

            <!-- Grid view start -->
            <div
              style="
                overflow-y: auto;
                height: calc(100vh - 240px);
                overflow-x: hidden;
              "
              v-if="!changeListView"
            >
              <!-- Card available actions start -->
              <b-card
                style="border: 0px !important"
                class="mb-3"
                no-body
                header-tag="header"
              >
                <!-- available actions header -->
                <template #header>
                  <div class="d-flex" style="justify-content: space-between">
                    <div>
                      <h6>Available Actions</h6>
                    </div>
                    <div class="d-flex">
                      <div>
                        <font-awesome-icon
                          v-if="changeListView"
                          @click="changeListView = !changeListView"
                          icon="th-large"
                        />
                        <font-awesome-icon
                          v-else
                          @click="changeListView = !changeListView"
                          icon="list"
                        />
                      </div>
                    </div>
                  </div>
                </template>

                <!-- available actions content -->
                <div>
                  <b-row style="margin-left: 1px; flex-wrap: wrap">
                    <div
                      :style="
                        thirdPartyData.length == 0 && action.isDisabled
                          ? 'width: 30%;pointer-events: none;'
                          : 'width: 30%;'
                      "
                      v-for="(action, index) in allActionsAvailable"
                      v-show="!action.isHidden"
                      :key="index"
                    >
                      <b-btn
                        @click="
                          action.actionType == 'edd'
                            ? onSelectExtraActionOccurEdd(action)
                            : onSelectAvailableActions(action)
                        "
                        size="sm"
                        :style="{
                          width: '100%',
                          backgroundColor: action.isActive
                            ? '#d0f2d2'
                            : 'white',
                          height: '100%',
                          borderColor: '#eeeeee'
                        }"
                        :disabled="isArchived"
                      >
                        <div class="bulk-perform-modal__available-actions">
                          <BaseIcon v-bind="getIcon(action.isActive)" />
                          <br />
                          <b-popover
                            :show="showDropDownModal"
                            target="popover-target-1"
                            triggers="click"
                            placement="auto"
                          >
                            <template #title>
                              <b-button
                                @click="showDropDownModal = false"
                                class="close"
                                aria-label="Close"
                              >
                                <font-awesome-icon
                                  class="d-inline-block"
                                  aria-hidden="true"
                                  icon="times-circle"
                                />
                              </b-button>
                              Set Report Type
                            </template>
                            <b-dropdown
                              variant="light"
                              size="lg"
                              class="pb-2"
                              :text="
                                selecteditem
                                  ? selecteditem
                                  : 'Select Report Type'
                              "
                            >
                              <b-overlay :show="isLoadingPackages">
                                <div
                                  class="dropdown-item bg-white text-body"
                                  v-for="(item, index) in listOfEDD"
                                  :key="index"
                                >
                                  <div style="display: flex">
                                    <b-dropdown-item
                                      @click="setSelectedItem(item)"
                                      >{{ item.name }}</b-dropdown-item
                                    >
                                  </div>
                                </div>
                              </b-overlay>
                            </b-dropdown>
                            <b-list-group
                              style="height: 368px; overflow: auto"
                              flush
                            >
                              <b-list-group-item
                                v-for="element in allElements"
                                :key="element.id"
                                class="mb-1"
                                style="display: flex"
                              >
                                <font-awesome-icon
                                  class="m-2"
                                  :icon="
                                    element.package_products_id > 0
                                      ? 'check'
                                      : 'times'
                                  "
                                  :style="{
                                    color:
                                      element.package_products_id > 0
                                        ? '#28a745'
                                        : '#CBCFD1'
                                  }"
                                />
                                <p
                                  :style="{
                                    color:
                                      element.package_products_id > 0
                                        ? ''
                                        : '#CBCFD1'
                                  }"
                                >
                                  {{ element.product_name }}
                                </p>
                              </b-list-group-item>
                            </b-list-group>
                            <div v-if="!isLoadingPackages">
                              <div
                                class="mb-1"
                                v-if="
                                  parseInt(
                                    selectedItemArray.client_packages_tat
                                  ) > 0
                                "
                                style="display: flex"
                              >
                                <font-awesome-icon
                                  class="m-1"
                                  icon="user-friends"
                                />
                                <p>
                                  Includes upto 2 associates. [X] associates are
                                  indicated on the third party record.
                                  Additional fees may apply
                                </p>
                              </div>
                              <div
                                class="mb-1"
                                v-if="
                                  parseInt(
                                    selectedItemArray.client_packages_tat
                                  ) > 0
                                "
                                style="display: flex"
                              >
                                <font-awesome-icon class="m-1" icon="clock" />
                                <p>
                                  {{ selectedItemArray.client_packages_tat }}
                                  Days Turn Around Time
                                </p>
                              </div>
                            </div>
                          </b-popover>
                          <b
                            class="m-1"
                            :style="{
                              color: isArchived ? 'lightgray' : 'black'
                            }"
                            :id="
                              action.actionType == 'edd'
                                ? 'popover-target-1'
                                : ''
                            "
                            >{{ action.gridBtntext }}</b
                          >
                          <b
                            class="m-1"
                            style="color: black"
                            v-if="action.actionType == 'edd' && selecteditem"
                            >[{{ selecteditem }}]</b
                          >
                        </div>
                      </b-btn>
                    </div>
                  </b-row>
                </div>
              </b-card>
              <!-- Card available actions end -->

              <!-- Card Other actions available (but not subscribed) start -->
              <b-card
                style="border: 0px"
                class="mb-3"
                no-body
                header-tag="header"
              >
                <!-- unavailable actions header -->
                <template #header>
                  <div class="d-flex" style="justify-content: space-between">
                    <div>
                      <h6>Other actions available (but not subscribed)</h6>
                    </div>
                  </div>
                </template>

                <!-- unavailable actions content -->
                <b-row>
                  <b-col
                    v-for="(action, index) in actionsNotAvailable"
                    :key="index"
                    cols="4"
                  >
                    <b-btn
                      size="sm"
                      variant="light"
                      class="bg-white"
                      style="width: 100%; height: 100%"
                    >
                      <div class="bulk-perform-modal__available-actions">
                        <BaseIcon
                          :shape="shapes.SQUARE"
                          :border="border.DASHED"
                          :size="25"
                        />
                        <br />
                        <b class="m-1" style="color: lightgray">
                          {{ action.gridBtntext }}
                        </b>
                      </div>
                    </b-btn>
                  </b-col>
                </b-row>
              </b-card>
              <!-- Card Other actions available (but not subscribed) end -->
            </div>
            <!-- Grid view end -->
          </div>
          <div v-else>
            <!-- if IDD+ is active and No sufficient vouchers available -->
            <!-- if MSQ is active and No primary contact defined. -->
            <p>
              <b
                >The following actions will not run due to the following
                reasons</b
              >
            </p>
            <b-table-simple bordered>
              <b-thead>
                <b-tr>
                  <b-th>Third Party</b-th>
                  <b-th>Action</b-th>
                  <b-th>Reason</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr
                  v-for="(reasons, reasonsIndex) in rejectedThirdParties"
                  :key="reasonsIndex"
                >
                  <b-td style="width: 50%; white-space: inherit"
                    >{{ reasons.comp_name_eng }}&nbsp;({{ reasons.id }})</b-td
                  >
                  <b-td>{{ reasons.action }}</b-td>
                  <b-td>{{ reasons.reason }}</b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </div>
        </div>
        <!-- loader -->
        <div
          v-else-if="submiting && !responseSuccess"
          style="display: flex; justify-content: center"
        >
          <p>Actions in progress......</p>
          <b-spinner type="grow" label="Loading..."></b-spinner>
        </div>
        <!-- loader -->

        <!-- success message -->
        <div v-else>
          <p class="my-2 text-center">
            Actions performed successfully.
            <br />
          </p>
        </div>
        <!-- success message -->
      </div>
      <template v-slot:modal-footer>
        <b-row class="w-100">
          <b-col cols="6">
            <b-btn
              size="sm"
              variant="outline-primary"
              v-if="!submiting && !responseSuccess && !taskSectionView"
              @click="taskSectionView = true"
              >Back</b-btn
            >
          </b-col>
          <b-col cols="6">
            <div class="float-right">
              <b-button
                v-if="!submiting"
                @click="closeModal"
                class="mr-1"
                size="sm"
                variant="outline-danger"
                >Close</b-button
              >
              <b-button
                class="mx-1"
                v-if="!submiting && !responseSuccess && !taskSectionView"
                @click="onCallPerfromSelectedTasksApi"
                size="sm"
                :disabled="
                  allActionsAvailable.findIndex((btn) => btn.isActive) == -1
                    ? true
                    : false
                "
                variant="outline-success"
                >Submit</b-button
              >
              <b-button
                class="mx-1"
                v-if="!submiting && !responseSuccess && taskSectionView"
                @click="submitSelectedActions"
                size="sm"
                :disabled="
                  allActionsAvailable.findIndex((btn) => btn.isActive) == -1
                    ? true
                    : false
                "
                variant="outline-success"
                >Submit</b-button
              >
            </div>
          </b-col>
        </b-row>
      </template>
    </b-modal>
    <!-- bulk perform actions modal end -->
  </div>
</template>

<script>
import { HTTP, makeAuthorizationHeader } from "@/utils";
import { EventBus } from "@/event-bus";
import { mapState } from "vuex";
import {
  eddContracts,
  themes,
  icons,
  shapes,
  border,
  space
} from "@/constants";
import BaseIcon from "@/atoms/BaseIcon/BaseIcon";
import CallToAction from "@/atoms/CallToAction/CallToAction";

export default {
  name: "BulkPerformActionsModal",
  components: {
    BaseIcon,
    CallToAction
  },
  data() {
    return {
      themes,
      icons,
      shapes,
      border,
      space,
      taskSectionView: true,
      submiting: false,
      responseSuccess: false,
      isLoading: false,
      showBulkActionsModal: false,
      changeListView: true,
      allActionsAvailable: [],
      actionsNotAvailable: [],
      rejectedThirdParties: [],
      thirdPartyData: [],
      allElements: [],
      isLoadingElements: false,
      isLoadingPackages: false,
      listOfEDD: [],
      selecteditem: null,
      selectedItemArray: [],
      showDropDownModal: false,
      selectedId: null,
      isArchived: false
    };
  },
  computed: {
    ...mapState({
      remainingVouchers: (state) => state.company.remainingVouchers,
      companyDetails: (state) => state.company.companyDetails,
      companyId: (state) => state.company.companyId,
      userId: (state) => state.user.userId
    })
  },
  created() {
    EventBus.$on("onBulkPerformActionsOpen", (thirdPartiesData) => {
      let configuredActionData = localStorage.getItem("actionsSchema");
      let bulkActions = localStorage.getItem("bulkActions");
      this.bulkActionModalShow(
        JSON.parse(configuredActionData),
        JSON.parse(bulkActions),
        thirdPartiesData
      );
    });
  },
  mounted() {
    this.getDropDownItems();
  },
  beforeDestroy() {},
  methods: {
    // shwo bulk action modal
    bulkActionModalShow(actionsSchema, bulkActions, thirdPartiesData) {
      this.thirdPartyData = [];
      this.rejectedThirdParties = [];
      this.allActionsAvailable = [];
      this.actionsNotAvailable = [];

      if (thirdPartiesData.length == 0) {
        if (
          actionsSchema &&
          actionsSchema.bulkAllAvailableActions &&
          actionsSchema.bulkActionNotAvailable
        ) {
          actionsSchema.bulkAllAvailableActions.map((actions) => {
            if (actions.isDisabled) {
              this.actionsNotAvailable.push(actions);
            } else {
              this.allActionsAvailable.push(actions);
            }
          });
          actionsSchema.bulkActionNotAvailable.map((actions) => {
            if (!actions.isHidden) {
              this.actionsNotAvailable.push(actions);
            }
          });
        } else {
          bulkActions.availableActions.map((actions) => {
            if (actions.isDisabled) {
              this.actionsNotAvailable.push(actions);
            } else {
              this.allActionsAvailable.push(actions);
            }
          });
          bulkActions.actionsNotAvailable.map((actions) => {
            if (!actions.isHidden) {
              this.actionsNotAvailable.push(actions);
            }
          });
        }
      } else {
        if (
          actionsSchema &&
          actionsSchema.bulkAllAvailableActions &&
          actionsSchema.bulkActionNotAvailable
        ) {
          this.allActionsAvailable.push(
            ...actionsSchema.bulkAllAvailableActions
          );
          actionsSchema.bulkActionNotAvailable.map((actions) => {
            if (!actions.isHidden) {
              this.actionsNotAvailable.push(actions);
            }
          });
        } else {
          this.allActionsAvailable.push(...bulkActions.availableActions);
          bulkActions.actionsNotAvailable.map((actions) => {
            if (!actions.isHidden) {
              this.actionsNotAvailable.push(actions);
            }
          });
        }
      }

      if (thirdPartiesData.length > 1) {
        let eddTypeIndex = this.allActionsAvailable.findIndex(
          (x) => x.actionType == "edd"
        );
        if (eddTypeIndex != -1) {
          this.actionsNotAvailable.push(this.allActionsAvailable[eddTypeIndex]);
          this.allActionsAvailable.splice(eddTypeIndex, 1);
        }
      }
      if (thirdPartiesData.length) {
        thirdPartiesData.map((data) => {
          let objectData = {
            id: data.id,
            comp_name_eng: data.comp_name_eng,
            meta_json: data.meta_json,
            risk_level: data.risk_level,
            is_idd: data.is_idd,
            is_edd_or: data.is_edd_or,
            is_edd: data.is_edd,
            srch_id: data.srch_id,
            availableEmail: data.lg_email,
            country: data.country,
            website: data.website
          };
          this.thirdPartyData.push(objectData);
          this.isArchived = parseInt(data.is_archived) == 1;
        });
      }

      this.showBulkActionsModal = true;
    },
    onSelectAvailableActions(action) {
      if (action.actionType != "edd") {
        this.allActionsAvailable.find((btn) => {
          if (btn.id == action.id && btn.title == action.title) {
            btn.isActive = !btn.isActive;
            btn.requestedDate = new Date();
          }
        });
      }
    },

    // disable submit button if any error comes
    disableSumbitButton() {
      let selectedActions = this.allActionsAvailable.filter(
        (btn) => btn.isActive
      );
      if (selectedActions.length == 0) {
        return 0;
      } else {
        return 1;
      }
    },

    // check error before submitting
    submitSelectedActions() {
      this.rejectedThirdParties = [];
      let selectedActions = this.allActionsAvailable.filter(
        (btn) => btn.isActive
      );
      let iddPlusIndex = selectedActions.findIndex(
        (x) => x.actionType == "idd_plus"
      );
      let msqIndex = selectedActions.findIndex((x) => x.actionType == "msq");
      if (iddPlusIndex != -1) {
        if (this.remainingVouchers === 0 && this.remainingVouchers !== -1) {
          this.thirdPartyData.map((thirdParties) => {
            this.rejectedThirdParties.push({
              id: thirdParties.id,
              comp_name_eng: thirdParties.comp_name_eng,
              reason: "No sufficient vouchers available",
              action: "IDD+"
            });
          });
        }
      }
      if (msqIndex != -1) {
        let withoutEmailThirdParty = this.thirdPartyData.filter(
          (x) => x.availableEmail == null || x.availableEmail == ""
        );
        if (withoutEmailThirdParty.length != 0) {
          withoutEmailThirdParty.map((thirdParties) => {
            this.rejectedThirdParties.push({
              id: thirdParties.id,
              comp_name_eng: thirdParties.comp_name_eng,
              reason: "No primary contact defined.",
              action: "MSQ"
            });
          });
        }
      }
      if (this.rejectedThirdParties.length == 0) {
        this.onCallPerfromSelectedTasksApi();
      } else {
        this.taskSectionView = false;
      }
    },
    async onCallPerfromSelectedTasksApi() {
      await this.callPerfromSelectedTasksApi();
      this.$emit("reload-table");
    },
    async callPerfromSelectedTasksApi() {
      let selectedActions = this.allActionsAvailable.filter(
        (btn) => btn.isActive
      );

      let IDDTypeIndex = selectedActions.findIndex(
        (x) => x.actionType == "idd"
      );
      if (IDDTypeIndex != -1) {
        selectedActions[IDDTypeIndex].requestedDate = new Date();
      }

      let due_diligence_type = "report_type_idd_free";
      let dueDiligenceTypeIndex = selectedActions.findIndex(
        (x) => x.actionType == "idd_plus"
      );
      if (dueDiligenceTypeIndex != -1) {
        due_diligence_type = "report_type_idd_plus";
        selectedActions[dueDiligenceTypeIndex].requestedDate = new Date();
      }

      let isMSQAllIndex = selectedActions.findIndex(
        (x) => x.actionType == "msq_all"
      );
      let is_msq_all = 0;
      if (isMSQAllIndex != -1) {
        is_msq_all = 1;
        selectedActions.splice(isMSQAllIndex, 1);

        let isSelectedMSQIndex = selectedActions.findIndex(
          (x) => x.actionType == "msq"
        );
        if (isSelectedMSQIndex == -1) {
          let isMSQIndex = this.allActionsAvailable.findIndex(
            (x) => x.actionType == "msq"
          );
          if (isMSQIndex != -1) {
            this.allActionsAvailable[isMSQIndex].isActive = true;
            this.allActionsAvailable[isMSQIndex].requestedDate = new Date();
            selectedActions.push(this.allActionsAvailable[isMSQIndex]);
          }
        }
      }
      selectedActions.map((actions) => {
        if (actions.actionType == "apri") {
          actions.requestedDate = new Date();
        }
      });

      let standardTypeIndex = selectedActions.findIndex(
        (x) => x.actionType == "pri"
      );
      if (standardTypeIndex != -1) {
        selectedActions[standardTypeIndex].requestedDate = new Date();
      }

      let productPriceId = 0;
      let eddTypeIndex = selectedActions.findIndex(
        (x) => x.actionType == "edd"
      );
      if (eddTypeIndex != -1) {
        selectedActions[eddTypeIndex].requestedDate = new Date();
        productPriceId = this.selectedItemArray.product_price_id;
      }

      let productsArr = this.allElements.filter(
        (element) => element.package_products_id > 0
      );
      let productsName = [];
      productsArr.map((product) => {
        productsName.push(product.product_name);
      });

      let sendReminderIndex = selectedActions.findIndex(
        (x) => x.actionType == "msq_reminder"
      );
      if (sendReminderIndex != -1) {
        selectedActions[sendReminderIndex].requestedDate = new Date();
      }

      let formData = {
        thirdPartyData: this.thirdPartyData,
        companyId: this.companyId,
        memberId: this.userId,
        userId: this.userId,
        is_msq_all,
        due_diligence_type,
        bulkActions: selectedActions,
        background: 1
      };
      if (eddTypeIndex != -1) {
        formData.edd = {
          bill_name: this.companyDetails.billName,
          bill_email: this.companyDetails.billEmail,
          cost: this.selectedItemArray.client_packages_cost,
          tat: this.selectedItemArray.client_packages_tat,
          tat_max: this.selectedItemArray.client_packages_tat_max,
          package_id: this.selectedItemArray.packagesId,
          region_id: this.selectedItemArray.region_id,
          bill_country: this.companyDetails.billCountry,
          billing_add: this.companyDetails.billingAdd,
          eddScope: this.selecteditem,
          product_price_id: productPriceId,
          products: productsName
        };
      }
      try {
        this.submiting = true;
        this.isLoading = true;
        let result = await HTTP(
          "post",
          "thirdparty/perform/bulk/actions",
          formData,
          makeAuthorizationHeader()
        );
        if (result.data.message) {
          if (
            productPriceId > 0 &&
            this.companyDetails.eddContract === eddContracts.ORDER
          ) {
            this.$bvToast.toast(
              "“Thank you for your Enhanced Due Diligence Order. This order has submitted to our team for action. You will be notified once the report is available on the platform”",
              {
                variant: "success",
                autoHideDelay: 5000,
                appendToast: false
              }
            );
          } else if (
            productPriceId > 0 &&
            this.companyDetails.eddContract === eddContracts.ENQUIRY
          ) {
            this.$bvToast.toast(
              "“Thank you for your Enhanced Due Diligence enquiry. This order has submitted to our team for action. You will be contacted shortly”",
              {
                variant: "success",
                autoHideDelay: 5000,
                appendToast: false
              }
            );
          }
        }
        this.submiting = false;
        this.isLoading = false;
        this.responseSuccess = true;
        EventBus.$emit("onBulkPerformActionsClose");
      } catch (error) {
        this.isLoading = false;
        this.submiting = false;
        this.responseSuccess = false;
      }
    },
    closeModal() {
      this.responseSuccess = false;
      this.submiting = false;
      this.isLoading = false;
      this.thirdPartyData = [];
      this.rejectedThirdParties = [];
      this.allActionsAvailable = [];
      this.actionsNotAvailable = [];
      if (this.submiting == false && this.responseSuccess == true) {
        this.$parent.selectedMultipleThirdParties = [];
        this.$parent.multipleThirdParties = [];
        this.$parent.thirdPartyIdsActionsData = [];
      }
      this.taskSectionView = true;
      this.selecteditem = null;
      this.allElements = [];
      this.selectedItemArray = [];
      this.selectedId = null;
      this.showDropDownModal = false;
      this.isLoadingPackages = false;
      this.isLoadingElements = false;

      this.showBulkActionsModal = false;
      EventBus.$emit("onBulkPerformActionsClose");
    },

    // get edd order types
    async getDropDownItems() {
      this.isLoadingPackages = true;
      try {
        let result = await HTTP(
          "post",
          `thirdparty/edd/client/all/packages/${this.companyId}`,
          null,
          makeAuthorizationHeader()
        );
        if (result.status == 200) {
          result.data.map((data) => {
            if (parseInt(data.client_packages_id) > 0) {
              this.listOfEDD.push(data);
            }
          });
          this.isLoadingPackages = false;
        }
      } catch (error) {
        this.isLoadingPackages = false;
        return [error];
      }
    },
    onSelectExtraActionOccurEdd(action) {
      this.allActionsAvailable.find((btn) => {
        let iddTypeIndex = this.allActionsAvailable.findIndex(
          (x) => x.actionType == "idd"
        );
        let iddPlusTypeIndex = this.allActionsAvailable.findIndex(
          (x) => x.actionType == "idd_plus"
        );
        if (btn.id == action.id) {
          btn.isActive = !btn.isActive;
          if (iddTypeIndex != -1) {
            this.allActionsAvailable[iddTypeIndex].isActive = btn.isActive;
          }
          if (iddPlusTypeIndex != -1) {
            this.allActionsAvailable[iddPlusTypeIndex].isActive = btn.isActive;
          }
        }
        if (!this.changeListView && btn.isActive) {
          this.showDropDownModal = true;
        }
      });
    },

    // set selected edd order type
    setSelectedItem(val) {
      this.selecteditem = val.name;
      this.selectedId = val.id;
      this.getElementsByType();
      this.checkValue();
    },

    // call get elements by type API
    async getElementsByType() {
      try {
        this.isLoadingElements = true;
        let result = await HTTP(
          "get",
          `thirdparty/edd/client/packages/selected/products/${this.companyId}/${this.selectedId}`,
          null,
          makeAuthorizationHeader()
        );
        if (result.status == 200) {
          this.allElements = result.data;
          this.isLoadingElements = false;
        }
      } catch (error) {
        this.isLoadingElements = false;
        return [error];
      }
    },
    async getTatCost() {
      this.isLoadingPackages = true;
      let formData = {
        country: this.thirdPartyData[0].country,
        client_id: this.companyId
      };
      try {
        let result = await HTTP(
          "post",
          "thirdparty/edd/client/all/packages",
          formData,
          makeAuthorizationHeader()
        );
        if (result.status == 200 && result.data.status == 1) {
          result.data.data.map((data) => {
            if (data.packagesId == this.selectedId) {
              this.selectedItemArray = data;
            }
          });
          this.allActionsAvailable.find((btn) => {
            if (btn.actionType == "edd") {
              btn.isActive = true;
            }
            if (btn.actionType == "idd") {
              btn.isActive = true;
            }
            if (btn.actionType == "idd_plus") {
              btn.isActive = true;
            }
          });
          this.isLoadingPackages = false;
        } else if (result.data.status == 0) {
          this.$bvToast.toast(result.data.message, {
            title: "Error",
            variant: "danger",
            autoHideDelay: 2000,
            appendToast: false
          });
          this.allActionsAvailable.find((btn) => {
            if (btn.actionType == "edd") {
              btn.isActive = false;
            }
            if (btn.actionType == "idd") {
              btn.isActive = false;
            }
            if (btn.actionType == "idd_plus") {
              btn.isActive = false;
            }
            this.selecteditem = null;
            this.allElements = [];
            this.selectedItemArray = [];
          });
          this.isLoadingPackages = false;
        }
      } catch (error) {
        this.isLoadingPackages = false;
        return [error];
      }
    },
    checkValue() {
      if (this.thirdPartyData[0].country && this.selecteditem) {
        this.getTatCost();
      }
    },
    selectedActionTheme(isActive) {
      return isActive ? themes.SUCCESS_WHITE : themes.SUCCESS_INVERSE_WHITE;
    },
    getIcon(isActive) {
      return {
        icon: icons.PLUS,
        shape: shapes.SQUARE,
        theme: this.selectedActionTheme(isActive),
        border: this.isArchived ? border.DASHED : border.SOLID,
        size: 25
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.bulk-perform-modal {
  &__available-actions {
    display: inline-block;
    text-align: center;
    padding: 8px 0;
  }
}
</style>
