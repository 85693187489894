<template>
  <section class="add-edit-action">
    <Modal
      v-if="isNextStepModalVisible"
      title="Add step"
      data-test-id="add-edit-action__add-step-modal"
      @close-modal="toggleNextStepModal"
    >
      <Select
        isLabelHidden
        class="add-edit-action__modal-select"
        data-test-id="add-edit-action__modal-select"
        label="Select step type"
        placeholder="Select step type"
        name="nextActionStep"
        id="nextActionStep"
        :options="nextStepModalOptions"
        :error="errorMessages.nextActionStep"
        @change="onChangeInput('nextActionStep', $event)"
      />
      <template #left>
        <CallToAction
          type="button"
          theme="error-inverse"
          value="Cancel"
          data-test-id="add-edit-action__modal-cancel-cta"
          @click="onCancelStep"
        />
      </template>
      <template #right>
        <CallToAction
          type="button"
          value="Add step"
          :is-error="nextActionStepHasError"
          data-test-id="add-edit-action__modal-add-cta"
          @click="onAddStep"
        />
      </template>
    </Modal>
    <BaseLoader v-if="isLoading" />
    <template v-else>
      <h2 class="add-edit-action__title" data-test-id="add-edit-action__title">
        {{ getTitle }}
      </h2>
      <form
        class="add-edit-action__form"
        data-test-id="add-edit-action__form"
        @submit.prevent
      >
        <div class="add-edit-action__form-holder">
          <div
            class="
              add-edit-action__form-section
              add-edit-action__form-section--action-details
            "
          >
            <h4
              class="add-edit-action__form-section-title"
              data-test-id="add-edit-action__form-section-title"
            >
              Action Details
            </h4>
            <Input
              id="actionName"
              label="Name"
              name="actionName"
              placeholder="eg. name"
              class="add-edit-action__form-input"
              data-test-id="add-edit-action__form-name"
              :value="actionName"
              :error="errorMessages.actionName"
              :focus="true"
              @change="onChangeInput('actionName', $event)"
            />
            <div v-if="errorMessagesApi[actionKeys.NAME]">
              <InputErrorMessage
                v-for="(errorMessage, index) in errorMessagesApi[
                  actionKeys.NAME
                ]"
                :key="`error-message-api-name-${index}`"
                :error="errorMessage"
              />
            </div>
            <Textarea
              id="actionDescription"
              label="Description"
              name="actionDescription"
              placeholder="Description"
              data-test-id="add-edit-action__form-description"
              :value="actionDescription"
              :error="errorMessages.actionDescription"
              @change="
                (_, $event) => onChangeInput('actionDescription', $event)
              "
            />
            <div v-if="errorMessagesApi[actionKeys.DESCRIPTION]">
              <InputErrorMessage
                v-for="(errorMessage, index) in errorMessagesApi[
                  actionKeys.DESCRIPTION
                ]"
                :key="`error-message-api-description-${index}`"
                :error="errorMessage"
              />
            </div>
          </div>
          <div class="add-edit-action__accordion-actions">
            <CallToAction
              class="add-edit-action__accordion-actions--expand"
              value="Expand all"
              theme="none"
              data-test-id="add-edit-action__accordion-actions--expand"
              @click="setAccordionCollapse(false)"
            />
            <CallToAction
              class="add-edit-action__accordion-actions--collapse"
              value="Collapse all"
              theme="none"
              data-test-id="add-edit-action__accordion-actions--collapse"
              @click="setAccordionCollapse(true)"
            />
          </div>
          <div class="add-edit-action__form-section">
            <AccordionSection
              title="Expected Data"
              data-test-id="add-edit-action__expected-data"
              :isCollapsed="areAccordionsCollapsed"
              isCollapsable
            >
              <ol
                class="add-edit-action__variable-list"
                v-if="expectedData.length"
              >
                <li
                  v-for="(data, index) in expectedData"
                  :key="`expectedData-${index}`"
                  class="add-edit-action__variable-list-item"
                >
                  <div class="add-edit-action__variable-details">
                    <div class="add-edit-action__variable-name">
                      <Input
                        placeholder="eg. name"
                        label="Data name"
                        data-test-id="add-edit-action__expected-data-name"
                        :id="`expectedData-${index}-expectedVariableName`"
                        :name="`expectedData-${index}-expectedVariableName`"
                        :value="data.expectedVariableName"
                        :error="data.expectedVariableNameErrorMessage"
                        @change="
                          onChangeInput(
                            {
                              key: 'expectedData',
                              index,
                              subKey: 'expectedVariableName'
                            },
                            $event
                          )
                        "
                      />
                    </div>
                    <div class="add-edit-action__variable-type">
                      <SearchSelect
                        label="Data type"
                        placeholder="Select..."
                        data-test-id="add-edit-action__expected-data-type"
                        :name="`expectedData-${index}-expectedVariableType`"
                        :id="`expectedData-${index}-expectedVariableType`"
                        :options="
                          makeOptionsForSelect(
                            data.expectedVariableType,
                            variableTypeOptions
                          )
                        "
                        :error="data.expectedVariableTypeErrorMessage"
                        @change="
                          onChangeInput(
                            {
                              key: 'expectedData',
                              index,
                              subKey: 'expectedVariableType'
                            },
                            $event
                          )
                        "
                      />
                    </div>
                    <div class="add-edit-action__variable-type">
                      <SearchSelect
                        v-if="
                          isExpectedDataSubTypeAvailable(
                            data.expectedVariableType
                          )
                        "
                        label="Sub type"
                        placeholder="Select..."
                        data-test-id="add-edit-action__expected-data-sub-type"
                        :name="`expectedData-${index}-expectedVariableSubType`"
                        :id="`expectedData-${index}-expectedVariableSubType`"
                        :options="
                          makeOptionsForSelect(
                            data.expectedVariableSubType,
                            getVariableSubTypeOptions(data.expectedVariableType)
                          )
                        "
                        :error="data.expectedVariableSubTypeErrorMessage"
                        @change="
                          onSubTypeSelection(
                            {
                              key: 'expectedData',
                              index,
                              subKey: 'expectedVariableSubType'
                            },
                            $event
                          )
                        "
                      />
                    </div>
                    <div
                      class="
                        add-edit-action__variable-type
                        add-edit-action__ddq-legend
                      "
                    >
                      <router-link
                        v-if="hasExpectedDataVariableSubType(data)"
                        :to="getLegendRouteLink(data)"
                        data-test-id="add-edit-action__expected-data-legend-link"
                        target="_blank"
                      >
                        Legend
                      </router-link>
                    </div>
                  </div>
                  <div>
                    <CallToAction
                      class="add-edit-action__variable-delete"
                      icon="trash-alt"
                      :size="20"
                      :theme="themes.GREY"
                      data-test-id="add-edit-action__expected-data-delete"
                      @click="onDeleteExpectedData(index)"
                    />
                  </div>
                </li>
              </ol>
              <BaseText v-else text="There are no set variables." />
              <CallToAction
                type="button"
                value="Add"
                :theme="themes.SECONDARY"
                data-test-id="add-edit-action__variable-add"
                @click="onAddDataDefinition"
              />
            </AccordionSection>
            <div v-if="errorMessagesApi[actionKeys.EXPECTED_DATA_DEFINITION]">
              <InputErrorMessage
                v-for="(errorMessage, index) in errorMessagesApi[
                  actionKeys.EXPECTED_DATA_DEFINITION
                ]"
                :key="`error-message-api-expected-data-${index}`"
                :error="errorMessage"
              />
            </div>
          </div>
          <div
            v-if="detailedExpectedData"
            @mouseenter="fetchParsedExpectedData"
            @click="fetchParsedExpectedData"
          >
            <AccordionSection
              v-for="(step, index) in functionSteps"
              :key="`functionSteps-${index}`"
              class="add-edit-action__form-section"
              :data-test-id="`add-edit-action__form-section--${toCamel(
                step.stepType
              )}`"
              :title="makeAccordionTitle(index, step.stepType)"
              :menuItems="getStepAccordionOptions(step.hasComment)"
              :isCollapsed="areAccordionsCollapsed"
              isCollapsable
              :showCommentBox="step.hasComment"
              :comment="step.comment"
              @click="onAccordionDropDownSelection(index, $event)"
              @input="onStepCommentChange(index, $event)"
            >
              <IfBlockWrapper
                v-if="step.stepType === actionStepType.IF"
                :blocks="step"
                :error="errorMessages.functionSteps[index]"
                :stepIndex="index"
                @change="onIfBlockChange($event, index)"
              />
              <SetStep
                v-else-if="step.stepType === actionStepType.SET"
                v-bind="step"
                @change="onSetStepChange($event, index)"
                @create="onSetStepCreate($event, index)"
                @reset="onSetStepChange($event, index)"
              />
              <BaseText
                v-else-if="step.stepType === actionStepType.END"
                v-bind="step"
              />
              <TriggerStep
                v-else-if="step.stepType === actionStepType.TRIGGER"
                v-bind="step"
                @change="onTriggerStepChange($event, index)"
                @create="onTriggerStepChange($event, index)"
                @reset="onTriggerStepChange($event, index)"
              />
              <EmailNotificationStep
                v-else-if="step.stepType === actionStepSubType.EMAIL"
                v-bind="step"
                @change="
                  onEmailNotificationStepChange({
                    event: $event,
                    stepIndex: index
                  })
                "
                @create="
                  onEmailNotificationStepChange({
                    event: $event,
                    stepIndex: index
                  })
                "
                @reset="
                  onEmailNotificationStepChange({
                    event: $event,
                    stepIndex: index
                  })
                "
              />
            </AccordionSection>
            <InputErrorMessage :error="errorMessages.functionStepsList" />
            <div v-if="errorMessagesApi[actionKeys.FUNCTION_BODY]">
              <InputErrorMessage
                v-for="(errorMessage, index) in errorMessagesApi[
                  actionKeys.FUNCTION_BODY
                ]"
                :key="`error-message-api-function-body-${index}`"
                :error="errorMessage"
              />
            </div>
            <InputErrorMessage
              v-if="errorMessagesApi.server"
              :error="errorMessagesApi.server"
            />
          </div>
        </div>
        <div class="add-edit-action__form-cta-holder">
          <BaseFooter>
            <template #left>
              <CallToAction
                type="button"
                value="Add step"
                data-test-id="add-edit-action__form-cta-holder--add-step"
                @click="addStepToBottomOfFunction"
              />
            </template>
            <template #right>
              <CallToAction
                class="add-edit-action__form-cta"
                type="button"
                theme="secondary"
                value="Close"
                data-test-id="add-edit-action__form-cta-holder--close"
                @click="onCancel"
              />
              <CallToAction
                class="add-edit-action__form-cta"
                type="button"
                value="Submit"
                :is-loading="isSavingAction"
                :is-error="hasActionError"
                :is-success="isSavingSuccessful"
                data-test-id="add-edit-action__form-cta-holder--submit"
                @click="onSubmit"
              />
            </template>
          </BaseFooter>
        </div>
      </form>
    </template>
  </section>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import {
  actionKeys,
  actionKeysApi,
  actionName,
  actionStepOptions,
  actionStepType,
  expectedDataTypes,
  getterName,
  ifBlockSuccessStepType,
  ifSuccessStepOptions,
  operations,
  routeNames,
  stepAccordionOptions,
  stepAccordionOptionValue,
  themes,
  urls,
  variableAssignmentOptionsWithEmptyOption,
  variableAssignmentType,
  variableScope,
  variableType,
  variableTypeOptions,
  variableValueBooleanOptions,
  variableValueTypeOptions,
  emptyOption,
  timers,
  actionStepSubType
} from "@/constants";
import { cloneDeep, debounce, has, isEmpty } from "lodash";
import { makeActionDTO } from "@/services/actions/dto/actionsDTO/actions.dto";
import { makeOptionsForSelect } from "@/molecules/Select/Select.dto";
import { actionsService } from "@/services";
import BaseText from "@/atoms/BaseText/BaseText";
import BaseFooter from "@/atoms/BaseFooter/BaseFooter";
import CallToAction from "@/atoms/CallToAction/CallToAction";
import Input from "@/molecules/Input/Input";
import Textarea from "@/molecules/Textarea/Textarea";
import SearchSelect from "@/molecules/SearchSelect/SearchSelect";
import Select from "@/molecules/Select/Select";
import AccordionSection from "@/molecules/AccordionSection/AccordionSection";
import BaseLoader from "@/atoms/BaseLoader/BaseLoader";
import InputErrorMessage from "@/molecules/InputErrorMessage/InputErrorMessage";
import Modal from "@/molecules/Modal/Modal";
import { hasStatus500, getSelectedOption, toCamel } from "@/utils";
import IfBlockWrapper from "@/organisms/IfBlockWrapper/IfBlockWrapper";
import IfStatementMixin from "@/organisms/AddEditAction/mixins/IfStatement.mixin";
import AddEditActionValidationMixin from "@/organisms/AddEditAction/mixins/AddEditActionValidation/AddEditActionValidation.mixin";
import SetStepMixin from "@/organisms/AddEditAction/mixins/SetStepMixin/SetStep.mixin";
import EndStepMixin from "@/organisms/AddEditAction/mixins/EndStepMixin/EndStep.mixin";
import TriggerStepMixin from "@/organisms/AddEditAction/mixins/TriggerStepMixin/TriggerStep.mixin";
import RoundMixin from "@/organisms/AddEditAction/mixins/RoundMixin/Round.mixin";
import EmailNotificationMixin from "@/organisms/AddEditAction/mixins/EmailNotificationMixin/EmailNotification.mixin";
import SetStep from "@/molecules/SetStep/SetStep";
import EmailNotificationStep from "@/molecules/EmailNotificationStep/EmailNotificationStep";
import TriggerStep from "@/organisms/TriggerStep/TriggerStep";
import {
  makeBracketDTO,
  makeFlatSetStepDTO,
  makeParsedExpectedDataAPIDTO
} from "@/services/actions/dto/setStepDTO/setStep.dto";
import { SetStepClass } from "@/molecules/SetStep/SetStep.class";

export default {
  name: "AddEditAction",
  components: {
    IfBlockWrapper,
    Input,
    Textarea,
    SearchSelect,
    CallToAction,
    BaseText,
    AccordionSection,
    BaseLoader,
    Select,
    BaseFooter,
    InputErrorMessage,
    Modal,
    SetStep,
    TriggerStep,
    EmailNotificationStep
  },
  mixins: [
    IfStatementMixin,
    AddEditActionValidationMixin,
    SetStepMixin,
    EndStepMixin,
    TriggerStepMixin,
    RoundMixin,
    EmailNotificationMixin
  ],
  props: {
    action: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      toCamel,
      actionName: "",
      actionDescription: "",
      nextActionStep: "",
      allowFetchParsedExpectedData: true,
      isLoading: false,
      isNextStepModalVisible: false,
      expectedData: [],
      functionSteps: [],
      detailedExpectedData: [],
      nextStepModalOptions: [],
      proxyExpectedData: {},
      tempAction: {},
      successStepEvent: {},
      errorMessagesApi: this.makeErrorMessagesApi(),
      areAccordionsCollapsed: false,
      accordionTitle: {
        [actionStepType.SET]: "SET step",
        [actionStepType.IF]: "IF step",
        [actionStepType.END]: "END step",
        [actionStepType.TRIGGER]: "TRIGGER step",
        [actionStepSubType.EMAIL]: "EMAIL step"
      },
      isSavingAction: false,
      hasActionError: false,
      isSavingSuccessful: false,
      addBlockIndex: undefined,
      actionKeys,
      variableTypeOptions,
      variableValueTypeOptions,
      variableType,
      variableScope,
      variableAssignmentOptionsWithEmptyOption,
      themes,
      variableAssignmentType,
      stepAccordionOptions,
      variableValueBooleanOptions,
      actionStepType,
      routeNames,
      actionStepSubType,
      showCommentBox: false
    };
  },
  computed: {
    ...mapState({
      companyId: (state) => state.company.companyId
    }),
    ...mapGetters({
      getSystemActions: getterName.ACTIONS.GET_SYSTEM_ACTIONS,
      getDDQTasks: getterName.ACTIONS.GET_DDQ_TASKS
    }),
    isNewAction() {
      return this.$route.name === routeNames.ADD_ACTION;
    },
    getTitle() {
      return this.isNewAction ? "Add Action" : "Action Configuration";
    },
    expectedDataBaseLists() {
      return this.makeExpectedDataBaseLists(this.detailedExpectedData);
    },
    proxyExpectedDataBaseLists() {
      const proxySetStepIds = Object.keys(this.proxyExpectedData);
      return Object.values(this.proxyExpectedData).reduce(
        (acc, data, index) => {
          acc[proxySetStepIds[index]] = this.makeExpectedDataBaseLists([data]);
          return acc;
        },
        {}
      );
    },
    expectedDataSettableListOfOptions() {
      return this.functionSteps.map((_, stepIndex) => {
        const localVariablesList = this.getLocalVariableUniqueList(
          stepIndex
        ).reduce((acc, setStep) => {
          if (
            this.isProxyValueDataType(setStep.valueDataType) &&
            this.proxyExpectedDataBaseLists?.[setStep.id]
          ) {
            acc.push(
              ...this.proxyExpectedDataBaseLists[setStep.id].settableOptions
            );
          } else if (setStep.variableNameSearchValue) {
            acc.push({
              text: setStep.variableNameSearchValue,
              value: setStep.variableNameSearchValue
            });
          }
          return acc;
        }, []);
        return [
          ...this.expectedDataBaseLists.settableOptions,
          ...localVariablesList
        ];
      });
    },
    expectedDataListOfAllOptions() {
      return this.functionSteps.map((_, stepIndex) => {
        const localVariablesList = this.getLocalVariableUniqueList(
          stepIndex
        ).reduce((acc, setStep) => {
          if (
            this.isProxyValueDataType(setStep.valueDataType) &&
            this.proxyExpectedDataBaseLists?.[setStep.id]
          ) {
            acc.push(...this.proxyExpectedDataBaseLists[setStep.id].allOptions);
          } else if (setStep.variableNameSearchValue) {
            acc.push({
              text: setStep.variableNameSearchValue,
              value: setStep.variableNameSearchValue,
              type: setStep.variableType,
              subType: setStep.valueDataType,
              isArray: this.isMultipleSelectionExpectedForEntityPropertyName(
                setStep.variableNameSearchValue
              )
            });
          }
          return acc;
        }, []);
        return [
          ...this.expectedDataBaseLists.allOptions,
          ...localVariablesList
        ];
      });
    },
    getAllEntities() {
      const proxyExpectedDataBaseListRaw = Object.values(
        this.proxyExpectedDataBaseLists
      ).reduce((acc, { raw }) => {
        acc = {
          ...acc,
          ...raw
        };
        return acc;
      }, {});

      return {
        ...this.expectedDataBaseLists.raw,
        ...proxyExpectedDataBaseListRaw
      };
    }
  },
  watch: {
    expectedData: {
      handler() {
        this.allowFetchParsedExpectedData = true;
      },
      deep: true
    },
    expectedDataListOfAllOptions: {
      deep: true,
      handler(oldValue, newValue) {
        if (JSON.stringify(oldValue) !== JSON.stringify(newValue)) {
          debounce(() => {
            this.updateExistingAllSetToBlock();
            this.updateAllIfStatementLeftOptions();
            this.updateSetSteps();
            this.updateAllSuccessTriggerStepsOptions();
            this.updateEmailNotificationStepsOptions();
          }, 500)();
        }
      }
    },
    hasActionError(newValue) {
      if (newValue) {
        setTimeout(() => {
          this.hasActionError = false;
        }, timers.MODERATE);
      }
    }
  },
  async created() {
    this.makeTempAction();
    this.assignExpectedData();
    await this.fetchParsedExpectedData();
    this.assignFunctionSteps();
    this.makeFunctionStepsErrorList();
  },
  methods: {
    makeOptionsForSelect,
    ...mapActions({
      saveAction: actionName.ACTIONS.SAVE_ACTION,
      amendAction: actionName.ACTIONS.AMEND_ACTION
    }),
    isExpectedDataSubTypeAvailable(type) {
      return type === expectedDataTypes.DDQ_TASK;
    },
    isExpectedDataSubTypeSelected(subType = "") {
      return subType !== "";
    },
    makeExpectedDataBaseLists(expectedData = []) {
      return expectedData?.reduce(
        (acc, { attributes }) => {
          attributes?.forEach((attribute) => {
            const { data } = attribute;
            if (!data.data.isReadOnly) {
              acc.settableOptions.push({
                text: data.data.name,
                value: data.data.name
              });
            }
            acc.allOptions.push({
              text: data.data.name,
              value: data.data.name,
              type: data.type,
              subType: data.data.subType || data.type,
              isArray: data.data.isArray || false
            });
            acc.raw[data.data.name] = attribute;
          });
          return acc;
        },
        {
          settableOptions: [],
          allOptions: [],
          raw: {}
        }
      );
    },
    makeDDQFormOptions() {
      return this.getDDQTasks.map(({ name, context } = {}) => ({
        text: name,
        value: context?.formId
      }));
    },
    getVariableSubTypeOptions(type) {
      if (type === expectedDataTypes.DDQ_TASK) {
        return this.makeDDQFormOptions();
      }
    },
    getAllAvailableOptionsByStepIndex(stepIndex) {
      const highestIndexOfAllAvailableOptions =
        this.expectedDataListOfAllOptions.length - 1;

      if (highestIndexOfAllAvailableOptions >= stepIndex) {
        return this.expectedDataListOfAllOptions[stepIndex] || [];
      }

      return (
        this.expectedDataListOfAllOptions[highestIndexOfAllAvailableOptions] ||
        []
      );
    },
    makeAccordionTitle(index = this.functionSteps.length, stepType) {
      const accordionTitle =
        this.accordionTitle[stepType] || "Unknown step type";
      return `${index + 1}. ${accordionTitle}`;
    },
    showModal() {
      this.nextStepModalOptions = this.makeOptionsForSelect(
        "",
        actionStepOptions
      );
      this.toggleNextStepModal();
    },
    showSuccessStepModal(event, index) {
      this.nextStepModalOptions = makeOptionsForSelect(
        "",
        ifSuccessStepOptions
      );
      this.successStepEvent = {
        event,
        index
      };
      this.toggleNextStepModal();
    },
    onAddBracket({ index }, calculationList) {
      calculationList.splice(index, 0, makeBracketDTO());
    },
    onDeleteBracket({ index }, calculationList) {
      calculationList.splice(index, 1);
    },
    amendBracketSection(event, calculationList) {
      if (event.eventType === operations.ADD_BRACKET) {
        this.onAddBracket(event, calculationList);
      } else if (event.eventType === operations.DELETE_BRACKET) {
        this.onDeleteBracket(event, calculationList);
      } else {
        this.updateCalculationBracket(event, calculationList);
      }
    },
    getLocalVariableUniqueList(stepIndex) {
      return this.functionSteps.reduce((acc, functionStep, position) => {
        if (functionStep instanceof SetStepClass) {
          const isItemNotIncluded = !acc.find(
            ({ variableNameSearchValue }) =>
              variableNameSearchValue === functionStep.variableNameSearchValue
          );
          const isDefinedAbove = position < stepIndex;
          const isLocalVariable =
            functionStep?.variableScope === variableScope.LOCAL;
          if (
            !this.isExpectedDataBaseOption(functionStep) &&
            this.isSetStepCompleted(functionStep) &&
            isLocalVariable &&
            isDefinedAbove &&
            isItemNotIncluded
          ) {
            acc.push(functionStep);
          }
        }
        return acc;
      }, []);
    },
    updateAllStepIndexes(editType, index) {
      this.updateIfStatementStepIndexes(editType, index);
      this.updateSetStepsIndexes(editType, index);
      this.updateEndStepsIndexes(editType, index);
      this.updateTriggerStepsIndexes(editType, index);
      this.updateEmailNotificationStepsIndexes(editType, index);
      this.updateEmailNotificationExpectedDataIndexes(editType, index);
    },
    onAccordionDropDownSelection(index, selection) {
      if (selection === stepAccordionOptionValue.ADD_STEP_ABOVE) {
        this.addBlockIndex = index;
        this.showModal();
      } else if (selection === stepAccordionOptionValue.ADD_STEP_BELOW) {
        this.addBlockIndex = index + 1;
        this.showModal();
      } else if (selection === stepAccordionOptionValue.DELETE_STEP) {
        this.removeBlock(index);
      } else if (selection === stepAccordionOptionValue.ADD_COMMENT) {
        this.setHasComment(index, true);
      } else if (selection === stepAccordionOptionValue.REMOVE_COMMENT) {
        this.setHasComment(index, false);
      }
    },
    removeBlock(index) {
      this.removeBelowValuesAssociatedToStep(index);
      const deletedStep = this.functionSteps.splice(index, 1);

      if (deletedStep?.[0]?.stepType === actionStepType.IF) {
        this.deleteIfStepStatements(index);
      } else if (deletedStep?.[0]?.stepType === actionStepType.SET) {
        this.deleteSetStepIndexFromList(index);
      } else if (deletedStep?.[0]?.stepType === actionStepType.END) {
        this.deleteEndStepIndexFromList(index);
      } else if (deletedStep?.[0]?.stepType === actionStepType.TRIGGER) {
        this.deleteTriggerStepIndexFromList(index);
      } else if (deletedStep?.[0]?.stepType === actionStepSubType.EMAIL) {
        this.deleteEmailNotificationStepIndexFromList(index);
      }

      this.updateAllStepIndexes(operations.DELETE, index);
    },
    deleteSuccessStep({ id, stepType, property, index }, stepIndex) {
      if (stepType === actionStepType.SET) {
        this.deleteSetStepIdFromList(id);
      } else if (stepType === actionStepType.END) {
        this.deleteEndStepIdFromList(id);
      } else if (stepType === actionStepType.TRIGGER) {
        this.deleteTriggerStepIdFromList(id);
      } else if (stepType === actionStepSubType.EMAIL) {
        this.deleteEmailNotificationStepIdFromList(id);
      }

      if (property === actionKeys.ELSE_BLOCK) {
        this.functionSteps[stepIndex][property] = this.functionSteps[stepIndex][
          property
        ].filter(({ componentOptions }) => componentOptions.id !== id);
      } else {
        this.functionSteps[stepIndex][property][index].successSteps =
          this.functionSteps[stepIndex][property][index].successSteps.filter(
            ({ componentOptions }) => componentOptions.id !== id
          );
      }
    },
    amendSuccessStep(event, stepIndex) {
      if (event.stepType === actionStepType.SET) {
        this.amendSuccessSetStep(event, stepIndex);
      } else if (event.stepType === actionStepType.TRIGGER) {
        this.amendSuccessTriggerStep(event, stepIndex);
      } else if (event.stepType === actionStepSubType.EMAIL) {
        this.amendSuccessEmailNotificationStep({ event, stepIndex });
      }
    },
    isExpectedDataBaseOption({ variableNameSearchValue }) {
      return !!this.expectedDataBaseLists.allOptions.find(
        ({ text }) => text === variableNameSearchValue
      );
    },
    toggleNextStepModal() {
      this.resetNextStepErrorMessage();
      this.isNextStepModalVisible = !this.isNextStepModalVisible;
    },
    onCancelStep() {
      this.nextActionStep = undefined;
      this.resetNextStepErrorMessage();
      this.toggleNextStepModal();
    },
    makeSuccessSetStepForIfBlock() {
      const { index: stepIndex, event } = this.successStepEvent;
      const successSetStep = this.makeSuccessSetStep(undefined, stepIndex);

      if (event.property === actionKeys.ELSE_BLOCK) {
        const elseBlocks = [
          ...(this.functionSteps[stepIndex]?.[event.property] || []),
          successSetStep
        ];

        this.$set(this.functionSteps[stepIndex], event.property, elseBlocks);
      } else {
        const successSteps = [
          ...(this.functionSteps[stepIndex]?.[event.property]?.[event.index]
            ?.successSteps || []),
          successSetStep
        ];

        this.$set(
          this.functionSteps[stepIndex][event.property][event.index],
          actionKeys.SUCCESS_STEPS,
          successSteps
        );
      }
    },
    addStepToBottomOfFunction() {
      this.addBlockIndex = this.functionSteps.length;
      this.showModal();
    },
    makeFunctionSetStepErrorObj(index) {
      return {
        [actionKeys.VARIABLE_NAME]: undefined,
        [actionKeys.VALUE_DATA_TYPE]: undefined,
        [actionKeys.VALUE]:
          this.functionSteps[index]?.[actionKeys.VALUE_DATA_TYPE] ===
          variableType.EXPRESSION
            ? this.makeCalculationValueObj(
                this.functionSteps[index][actionKeys.VALUE]
              )
            : undefined,
        [actionKeys.VALUE_TYPE]: undefined
      };
    },
    makeFunctionStepsErrorList() {
      this.errorMessages.functionSteps = this.functionSteps.map((_, index) =>
        this.makeFunctionSetStepErrorObj(index)
      );
    },
    async onAddStep() {
      const isInvalid = await this.isNextActionStepInvalid();

      if (!isInvalid) {
        if (this.nextActionStep === actionStepType.SET) {
          this.makeNewSetStep(this.addBlockIndex);
        } else if (this.nextActionStep === actionStepType.IF) {
          this.makeNewIfStep(this.addBlockIndex);
        } else if (this.nextActionStep === actionStepType.END) {
          this.makeNewEndStep(this.addBlockIndex);
        } else if (this.nextActionStep === actionStepType.TRIGGER) {
          this.makeNewTriggerStep(this.addBlockIndex);
        } else if (this.nextActionStep === actionStepSubType.EMAIL) {
          this.makeNewEmailNotificationStep(this.addBlockIndex);
        } else if (this.nextActionStep === ifBlockSuccessStepType.SET) {
          this.makeSuccessSetStepForIfBlock();
        } else if (this.nextActionStep === ifBlockSuccessStepType.TRIGGER) {
          this.makeSuccessTriggerStepForIfBlock();
        } else if (this.nextActionStep === ifBlockSuccessStepType.END) {
          this.makeSuccessEndStepForIfBlock();
        } else if (this.nextActionStep === ifBlockSuccessStepType.EMAIL) {
          this.makeSuccessEmailNotificationStepForIfBlock();
        }

        this.toggleNextStepModal();
        this.nextActionStep = undefined;
      }
    },
    makeExpectedDataObj(dataObj = {}) {
      return {
        expectedVariableName: dataObj[actionKeys.NAME] || "",
        expectedVariableType: dataObj[actionKeys.ENTITY] || "",
        expectedVariableSubType:
          dataObj?.[actionKeys.CONTEXT]?.[actionKeys.FORM_ID] || "",
        expectedVariableNameErrorMessage: "",
        expectedVariableTypeErrorMessage: "",
        expectedVariableSubTypeErrorMessage: ""
      };
    },
    makeFirstOptionForSelect(isEmptyOptionRequired = true) {
      return isEmptyOptionRequired
        ? [{ ...emptyOption, text: "Select..." }]
        : [];
    },
    makeSetStepFlatObj(dataObj = {}) {
      return {
        [actionKeys.STEP_TYPE]:
          dataObj[actionKeys.STEP_TYPE] || actionStepType.SET,
        [actionKeys.COMMENT]: dataObj[actionKeys.COMMENT],
        [actionKeys.VARIABLE_SCOPE]:
          dataObj[actionKeys.VARIABLE_SCOPE] || variableScope.LOCAL,
        [actionKeys.VARIABLE_NAME]: dataObj[actionKeys.VARIABLE_NAME],
        [actionKeys.VARIABLE_TYPE]: dataObj[actionKeys.VARIABLE_TYPE],
        [actionKeys.VALUE]: dataObj[actionKeys.VALUE],
        [actionKeys.VALUE_DATA_TYPE]: dataObj[actionKeys.VALUE_DATA_TYPE],
        [actionKeys.VALUE_TYPE]:
          dataObj[actionKeys.VALUE_TYPE] || variableAssignmentType.LITERAL,
        [actionKeys.DISPLAY_VALUE_TYPE]: dataObj[actionKeys.DISPLAY_VALUE_TYPE]
      };
    },
    makeCalculationValueObj(calculationList = []) {
      return calculationList?.reduce((acc, { bracket }) => {
        acc.push({
          operator: undefined,
          bracket: bracket.map(() => ({
            operator: undefined,
            value: undefined
          }))
        });
        return acc;
      }, []);
    },
    onCancel() {
      this.$router.push(urls.ACTIONS);
    },
    onAddDataDefinition() {
      this.expectedData.push(this.makeExpectedDataObj());
    },
    onDeleteExpectedData(index) {
      this.expectedData = this.expectedData.filter(
        (_, position) => index !== position
      );
    },
    onChangeInput(inputName, value) {
      if (typeof inputName === "string") {
        this[inputName] = value.trim();
      } else {
        const { key, index, subKey } = inputName;
        this[key][index][subKey] =
          typeof value === "string" ? value.trim() : value;
      }
    },
    makeSaveActionObject() {
      return {
        [actionKeys.NAME]: this.actionName,
        [actionKeys.DESCRIPTION]: this.actionDescription,
        [actionKeys.COMPANY_ID]: this.companyId,
        [actionKeys.VARIABLES]: this.expectedData.map(this.makeApiExpectedData),
        [actionKeys.FUNCTION_BODY]: this.functionSteps.map((functionStep) => {
          if (functionStep instanceof SetStepClass) {
            return makeFlatSetStepDTO(functionStep);
          } else {
            return functionStep;
          }
        }),
        [actionKeys.IS_ACTIVE]: true
      };
    },
    async onSubTypeSelection({ key, index, subKey }, value) {
      this[key][index][subKey] =
        typeof value === "string" ? value.trim() : value;
      if (subKey === "expectedVariableType") {
        this[key][index].expectedVariableSubType = "";
      }
    },
    async onSubmit() {
      this.isSavingAction = true;
      this.hasActionError = false;
      const isInvalid = await this.validateAction();
      try {
        if (!isInvalid) {
          await this.submitAction();
        }
      } catch (error) {
        this.hasActionError = true;
        this.errorMessagesApi = this.makeErrorMessagesApi(error);
      } finally {
        this.isSavingAction = false;
      }
    },
    async submitAction() {
      const payload = {
        action: this.makeSaveActionObject(),
        entities: Object.values(this.getAllEntities).map(
          makeParsedExpectedDataAPIDTO
        )
      };
      if (this.tempAction[actionKeys.ID]) {
        await this.amendAction({
          id: this.tempAction[actionKeys.ID],
          ...payload
        });
        this.submitActionSuccess("updated");
      } else {
        await this.saveAction(payload);
        this.submitActionSuccess("created");
      }
    },
    submitActionSuccess(operation) {
      this.isSavingSuccessful = true;

      setTimeout(() => {
        this.$router.push({
          name: routeNames.ACTIONS,
          params: { message: `Action ${operation} successfully` }
        });
      }, timers.MODERATE);
    },
    assignExpectedData() {
      const isListAvailable = has(this.tempAction, [
        actionKeys.EXPECTED_DATA_DEFINITION,
        actionKeys.VARIABLES
      ]);
      this.expectedData = isListAvailable
        ? this.tempAction[actionKeys.EXPECTED_DATA_DEFINITION][
            actionKeys.VARIABLES
          ]?.map(this.makeExpectedDataObj)
        : [];
      this.actionName = this.tempAction[actionKeys.NAME] || "";
      this.actionDescription = this.tempAction[actionKeys.DESCRIPTION] || "";
    },
    assignFunctionSteps() {
      this.functionSteps =
        this.tempAction?.[actionKeys.FUNCTION_BODY]?.map(
          (functionData, index) => {
            if (functionData?.stepType === actionStepType.IF) {
              return this.makeIfStep(functionData, index);
            } else if (functionData?.stepType === actionStepType.SET) {
              return this.makeSetStepAndExtendSetStepList(functionData, index);
            } else if (functionData?.stepType === actionStepType.END) {
              return this.makeEndStepAndExtendEndStepList(index);
            } else if (functionData?.stepType === actionStepType.TRIGGER) {
              return this.makeTriggerStepAndExtendTriggerStepList(
                functionData,
                index
              );
            } else if (functionData?.stepType === actionStepSubType.EMAIL) {
              return this.makeEmailNotificationStepAndExtendStepList({
                functionData,
                stepIndex: index
              });
            }
          }
        ) || [];
    },
    makeTempAction() {
      if (isEmpty(this.action)) {
        this.tempAction = makeActionDTO();
      } else {
        this.tempAction = cloneDeep(this.action);
      }
    },
    makeApiExpectedData({
      expectedVariableName,
      expectedVariableType,
      expectedVariableSubType
    }) {
      return {
        [actionKeysApi.ENTITY]: expectedVariableType,
        [actionKeysApi.NAME]: expectedVariableName,
        ...(!!expectedVariableSubType && {
          [actionKeysApi.CONTEXT]: {
            [actionKeysApi.FORM_ID]: expectedVariableSubType
          }
        })
      };
    },
    makeListOfStrings(value) {
      if (value) {
        return Array.isArray(value) ? value : [value];
      } else {
        return value;
      }
    },
    makeErrorMessagesApi({ response } = {}) {
      const { errors = {}, message = "" } = response?.data || {};
      return {
        [actionKeys.NAME]: this.makeListOfStrings(errors[actionKeysApi.NAME]),
        [actionKeys.DESCRIPTION]: this.makeListOfStrings(
          errors[actionKeysApi.DESCRIPTION]
        ),
        [actionKeys.EXPECTED_DATA_DEFINITION]: this.makeListOfStrings(
          errors[actionKeysApi.EXPECTED_DATA_DEFINITION]
        ),
        [actionKeys.FUNCTION_BODY]: this.makeListOfStrings(
          errors[actionKeysApi.FUNCTION_BODY]
        ),
        server: hasStatus500(response) ? message : undefined
      };
    },
    async fetchParsedExpectedData() {
      const isExpectedDataValid = !this.validateExpectedData();
      if (isExpectedDataValid && this.allowFetchParsedExpectedData) {
        try {
          this.errorMessagesApi = this.makeErrorMessagesApi();
          this.detailedExpectedData = [];
          this.isLoading = true;
          const { data = {} } = await actionsService.fetchParsedExpectedData(
            this.companyId,
            this.expectedData.map(this.makeApiExpectedData)
          );
          this.detailedExpectedData = data;
        } catch (error) {
          this.detailedExpectedData = [];
          this.errorMessagesApi = this.makeErrorMessagesApi(error);
        } finally {
          this.allowFetchParsedExpectedData = false;
          this.isLoading = false;
        }
      }
    },
    makeFetchProxyExpectedDataPayload(setStep) {
      const entityKey =
        getSelectedOption(setStep?.componentOptions?.componentOptions?.options)
          ?.text || "";
      const payload = this.getAllEntities[entityKey] || {};
      return {
        ...(makeParsedExpectedDataAPIDTO(payload)?.data?.data || {}),
        name: setStep?.variableNameSearchValue
      };
    },
    async fetchProxyExpectedData(setStep = {}) {
      try {
        this.isLoading = true;
        this.$set(this.proxyExpectedData, setStep.id, {});
        this.errorMessagesApi = this.makeErrorMessagesApi();
        const { data = {} } = await actionsService.fetchProxyExpectedData(
          this.companyId,
          this.makeFetchProxyExpectedDataPayload(setStep)
        );
        this.$set(this.proxyExpectedData, setStep.id, data);
      } catch (error) {
        this.errorMessagesApi = this.makeErrorMessagesApi(error);
      } finally {
        this.isLoading = false;
      }
    },
    setAccordionCollapse(value) {
      this.areAccordionsCollapsed = value;
    },
    getStepAccordionOptions(hasComment) {
      const clonedOptions = cloneDeep(stepAccordionOptions);

      if (hasComment) {
        clonedOptions.shift();
      }

      return clonedOptions;
    },
    setHasComment(index, value) {
      this.$set(this.functionSteps[index], "hasComment", value);

      if (!value) {
        this.$set(this.functionSteps[index], "comment", "");
      }
    },
    onStepCommentChange(index, { target = {} } = {}) {
      this.$set(this.functionSteps[index], "comment", target.value);
    },
    hasExpectedDataVariableSubType(data = {}) {
      return !!data.expectedVariableSubType;
    },
    getLegendRouteLink(data = {}) {
      return {
        name: routeNames.QUESTIONS_REFERENCE,
        params: {
          id: data.expectedVariableSubType || ""
        }
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.add-edit-action {
  text-align: left;

  &__variable-name,
  &__variable-type {
    display: inline-block;
    max-width: 250px;
    width: 100%;
    margin: 0;
    vertical-align: top;
  }

  &__variable-name {
    margin-right: 25px;
  }

  &__variable-type {
    max-width: none;
    width: auto;
    margin-right: 25px;
  }

  &__ddq-legend {
    display: flex;
    align-items: center;
  }

  &__variable-delete {
    margin-top: 32px;
    vertical-align: top;
  }

  &__variable-list {
    padding: 0;
    margin: 0;

    &-item {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }
  }

  &__variable-details {
    display: inline-flex;
    max-width: 900px;
    width: 100%;

    &:deep(.select__items-container) {
      width: 100%;
    }
    &:deep(.add-edit-action__variable-type) {
      width: 100%;
    }
  }

  &__accordion-actions {
    margin-bottom: 30px;
    display: flex;
    justify-content: flex-end;

    &--expand {
      margin-right: 15px;
    }
  }

  &__form {
    text-align: left;

    &-set-to-block {
      width: 50%;
    }

    &:deep(.add-edit-action__form-set-from-block) {
      display: flex;
      width: 50%;
    }

    &-holder {
      height: calc(100vh - 245px);
      overflow-y: scroll;
      padding-right: 20px;
    }

    &:deep(.add-edit-action__form-cta) {
      margin-left: 20px;
    }

    &-step {
      display: flex;
    }

    &-section {
      margin-bottom: 30px;

      &-title {
        @include heading-five;
      }

      &:deep(.add-edit-action__form-section-separator) {
        padding: 0 10px;
      }

      &--action-details {
        max-width: 50%;
      }
    }

    &-cta-holder {
      text-align: right;

      :deep(.base-footer) {
        padding: 15px 0;
      }
    }
  }

  &:deep(.add-edit-action__modal-select) {
    flex-direction: column;
  }

  &:deep(.label__label) {
    display: flex;
  }
}
</style>
