<template>
  <div
    class="entity-due-diligence-template"
    data-test-id="entity-due-diligence-template"
  >
    <div class="entity-due-diligence-template__container">
      <Menu
        class="entity-due-diligence-template__menu"
        v-bind="dueDiligenceMenuOptions"
      />
      <section class="entity-due-diligence-template__content">
        <router-view
          :due-diligence-report-page-heading="dueDiligenceReportPageHeading"
          :due-diligence-report-data="dueDiligenceReportData"
          :due-diligence-report-data-is-loading="
            dueDiligenceReportDataIsLoading
          "
          :due-diligence-report-data-error-message="
            dueDiligenceReportDataErrorMessage
          "
        />
      </section>
    </div>
  </div>
</template>

<script>
import Menu from "@/molecules/Menu/Menu";

export default {
  name: "EntityDueDiligenceTemplate",
  components: {
    Menu
  },
  props: {
    dueDiligenceMenuOptions: {
      type: Object,
      default: () => ({})
    },
    dueDiligenceReportDataIsLoading: {
      type: Boolean,
      default: false
    },
    dueDiligenceReportData: {
      type: Array,
      default: () => []
    },
    dueDiligenceReportDataErrorMessage: {
      type: String,
      default: ""
    },
    dueDiligenceReportPageHeading: {
      type: String,
      default: ""
    }
  }
};
</script>

<style lang="scss" scoped>
$menu-width: 175px;
.entity-due-diligence-template {
  position: relative;
  height: calc(100vh - 240px);
  overflow-y: auto;

  &__menu {
    width: $menu-width;
    position: fixed;
    height: 100%;
  }

  &__content {
    margin-left: $menu-width;
  }
}
</style>
