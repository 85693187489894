
class Helper {
  makeToast(show, variant = null, message, title, noAutoHide = false) {
    show.toast(message, {
      title: title || 'Alert',
      variant,
      solid: true,
      autoHideDelay: 500,
      noAutoHide
    });
  }
}
export default new Helper();
