import {
  actionStepSubType,
  operations,
  actionKeys,
  getterName,
  variableType,
  variableTypeToDisplayTextMap,
  variableAssignmentType,
  variableAssignmentOptions,
  emailExpectedDataTypeOptions,
  userEmailTemplatesDestinationTypes
} from "@/constants";
import EmailNotificationStep from "@/molecules/EmailNotificationStep/EmailNotificationStep";
import { mapGetters, mapState } from "vuex";
import { EmailNotificationClass } from "@/molecules/EmailNotificationStep/EmailNotificationStep.class";
import { makeOptionsForSelect } from "@/molecules/Select/Select.dto";
import { getSelectedValuesFromDestinationKey } from "@/organisms/AddEditUserEmailTemplate/AddEditUserEmailTemplate.logic";
import {
  makeOptionsForMultiSelect,
  makeLiteralOptionForMultiSelect
} from "@/molecules/MultiSelect/MultiSelect.dto";
import { EmailNotificationExpectedDataClass } from "@/molecules/EmailNotificationStep/EmailNotificationExpectedData.class";
import Input from "@/molecules/Input/Input";
import Select from "@/molecules/Select/Select";
import { hasCorrectEmailFormat } from "@/utils";

export default {
  data() {
    return {
      emailNotificationStepList: [],
      emailNotificationExpectedDataList: []
    };
  },
  components: {
    EmailNotificationStep
  },
  computed: {
    ...mapState({
      userEmailTemplates: (state) => state.userEmailTemplates.userEmailTemplates
    }),
    ...mapGetters({
      getDestinationBaseOptions:
        getterName.USER_EMAIL_TEMPLATES.GET_DESTINATION_BASE_OPTIONS,
      getUserEmailTemplatesOptions:
        getterName.USER_EMAIL_TEMPLATES.GET_USER_EMAIL_TEMPLATES_OPTIONS,
      getUserEmailTemplateBasedById:
        getterName.USER_EMAIL_TEMPLATES.GET_USER_EMAIL_TEMPLATE_BASED_BY_ID
    })
  },
  methods: {
    getSelectedValuesFromDestinationKey,
    deleteEmailNotificationStepIndexFromList(index) {
      this.emailNotificationStepList = this.emailNotificationStepList.filter(
        ({ stepIndex }) => stepIndex !== index
      );
    },
    deleteEmailNotificationStepIdFromList(id) {
      this.emailNotificationStepList = this.emailNotificationStepList.filter(
        ({ emailNotificationStep }) => emailNotificationStep.id !== id
      );
    },
    makeEmailNotificationExpectedData({
      emailNotificationExpectedData = [],
      stepIndex
    }) {
      return emailNotificationExpectedData.map((expectedData) => {
        const sharedComponentOptions =
          this.getSharedComponentOptions(stepIndex);
        const isLiteral = this.isLiteralValueType(
          expectedData?.triggeringData?.type
        );
        const valueDataType = isLiteral
          ? expectedData?.triggeringData?.data?.type
          : expectedData?.triggeringData?.data?.data?.type;
        const valueType = isLiteral
          ? variableAssignmentType.LITERAL
          : variableAssignmentType.ENTITY;
        const valueTypeOptions = makeOptionsForSelect(
          valueType,
          variableAssignmentOptions
        );
        const valueDataTypeOptions = makeOptionsForSelect(
          valueDataType,
          emailExpectedDataTypeOptions
        );
        const componentValue = isLiteral
          ? expectedData?.triggeringData?.data?.data?.value
          : expectedData?.triggeringData?.data?.data?.data?.name;
        const { component, componentOptions } = isLiteral
          ? this.makeEmailNotificationExpectedDataForLiteral({
              componentValue,
              componentOptions: sharedComponentOptions
            })
          : this.makeEmailNotificationExpectedDataForEntity({
              valueDataType,
              componentValue,
              componentOptions: sharedComponentOptions,
              stepIndex
            });

        const emailNotificationExpectedDataInstance =
          this.makeEmailNotificationExpectedDataAndExpendList({
            options: {
              emailNotificationExpectedDataName:
                expectedData?.triggeredExpectedDataVariableName,
              valueDataType,
              valueDataTypeOptions,
              valueDataTypeText: variableTypeToDisplayTextMap[valueDataType],
              valueTypeOptions,
              component,
              componentOptions,
              componentValue
            },
            stepIndex
          });
        return emailNotificationExpectedDataInstance;
      });
    },
    makeEmailNotificationExpectedDataAndExpendList({ options, stepIndex }) {
      const emailNotificationExpectedDataInstance =
        new EmailNotificationExpectedDataClass(options);

      this.emailNotificationExpectedDataList.push({
        stepIndex,
        emailNotificationExpectedData: emailNotificationExpectedDataInstance
      });
      return emailNotificationExpectedDataInstance;
    },
    makeEmailNotificationExpectedDataForLiteral({
      componentValue,
      componentOptions
    }) {
      return {
        component: Input,
        componentOptions: {
          ...componentOptions,
          value: componentValue
        }
      };
    },
    makeEmailNotificationExpectedDataForEntity({
      valueDataType,
      componentValue,
      componentOptions,
      stepIndex
    }) {
      const options = this.getListBasedOnType({
        stepIndex,
        selectedLeftValueType: valueDataType,
        selectedRightValue: componentValue,
        isArray: false,
        isEmptyOptionRequired: true
      });
      return {
        component: Select,
        componentOptions: {
          ...componentOptions,
          options: makeOptionsForSelect(componentValue, options)
        }
      };
    },
    makeEmptyEmailNotificationStep() {
      return new EmailNotificationClass({
        comment: "",
        hasComment: false,
        emailNotificationOptions: this.getUserEmailTemplatesOptions
      });
    },
    makeEmailNotificationStep({ functionData = {}, stepIndex }) {
      const emailNotificationExpectedData =
        this.makeEmailNotificationExpectedData({
          emailNotificationExpectedData:
            functionData?.specification?.expectedDataMapping,
          stepIndex
        });

      const emailNotificationOptions = makeOptionsForSelect(
        functionData?.specification?.templateId,
        this.getUserEmailTemplatesOptions
      );
      const emailNotificationSearchValue =
        emailNotificationOptions.find(({ selected }) => selected)?.text || "";

      const toEmailDestinations = this.makeEmailDestinationData({
        emailList: functionData?.specification?.[actionKeys.TO],
        key: [actionKeys.TO],
        id: functionData?.specification?.templateId,
        stepIndex
      });
      const ccEmailDestinations = this.makeEmailDestinationData({
        emailList: functionData?.specification?.[actionKeys.CC],
        key: [actionKeys.CC],
        id: functionData?.specification?.templateId,
        stepIndex
      });
      const bccEmailDestinations = this.makeEmailDestinationData({
        emailList: functionData?.specification?.[actionKeys.BCC],
        key: [actionKeys.BCC],
        id: functionData?.specification?.templateId,
        stepIndex
      });

      return new EmailNotificationClass({
        emailNotificationExpectedData,
        comment: functionData?.comment,
        hasComment: !!functionData.comment,
        emailNotificationOptions,
        emailNotificationSearchValue,
        emailNotificationToDestinationOptions: toEmailDestinations.options,
        emailNotificationToAmendable: toEmailDestinations.amendableValues,
        emailNotificationToSelectedValues: toEmailDestinations.selectedValues,
        userEmailTemplateToValues:
          toEmailDestinations.userEmailTemplateSelectedValues,
        emailNotificationCcDestinationOptions: ccEmailDestinations.options,
        emailNotificationCcAmendable: ccEmailDestinations.amendableValues,
        emailNotificationCcSelectedValues: ccEmailDestinations.selectedValues,
        userEmailTemplateCcValues:
          ccEmailDestinations.userEmailTemplateSelectedValues,
        emailNotificationBccDestinationOptions: bccEmailDestinations.options,
        emailNotificationBccAmendable: bccEmailDestinations.amendableValues,
        emailNotificationBccSelectedValues: bccEmailDestinations.selectedValues,
        userEmailTemplateBccValues:
          bccEmailDestinations.userEmailTemplateSelectedValues
      });
    },
    getUniqueList({ listToIterate = [], listToCompare = [] } = {}) {
      return (
        listToIterate?.reduce((acc, value) => {
          if (!listToCompare.includes(value)) {
            acc.push(value);
          }
          return acc;
        }, []) || []
      );
    },
    updateEmailNotificationStepsOptions() {
      this.updateEmailNotificationStepList();
      this.updateEmailNotificationExpectedDataList();
    },
    getOptionsFromAction(stepIndex) {
      return (
        this.expectedDataListOfAllOptions?.[stepIndex]?.reduce(
          (acc, option) => {
            if (option.type === variableType.STRING && !option.isArray) {
              acc.push({
                ...option,
                isVariable: true,
                isEntity: !!this.getAllEntities[option.text]
              });
            }
            return acc;
          },
          []
        ) || []
      );
    },
    updateEmailNotificationStepList() {
      this.emailNotificationStepList.forEach(
        ({ emailNotificationStep, stepIndex }) => {
          const extraOptions = this.getOptionsFromAction(stepIndex);
          emailNotificationStep.setEmailNotificationToDestinationOptions(
            makeOptionsForMultiSelect(
              emailNotificationStep.emailNotificationToSelectedValues,
              emailNotificationStep.emailNotificationToAmendable,
              [
                ...emailNotificationStep.emailNotificationToDestinationOptions,
                ...extraOptions
              ]
            )
          );

          emailNotificationStep.setEmailNotificationCcDestinationOptions(
            makeOptionsForMultiSelect(
              emailNotificationStep.emailNotificationCcSelectedValues,
              emailNotificationStep.emailNotificationCcAmendable,
              [
                ...emailNotificationStep.emailNotificationCcDestinationOptions,
                ...extraOptions
              ]
            )
          );
          emailNotificationStep.setEmailNotificationBccDestinationOptions(
            makeOptionsForMultiSelect(
              emailNotificationStep.emailNotificationBccSelectedValues,
              emailNotificationStep.emailNotificationBccAmendable,
              [
                ...emailNotificationStep.emailNotificationBccDestinationOptions,
                ...extraOptions
              ]
            )
          );
        }
      );
    },
    updateEmailNotificationExpectedDataList() {
      this.emailNotificationExpectedDataList.forEach(
        ({ emailNotificationExpectedData, stepIndex }) => {
          const { value: valueType } =
            emailNotificationExpectedData.getSelectedValueTypeOption();
          if (!this.isLiteralValueType(valueType)) {
            const { component, componentOptions } =
              this.makeEmailNotificationExpectedDataForEntity({
                valueDataType: emailNotificationExpectedData.valueDataType,
                componentValue: emailNotificationExpectedData.componentValue,
                componentOptions: this.getSharedComponentOptions(stepIndex),
                stepIndex
              });

            emailNotificationExpectedData.setComponent(component);
            emailNotificationExpectedData.setComponentOptions(componentOptions);
          }
        }
      );
    },
    makeEmailDestinationData({
      key = "",
      id = "",
      emailList = [],
      stepIndex = 0
    } = {}) {
      const userEmailTemplate = this.getUserEmailTemplateBasedById(id);
      const emailStepDestinations =
        this.getSelectedValuesFromDestinationKey(emailList);
      const userEmailTemplateDestinations =
        this.getSelectedValuesFromDestinationKey(userEmailTemplate?.[key]);
      const amendableValues = this.getUniqueList({
        listToIterate: emailStepDestinations.selectedValues,
        listToCompare: userEmailTemplateDestinations.selectedValues
      });
      const extraOptions = this.getOptionsFromAction(stepIndex);

      return {
        options: makeOptionsForMultiSelect(
          emailStepDestinations.selectedValues,
          amendableValues,
          [
            ...this.getDestinationBaseOptions,
            ...emailStepDestinations.literalEmailOptions,
            ...extraOptions
          ]
        ),
        amendableValues,
        selectedValues: emailStepDestinations.selectedValues,
        userEmailTemplateSelectedValues:
          userEmailTemplateDestinations.selectedValues
      };
    },
    makeSuccessEmailNotificationStep({
      emailNotificationStep,
      stepIndex
    } = {}) {
      return {
        stepType: actionStepSubType.EMAIL,
        component: EmailNotificationStep,
        componentOptions: this.makeEmailNotificationStepAndExtendStepList({
          functionData: emailNotificationStep,
          stepIndex
        })
      };
    },
    makeEmailNotificationStepAndExtendStepList({
      functionData = {},
      stepIndex = this.functionSteps.length
    } = {}) {
      const emailNotificationStepInstance = this.makeEmailNotificationStep({
        functionData,
        stepIndex
      });
      this.emailNotificationStepList.push({
        stepIndex,
        emailNotificationStep: emailNotificationStepInstance
      });
      return emailNotificationStepInstance;
    },
    makeNewEmailNotificationStep(stepIndex = this.functionSteps.length - 1) {
      this.updateAllStepIndexes(operations.ADD, stepIndex);
      this.functionSteps.splice(
        stepIndex,
        0,
        this.makeEmailNotificationStepAndExtendStepList({
          functionData: undefined,
          stepIndex: this.addBlockIndex
        })
      );
    },
    makeSuccessEmailNotificationStepForIfBlock() {
      const { index: stepIndex, event } = this.successStepEvent;
      const successSetStep = this.makeSuccessEmailNotificationStep({
        stepIndex
      });
      if (event.property === actionKeys.ELSE_BLOCK) {
        const elseBlocks = [
          ...(this.functionSteps[stepIndex]?.[event.property] || []),
          successSetStep
        ];
        this.$set(this.functionSteps[stepIndex], event.property, elseBlocks);
      } else {
        const successSteps = [
          ...(this.functionSteps[stepIndex]?.[event.property]?.[event.index]
            ?.successSteps || []),
          successSetStep
        ];
        this.$set(
          this.functionSteps[stepIndex][event.property][event.index],
          actionKeys.SUCCESS_STEPS,
          successSteps
        );
      }
    },
    updateEmailNotificationStepsIndexes(editType, index) {
      for (
        let emailNotificationStepsIndex = 0;
        emailNotificationStepsIndex < this.emailNotificationStepList.length;
        emailNotificationStepsIndex++
      ) {
        if (
          this.emailNotificationStepList[emailNotificationStepsIndex]
            .stepIndex >= index
        ) {
          if (
            editType === operations.ADD &&
            this.emailNotificationStepList[emailNotificationStepsIndex]
              .stepIndex >= index
          ) {
            this.emailNotificationStepList[
              emailNotificationStepsIndex
            ].stepIndex += 1;
          } else if (
            editType === operations.DELETE &&
            this.emailNotificationStepList[emailNotificationStepsIndex]
              .stepIndex > index
          ) {
            this.emailNotificationStepList[
              emailNotificationStepsIndex
            ].stepIndex -= 1;
          }
        }
      }
    },
    updateEmailNotificationExpectedDataIndexes(editType, index) {
      for (
        let emailNotificationExpectedDataIndex = 0;
        emailNotificationExpectedDataIndex <
        this.emailNotificationExpectedDataList.length;
        emailNotificationExpectedDataIndex++
      ) {
        if (
          this.emailNotificationExpectedDataList[
            emailNotificationExpectedDataIndex
          ].stepIndex >= index
        ) {
          if (
            editType === operations.ADD &&
            this.emailNotificationExpectedDataList[
              emailNotificationExpectedDataIndex
            ].stepIndex >= index
          ) {
            this.emailNotificationExpectedDataList[
              emailNotificationExpectedDataIndex
            ].stepIndex += 1;
          } else if (
            editType === operations.DELETE &&
            this.emailNotificationExpectedDataList[
              emailNotificationExpectedDataIndex
            ].stepIndex > index
          ) {
            this.emailNotificationExpectedDataList[
              emailNotificationExpectedDataIndex
            ].stepIndex -= 1;
          }
        }
      }
    },
    onEmailNotificationStepChange({ event, stepIndex }) {
      if (event.name === actionKeys.TEMPLATE_ID) {
        this.emailNotificationTemplateIdChange({ event, stepIndex });
      } else if (
        event.name === actionKeys.TO ||
        event.name === actionKeys.CC ||
        event.name === actionKeys.BCC
      ) {
        this.emailNotificationDestinationChange(event);
      } else if (event.name === actionKeys.EXPECTED_DATA_VARIABLE) {
        this.emailNotificationExpectedDataChange({ ...event.event, stepIndex });
      }
    },
    getEmailNotificationStepBasedOnId(id) {
      return (
        this.emailNotificationStepList.find(
          ({ emailNotificationStep }) => emailNotificationStep.id === id
        )?.emailNotificationStep || {}
      );
    },
    resetEmailNotificationStep({ event, stepIndex }) {
      const emailNotificationStep = this.getEmailNotificationStepBasedOnId(
        event.id
      );
      const emptyEmailNotificationStep = this.makeEmptyEmailNotificationStep();

      emailNotificationStep.setEmailNotificationExpectedDataList(
        emptyEmailNotificationStep.emailNotificationExpectedData
      );
      emailNotificationStep.setEmailNotificationOptions(
        emptyEmailNotificationStep.emailNotificationOptions
      );
      emailNotificationStep.setEmailNotificationError(
        emptyEmailNotificationStep.emailNotificationError
      );
      emailNotificationStep.setEmailNotificationSearchValue(
        emptyEmailNotificationStep.emailNotificationSearchValue
      );
      emailNotificationStep.setEmailNotificationToDestinationOptions(
        emptyEmailNotificationStep.emailNotificationToDestinationOptions
      );
      emailNotificationStep.setEmailNotificationToAmendable(
        emptyEmailNotificationStep.emailNotificationBccAmendable
      );
      emailNotificationStep.setEmailNotificationToSelectedValues(
        emptyEmailNotificationStep.emailNotificationToSelectedValues
      );
      emailNotificationStep.setEmailDestinationToError(
        emptyEmailNotificationStep.emailDestinationToError
      );
      emailNotificationStep.setEmailNotificationCcDestinationOptions(
        emptyEmailNotificationStep.emailNotificationCcDestinationOptions
      );
      emailNotificationStep.setEmailNotificationCcAmendable(
        emptyEmailNotificationStep.emailNotificationCcAmendable
      );
      emailNotificationStep.setEmailNotificationCcSelectedValues(
        emptyEmailNotificationStep.emailNotificationCcSelectedValues
      );
      emailNotificationStep.setEmailDestinationCcError(
        emptyEmailNotificationStep.emailDestinationCcError
      );
      emailNotificationStep.setEmailNotificationBccDestinationOptions(
        emptyEmailNotificationStep.emailNotificationBccDestinationOptions
      );
      emailNotificationStep.setEmailNotificationBccSelectedValues(
        emptyEmailNotificationStep.emailNotificationBccSelectedValues
      );
      emailNotificationStep.setEmailDestinationBccError(
        emptyEmailNotificationStep.emailDestinationBccError
      );
      emailNotificationStep.setUserEmailTemplateToValues(
        emptyEmailNotificationStep.userEmailTemplateToValues
      );
      emailNotificationStep.setUserEmailTemplateBccValues(
        emptyEmailNotificationStep.userEmailTemplateBccValues
      );
      emailNotificationStep.setUserEmailTemplateCcValues(
        emptyEmailNotificationStep.userEmailTemplateCcValues
      );

      this.emailNotificationExpectedDataList =
        this.emailNotificationExpectedDataList.filter(
          (emailNotificationExpectedData) =>
            emailNotificationExpectedData.stepIndex !== stepIndex
        );
    },
    makeEmptyEmailNotificationExpectedData({
      userEmailExpectedData,
      stepIndex
    }) {
      return userEmailExpectedData.map(({ variable, type }) => {
        const componentValue = "";
        const valueDataTypeOptions = makeOptionsForMultiSelect(
          type,
          emailExpectedDataTypeOptions
        );
        const sharedComponentOptions =
          this.getSharedComponentOptions(stepIndex);
        const { component, componentOptions } =
          this.makeEmailNotificationExpectedDataForLiteral({
            componentValue,
            componentOptions: sharedComponentOptions
          });
        const emailNotificationExpectedDataInstance =
          this.makeEmailNotificationExpectedDataAndExpendList({
            options: {
              emailNotificationExpectedDataName: variable,
              valueDataType: type,
              valueDataTypeOptions,
              valueDataTypeText: variableTypeToDisplayTextMap[type],
              valueTypeOptions: makeOptionsForSelect(
                variableAssignmentType.LITERAL,
                variableAssignmentOptions
              ),
              component,
              componentOptions,
              componentValue
            },
            stepIndex
          });
        return emailNotificationExpectedDataInstance;
      });
    },
    setEmailNotificationStep({ event, stepIndex }) {
      const emailNotificationStep = this.getEmailNotificationStepBasedOnId(
        event.id
      );

      const selectedUserEmailTemplate = this.getUserEmailTemplateBasedById(
        event.event
      );
      const emailNotificationExpectedData =
        this.makeEmptyEmailNotificationExpectedData({
          userEmailExpectedData: selectedUserEmailTemplate.expectedData,
          stepIndex
        });
      const toEmailDestinations = this.makeEmailDestinationData({
        emailList: selectedUserEmailTemplate[actionKeys.TO],
        key: [actionKeys.TO],
        id: selectedUserEmailTemplate.id,
        stepIndex
      });
      const ccEmailDestinations = this.makeEmailDestinationData({
        emailList: selectedUserEmailTemplate[actionKeys.CC],
        key: [actionKeys.CC],
        id: selectedUserEmailTemplate.id,
        stepIndex
      });
      const bccEmailDestinations = this.makeEmailDestinationData({
        emailList: selectedUserEmailTemplate[actionKeys.BCC],
        key: [actionKeys.BCC],
        id: selectedUserEmailTemplate.id,
        stepIndex
      });

      emailNotificationStep.setEmailNotificationOptions(
        makeOptionsForSelect(
          selectedUserEmailTemplate.id,
          this.getUserEmailTemplatesOptions
        )
      );
      emailNotificationStep.setEmailNotificationSearchValue(
        selectedUserEmailTemplate.name
      );
      emailNotificationStep.setEmailNotificationError("");
      emailNotificationStep.setEmailNotificationToDestinationOptions(
        toEmailDestinations.options
      );
      emailNotificationStep.setEmailNotificationToAmendable(
        toEmailDestinations.amendableValues
      );
      emailNotificationStep.setEmailNotificationToSelectedValues(
        toEmailDestinations.selectedValues
      );
      emailNotificationStep.setUserEmailTemplateToValues(
        toEmailDestinations.userEmailTemplateSelectedValues
      );
      emailNotificationStep.setEmailDestinationToError("");
      emailNotificationStep.setEmailNotificationCcDestinationOptions(
        ccEmailDestinations.options
      );
      emailNotificationStep.setEmailNotificationCcAmendable(
        ccEmailDestinations.amendableValues
      );
      emailNotificationStep.setEmailNotificationCcSelectedValues(
        ccEmailDestinations.selectedValues
      );
      emailNotificationStep.setUserEmailTemplateCcValues(
        ccEmailDestinations.userEmailTemplateSelectedValues
      );
      emailNotificationStep.setEmailDestinationCcError("");
      emailNotificationStep.setEmailNotificationBccDestinationOptions(
        bccEmailDestinations.options
      );
      emailNotificationStep.setEmailNotificationBccAmendable(
        bccEmailDestinations.amendableValues
      );
      emailNotificationStep.setEmailNotificationBccSelectedValues(
        bccEmailDestinations.selectedValues
      );
      emailNotificationStep.setUserEmailTemplateBccValues(
        bccEmailDestinations.userEmailTemplateSelectedValues
      );
      emailNotificationStep.setEmailDestinationBccError("");
      emailNotificationStep.setEmailNotificationExpectedDataList(
        emailNotificationExpectedData
      );
    },
    emailNotificationTemplateIdChange({ event, stepIndex }) {
      if (event.event) {
        this.setEmailNotificationStep({ event, stepIndex });
      } else {
        this.resetEmailNotificationStep({ event, stepIndex });
      }
    },
    emailNotificationDestinationChangeAddTo({ value, emailNotification }) {
      const emailNotificationAmendable = [
        ...emailNotification.emailNotificationToAmendable,
        value
      ];
      const emailNotificationSelectedValues = [
        ...emailNotification.emailNotificationToSelectedValues,
        value
      ];
      emailNotification.setEmailNotificationToAmendable(
        emailNotificationAmendable
      );
      emailNotification.setEmailNotificationToSelectedValues(
        emailNotificationSelectedValues
      );
      emailNotification.setEmailNotificationToDestinationOptions(
        makeOptionsForMultiSelect(
          emailNotificationSelectedValues,
          emailNotificationAmendable,
          emailNotification.emailNotificationToDestinationOptions
        )
      );
      emailNotification.setEmailDestinationToError("");
    },
    emailNotificationDestinationChangeAddCc({ value, emailNotification }) {
      const emailNotificationAmendable = [
        ...emailNotification.emailNotificationCcAmendable,
        value
      ];
      const emailNotificationSelectedValues = [
        ...emailNotification.emailNotificationCcSelectedValues,
        value
      ];
      emailNotification.setEmailNotificationCcAmendable(
        emailNotificationAmendable
      );
      emailNotification.setEmailNotificationCcSelectedValues(
        emailNotificationSelectedValues
      );
      emailNotification.setEmailNotificationCcDestinationOptions(
        makeOptionsForMultiSelect(
          emailNotificationSelectedValues,
          emailNotificationAmendable,
          emailNotification.emailNotificationCcDestinationOptions
        )
      );
      emailNotification.setEmailDestinationCcError("");
    },
    emailNotificationDestinationChangeAddBcc({ value, emailNotification }) {
      const emailNotificationAmendable = [
        ...emailNotification.emailNotificationBccAmendable,
        value
      ];
      const emailNotificationSelectedValues = [
        ...emailNotification.emailNotificationBccSelectedValues,
        value
      ];
      emailNotification.setEmailNotificationBccAmendable(
        emailNotificationAmendable
      );
      emailNotification.setEmailNotificationBccSelectedValues(
        emailNotificationSelectedValues
      );
      emailNotification.setEmailNotificationBccDestinationOptions(
        makeOptionsForMultiSelect(
          emailNotificationSelectedValues,
          emailNotificationAmendable,
          emailNotification.emailNotificationBccDestinationOptions
        )
      );
      emailNotification.setEmailDestinationBccError("");
    },
    emailNotificationDestinationChangeAdd({ value, emailNotification, key }) {
      if (key === actionKeys.TO) {
        this.emailNotificationDestinationChangeAddTo({
          value,
          emailNotification
        });
      } else if (key === actionKeys.CC) {
        this.emailNotificationDestinationChangeAddCc({
          value,
          emailNotification
        });
      } else if (key === actionKeys.BCC) {
        this.emailNotificationDestinationChangeAddBcc({
          value,
          emailNotification
        });
      }
    },
    emailNotificationDestinationChangeDelete({
      value,
      emailNotification,
      key
    }) {
      if (key === actionKeys.TO) {
        this.emailNotificationDestinationChangeDeleteTo({
          value,
          emailNotification
        });
      } else if (key === actionKeys.CC) {
        this.emailNotificationDestinationChangeDeleteCc({
          value,
          emailNotification
        });
      } else if (key === actionKeys.BCC) {
        this.emailNotificationDestinationChangeDeleteBcc({
          value,
          emailNotification
        });
      }
    },
    emailNotificationDestinationChangeDeleteTo({ value, emailNotification }) {
      const emailNotificationAmendable =
        emailNotification.emailNotificationToAmendable.filter(
          (emailNotificationToAmendableValue) =>
            emailNotificationToAmendableValue !== value
        );
      const emailNotificationSelectedValues =
        emailNotification.emailNotificationToSelectedValues.filter(
          (emailNotificationToSelectedValue) =>
            emailNotificationToSelectedValue !== value
        );
      emailNotification.setEmailNotificationToAmendable(
        emailNotificationAmendable
      );
      emailNotification.setEmailNotificationToSelectedValues(
        emailNotificationSelectedValues
      );
      emailNotification.setEmailNotificationToDestinationOptions(
        makeOptionsForMultiSelect(
          emailNotificationSelectedValues,
          emailNotificationAmendable,
          emailNotification.emailNotificationToDestinationOptions
        )
      );
      emailNotification.setEmailDestinationToError("");
    },
    emailNotificationDestinationChangeDeleteCc({ value, emailNotification }) {
      const emailNotificationAmendable =
        emailNotification.emailNotificationCcAmendable.filter(
          (emailNotificationCcAmendableValue) =>
            emailNotificationCcAmendableValue !== value
        );
      const emailNotificationSelectedValues =
        emailNotification.emailNotificationCcSelectedValues.filter(
          (emailNotificationCcSelectedValue) =>
            emailNotificationCcSelectedValue !== value
        );
      emailNotification.setEmailNotificationCcAmendable(
        emailNotificationAmendable
      );
      emailNotification.setEmailNotificationCcSelectedValues(
        emailNotificationSelectedValues
      );
      emailNotification.setEmailNotificationCcDestinationOptions(
        makeOptionsForMultiSelect(
          emailNotificationSelectedValues,
          emailNotificationAmendable,
          emailNotification.emailNotificationCcDestinationOptions
        )
      );
      emailNotification.setEmailDestinationCcError("");
    },
    emailNotificationDestinationChangeDeleteBcc({ value, emailNotification }) {
      const emailNotificationAmendable =
        emailNotification.emailNotificationBccAmendable.filter(
          (emailNotificationBccAmendableValue) =>
            emailNotificationBccAmendableValue !== value
        );
      const emailNotificationSelectedValues =
        emailNotification.emailNotificationBccSelectedValues.filter(
          (emailNotificationBccSelectedValue) =>
            emailNotificationBccSelectedValue !== value
        );
      emailNotification.setEmailNotificationBccAmendable(
        emailNotificationAmendable
      );
      emailNotification.setEmailNotificationBccSelectedValues(
        emailNotificationSelectedValues
      );
      emailNotification.setEmailNotificationBccDestinationOptions(
        makeOptionsForMultiSelect(
          emailNotificationSelectedValues,
          emailNotificationAmendable,
          emailNotification.emailNotificationBccDestinationOptions
        )
      );
      emailNotification.setEmailDestinationBccError("");
    },
    emailNotificationDestinationChange({ id, event, name }) {
      const emailNotification = this.getEmailNotificationStepBasedOnId(id);

      if (event?.eventType === operations.ADD) {
        this.emailNotificationDestinationChangeAdd({
          value: event.value,
          emailNotification,
          key: name
        });
      } else if (event?.eventType === operations.DELETE) {
        this.emailNotificationDestinationChangeDelete({
          value: event.value,
          emailNotification,
          key: name
        });
      } else if (event?.eventType === operations.CREATE) {
        this.emailNotificationDestinationChangeCreate({
          value: event.value,
          emailNotification,
          key: name
        });
      }
    },
    emailNotificationDestinationChangeCreate({
      value,
      emailNotification,
      key
    }) {
      if (key === actionKeys.TO) {
        this.emailNotificationDestinationChangeCreateTo({
          value,
          emailNotification
        });
      } else if (key === actionKeys.CC) {
        this.emailNotificationDestinationChangeCreateCc({
          value,
          emailNotification
        });
      } else if (key === actionKeys.BCC) {
        this.emailNotificationDestinationChangeCreateBcc({
          value,
          emailNotification
        });
      }
    },
    emailNotificationDestinationChangeCreateTo({ value, emailNotification }) {
      const { errorMessage, option } =
        this.makeLiteralEmailNotificationDestination({
          list: emailNotification.emailNotificationToDestinationOptions,
          value
        });
      if (errorMessage) {
        emailNotification.setEmailDestinationToError(errorMessage);
      } else {
        emailNotification.setEmailNotificationToDestinationOptions([
          ...emailNotification.emailNotificationToDestinationOptions,
          option
        ]);
        this.emailNotificationDestinationChangeAddTo({
          value,
          emailNotification
        });
      }
    },
    emailNotificationDestinationChangeCreateCc({ value, emailNotification }) {
      const { errorMessage, option } =
        this.makeLiteralEmailNotificationDestination({
          list: emailNotification.emailNotificationCcDestinationOptions,
          value
        });
      if (errorMessage) {
        emailNotification.setEmailDestinationCcError(errorMessage);
      } else {
        emailNotification.setEmailNotificationCcDestinationOptions([
          ...emailNotification.emailNotificationCcDestinationOptions,
          option
        ]);
        this.emailNotificationDestinationChangeAddCc({
          value,
          emailNotification
        });
      }
    },
    emailNotificationDestinationChangeCreateBcc({ value, emailNotification }) {
      const { errorMessage, option } =
        this.makeLiteralEmailNotificationDestination({
          list: emailNotification.emailNotificationBccDestinationOptions,
          value
        });
      if (errorMessage) {
        emailNotification.setEmailDestinationBccError(errorMessage);
      } else {
        emailNotification.setEmailNotificationBccDestinationOptions([
          ...emailNotification.emailNotificationBccDestinationOptions,
          option
        ]);
        this.emailNotificationDestinationChangeAddBcc({
          value,
          emailNotification
        });
      }
    },
    makeLiteralEmailNotificationDestination({ value, list }) {
      const result = {
        errorMessage: "",
        option: {}
      };
      if (hasCorrectEmailFormat(value)) {
        const isValueInList = !!list.find((option) => option.value === value);
        if (isValueInList) {
          result.errorMessage = `${value} is a duplicate of a previous entry`;
        } else {
          result.option = makeLiteralOptionForMultiSelect({
            text: value,
            value,
            type: userEmailTemplatesDestinationTypes.EMAIL
          });
        }
      } else {
        result.errorMessage = "The inputted value is not a valid email address";
      }
      return result;
    },
    emailNotificationExpectedDataChange({ event, id, name, stepIndex }) {
      if (name === actionKeys.VALUE_TYPE) {
        this.emailNotificationExpectedDataChangeValueType({
          event,
          id,
          stepIndex
        });
      } else if (name === actionKeys.VALUE) {
        this.emailNotificationExpectedDataChangeValue({
          event,
          id
        });
      }
    },
    getEmailNotificationExpectedData(id) {
      return (
        this.emailNotificationExpectedDataList.find(
          ({ emailNotificationExpectedData }) =>
            emailNotificationExpectedData.id === id
        )?.emailNotificationExpectedData || {}
      );
    },
    emailNotificationExpectedDataChangeValueType({ event, id, stepIndex }) {
      const componentValue = "";
      const emailNotificationExpectedData =
        this.getEmailNotificationExpectedData(id);
      const sharedComponentOptions = this.getSharedComponentOptions(stepIndex);
      const { component, componentOptions } = this.isLiteralValueType(event)
        ? this.makeEmailNotificationExpectedDataForLiteral({
            componentValue,
            componentOptions: sharedComponentOptions
          })
        : this.makeEmailNotificationExpectedDataForEntity({
            valueDataType: emailNotificationExpectedData.valueDataType,
            componentValue,
            componentOptions: sharedComponentOptions,
            stepIndex
          });
      emailNotificationExpectedData.setValueTypeErrorMessage("");
      emailNotificationExpectedData.setValueTypeOptions(
        makeOptionsForSelect(
          event,
          emailNotificationExpectedData.valueTypeOptions
        )
      );
      emailNotificationExpectedData.setComponent(component);
      emailNotificationExpectedData.setComponentOptions(componentOptions);
      emailNotificationExpectedData.setComponentValue(componentValue);
    },
    emailNotificationExpectedDataChangeValue({ event, id }) {
      const emailNotificationExpectedData =
        this.getEmailNotificationExpectedData(id);
      if (
        this.isLiteralValueType(
          emailNotificationExpectedData?.getSelectedValueTypeOption()?.value
        )
      ) {
        this.updateSetToBlockValueForLiteralValue({
          setToBlockToUpdate: emailNotificationExpectedData,
          selectedValue: event
        });
      } else {
        this.updateSetToBlockValueForEntity({
          setToBlockToUpdate: emailNotificationExpectedData,
          newValue: event
        });
      }
    },
    amendSuccessEmailNotificationStep({ event, stepIndex }) {
      this.onEmailNotificationStepChange({ event: event.event, stepIndex });
    }
  }
};
