<!-- eslint-disable vue/require-component-is -->
<template>
  <div data-test-id="product_status">
    <component :is="component" v-bind="componentOptions" />
  </div>
</template>

<script>
import moment from "moment";
import "moment/locale/es";
import {
  thirdPartyActionProductStatus,
  thirdPartyActionTaskStatus,
  thirdPartyActionTaskStatusValue,
  thirdPartyActionTaskRisk,
  riskRangeValue,
  themes,
  thirdPartyProductColumnKeys,
  urls,
  defaultIcons
} from "@/constants";
import BaseText from "@/atoms/BaseText/BaseText";
import BaseBadge from "@/atoms/BaseBadge/BaseBadge";
import BadgeLink from "@/molecules/BadgeLink/BadgeLink";
import BadgeWithText from "@/molecules/BadgeWithText/BadgeWithText";
import BaseIcon from "@/atoms/BaseIcon/BaseIcon";

export default {
  name: "ProductStatus",
  components: { BaseText, BaseBadge, BadgeLink, BadgeWithText, BaseIcon },
  props: {
    columnKey: {
      type: String,
      default: ""
    },
    data: {
      type: Object,
      default: () => ({})
    },
    columnId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      component: null,
      componentOptions: {}
    };
  },
  created() {
    this.setDisplayData();
  },
  methods: {
    setDisplayData() {
      const text = this.columnKey || "";

      if (text.includes(thirdPartyProductColumnKeys.LATEST_DDQ_SENT)) {
        this.setTextOptions();
      } else if (
        text.includes(thirdPartyProductColumnKeys.LATEST_COMPLETED_DDQ_STATUS)
      ) {
        this.setBadgeOptions();
      } else if (text.includes(thirdPartyProductColumnKeys.LATEST_DDQ_STATUS)) {
        this.getTaskStatus();
      } else if (text.includes(thirdPartyProductColumnKeys.COMPLETED_DATE)) {
        this.getProductDate("completed_at");
      } else if (text.includes(thirdPartyProductColumnKeys.CREATED_DATE)) {
        this.getProductDate("created_at");
      } else if (text.includes(thirdPartyProductColumnKeys.PRODUCT_STATUS)) {
        this.getProductStatus();
      } else if (text.includes(thirdPartyProductColumnKeys.PAYMENT_OPTION)) {
        this.getProductPaymentType();
      }
    },
    setTextOptions() {
      const text = this.data[this.columnKey];
      this.component = BaseText;
      this.componentOptions = {
        text: this.formatDate(text)
      };
    },
    formatDate(date) {
      if (date) {
        return moment.utc(date).local().locale("en").fromNow();
      }
    },
    setBadgeOptions() {
      const riskStatus = this.data?.[this.columnKey];
      if (riskStatus?.risk_data) {
        const riskData = JSON.parse(riskStatus.risk_data);
        if (this.isRiskScoreColor(riskData)) {
          this.component = BadgeWithText;
          this.componentOptions = {
            badgeLinkOptions: {
              badgeOptions: {
                text: this.getRiskDataScore(riskData),
                theme: this.getRiskDataTheme(riskData)
              },
              path: urls.DDQ_ONBOARDING(riskStatus.id),
              openInNewTab: true
            },
            badgeTextOptions: {
              text: riskData?.risk
            }
          };
        }
      }
    },
    isRiskScoreColor(riskData) {
      return (
        riskData?.risk === thirdPartyActionTaskRisk.HIGH ||
        riskData?.risk === thirdPartyActionTaskRisk.LOW ||
        riskData?.risk === thirdPartyActionTaskRisk.MODERATE
      );
    },
    getRiskDataScore(riskData) {
      if (riskData?.rating) {
        return `${riskRangeValue[riskData.rating]} (${riskData.rating})`;
      } else {
        return thirdPartyActionTaskStatusValue.COMPLETED;
      }
    },
    getRiskDataTheme(riskData) {
      switch (riskData?.risk) {
        case thirdPartyActionTaskRisk.HIGH:
          return themes.ERROR;
        case thirdPartyActionTaskRisk.MODERATE:
          return themes.WARNING;
        case thirdPartyActionTaskRisk.LOW:
          return themes.SUCCESS;
      }
    },
    getTaskStatus() {
      const riskStatus = this.data[this.columnKey];
      const taskStatus = riskStatus?.task_status;
      if (taskStatus === thirdPartyActionTaskStatus.COMPLETED) {
        this.component = BadgeLink;
        this.componentOptions = {
          badgeOptions: {
            text: thirdPartyActionTaskStatusValue.COMPLETED,
            theme: themes.SUCCESS
          },
          path: urls.DDQ_ONBOARDING(riskStatus.id),
          openInNewTab: true
        };
      } else if (taskStatus === thirdPartyActionTaskStatus.REVIEW) {
        this.component = BaseBadge;
        this.componentOptions = {
          text: thirdPartyActionTaskStatusValue.REVIEW,
          theme: themes.WEAK
        };
      } else {
        this.component = BaseText;
        this.componentOptions = {
          text: this.getTaskText(taskStatus)
        };
      }
    },
    getTaskText(taskStatus) {
      if (taskStatus === thirdPartyActionTaskStatus.NOT_STARTED) {
        return thirdPartyActionTaskStatusValue.NOT_STARTED;
      } else if (taskStatus === thirdPartyActionTaskStatus.IN_PROGRESS) {
        return thirdPartyActionTaskStatusValue.IN_PROGRESS;
      } else if (taskStatus === thirdPartyActionTaskStatus.ON_HOLD) {
        return thirdPartyActionTaskStatusValue.ON_HOLD;
      } else if (taskStatus === thirdPartyActionTaskStatus.NOT_REQUIRED) {
        return thirdPartyActionTaskStatusValue.NOT_REQUIRED;
      } else if (taskStatus === thirdPartyActionTaskStatus.CANCELLED) {
        return thirdPartyActionTaskStatusValue.CANCELLED;
      } else if (
        taskStatus === thirdPartyActionTaskStatus.DECLINED_OR_REFUSED
      ) {
        return thirdPartyActionTaskStatusValue.DECLINED_OR_REFUSED;
      } else if (taskStatus === thirdPartyActionTaskStatus.SELF_EVIDENCED) {
        return thirdPartyActionTaskStatusValue.SELF_EVIDENCED;
      } else if (
        taskStatus === thirdPartyActionTaskStatus.IN_PROGRESS_PAUSE_REMINDERS
      ) {
        return thirdPartyActionTaskStatusValue.IN_PROGRESS_PAUSE_REMINDERS;
      } else {
        return thirdPartyActionTaskStatusValue.NEVER_SENT;
      }
    },
    getProductId() {
      if (this.data?.product_orders) {
        return this.data.product_orders.findIndex(
          (product) => parseInt(product.product_id) === parseInt(this.columnId)
        );
      }
    },
    getProductDate(key) {
      if (this.getProductId() !== -1) {
        const text = this.data?.product_orders[this.getProductId()][key];
        if (text) {
          this.component = BaseText;
          this.componentOptions = {
            text: this.formatDate(text)
          };
        }
      }
    },
    getProductStatus() {
      if (this.getProductId() !== -1) {
        if (
          this.data?.product_orders[this.getProductId()]?.status ===
          thirdPartyActionProductStatus.IN_PROGRESS
        ) {
          this.component = BaseIcon;
          this.componentOptions = defaultIcons.IN_PROGRESS
        } else if (
          this.data?.product_orders[this.getProductId()]?.status ===
          thirdPartyActionProductStatus.COMPLETED
        ) {
          this.component = BaseBadge;
          this.componentOptions = {
            text: "Completed",
            theme: themes.STRONG_GREEN
          };
        }
      }
    },
    getProductPaymentType() {
      if (this.getProductId() !== -1) {
        this.component = BaseText;
        this.componentOptions = {
          text: this.data?.product_orders[this.getProductId()]?.payment_type
        };
      }
    }
  }
};
</script>
