import { thirdPartyService } from "@/services";
import {
  actionName,
  mutationName,
  platformFeatures,
  thirdPartyActionProductType
} from "@/constants";

export const actions = {
  async fetchTCertificationOptions({ commit }, { productId }) {
    try {
      const { data = {} } =
        await thirdPartyService.fetchTComplianceOrderTypeProduct(productId);

      commit("setTCertificationOrderTypes", data);
      return data;
    } catch (error) {
      commit("setTCertificationOrderTypes", []);
      throw error;
    }
  },
  async fetchESGMetricsOptions({ commit }, { productId }) {
    try {
      const { data = {} } =
        await thirdPartyService.fetchTComplianceOrderTypeProduct(productId);

      commit("setESGMetricsOrderTypes", data);
      return data;
    } catch (error) {
      commit("setESGMetricsOrderTypes", []);
      throw error;
    }
  },
  async fetchFormsLanguagesList({ commit }, { companyId }) {
    try {
      const data = await thirdPartyService.fetchFormsLanguagesList(companyId);

      commit(mutationName.THIRD_PARTY.SET_FORMS_LANGUAGES_LIST, data);
      return data;
    } catch (error) {
      commit(mutationName.THIRD_PARTY.SET_FORMS_LANGUAGES_LIST, []);
      throw error;
    }
  },
  async fetchOCStatesList({ commit }) {
    try {
      const data = await thirdPartyService.fetchOCStatesList();

      commit(mutationName.THIRD_PARTY.SET_OC_STATES_LIST, data);
      return data;
    } catch (error) {
      commit(mutationName.THIRD_PARTY.SET_OC_STATES_LIST, []);
      throw error;
    }
  },
  async fetchHeaderConfigData({ commit, rootState }) {
    const { userId } = rootState.user;
    const { companyId } = rootState.company;

    try {
      const data = await thirdPartyService.fetchHeaderConfigData(
        companyId,
        userId
      );

      commit(mutationName.THIRD_PARTY.SET_HEADER_CONFIG_DATA, data);
      return data;
    } catch (error) {
      commit(mutationName.THIRD_PARTY.SET_HEADER_CONFIG_DATA, {});
      throw error;
    }
  },
  async fetchEDDClientAllPackages({ commit, rootState }) {
    const { companyId } = rootState.company;

    try {
      const data = await thirdPartyService.fetchEDDClientAllPackages(companyId);
      const filterData = data?.filter(
        (list) => parseInt(list.client_packages_id) > 0
      );

      commit(mutationName.THIRD_PARTY.SET_EDD_CLIENT_ALL_PACKAGES, filterData);
      return filterData;
    } catch (error) {
      commit(mutationName.THIRD_PARTY.SET_EDD_CLIENT_ALL_PACKAGES, []);
      throw error;
    }
  },
  async fetchPlatformFeature({ commit, rootState }, tpData) {
    const { companyId } = rootState.company;
    const { data = {} } = await thirdPartyService.fetchPlatformFeatures(
      companyId
    );
    const foundDNB = data.data?.find(
      (platformFeature) =>
        platformFeature.name === platformFeatures.CORPORATE_DATA &&
        platformFeature.value === platformFeatures.DNB
    );

    commit("setThirdPartyModalData", tpData || null);
    if (foundDNB) {
      commit("setIsThirdPartyDnbModalVisible", true);
    } else {
      commit("setIsThirdPartyNewModalVisible", true);
    }
  },
  async fetchAllThirdPartyData({ dispatch, rootState }) {
    const { companyId } = rootState.company;

    const formData = {
      sort: "tp_sequence|asc",
      comp_id: companyId,
      is_active: 1
    };

    await Promise.allSettled([
      dispatch(
        actionName.CUSTOM_ATTRIBUTES.FETCH_CUSTOM_ATTRIBUTES_LIST,
        formData,
        { root: true }
      ),
      dispatch("fetchTCertificationOptions", {
        productId: thirdPartyActionProductType.T_CERTIFICATION
      }),
      dispatch("fetchESGMetricsOptions", {
        productId: thirdPartyActionProductType.ESG_METRIC
      }),
      dispatch("fetchOCStatesList"),
      dispatch("fetchHeaderConfigData"),
      dispatch("fetchEDDClientAllPackages")
    ]);
  },
  async fetchThirdPartyUBOs({ commit }, queryParameters) {
    try {
      commit(mutationName.THIRD_PARTY.SET_IS_UBO_DATA_LOADING, true, {
        root: true
      });
      commit(mutationName.THIRD_PARTY.SET_THIRD_PARTY_UBO_ERROR, "", {
        root: true
      });
      const data = await thirdPartyService.fetchUBOThirdPartyList(
        queryParameters
      );
      commit(mutationName.THIRD_PARTY.SET_THIRD_PARTY_UBO_LIST, data, {
        root: true
      });
    } catch ({ status }) {
      commit(mutationName.THIRD_PARTY.SET_THIRD_PARTY_UBO_ERROR, status, {
        root: true
      });
    } finally {
      commit(mutationName.THIRD_PARTY.SET_IS_UBO_DATA_LOADING, false, {
        root: true
      });
    }
  },
  async createThirdPartyFromUBO(
    { commit, rootState },
    { postData = {}, thirdPartyId = "" }
  ) {
    try {
      const { companyId } = rootState.company;

      commit(mutationName.THIRD_PARTY.SET_IS_CREATING_THIRD_PARTY, true, {
        root: true
      });
      commit(mutationName.THIRD_PARTY.SET_CREATE_THIRD_PARTY_ERROR, false, {
        root: true
      });
      await thirdPartyService.createThirdPartyFromUBO({
        postData,
        thirdPartyId,
        companyId
      });
      commit(
        mutationName.THIRD_PARTY.SET_IS_CREATING_THIRD_PARTY_SUCCESSFUL,
        true,
        {
          root: true
        }
      );
    } catch {
      commit(mutationName.THIRD_PARTY.SET_CREATE_THIRD_PARTY_ERROR, true, {
        root: true
      });
    } finally {
      commit(mutationName.THIRD_PARTY.SET_IS_CREATING_THIRD_PARTY, false, {
        root: true
      });
    }
  }
};
