const gradeRating = {
  AAA: "AAA",
  AA: "AA",
  A: "A",
  BBB: "BBB",
  BB: "BB",
  B: "B",
  C: "C"
};

const strengthScale = {
  STRONG: "Strong",
  MODERATE: "Moderate",
  WEAK: "Weak",
  NONE: "None"
};

const riskScale = {
  NONE: "None",
  NO_MATCH: "No match",
  CRITICAL: "Critical",
  SERIOUS: "Serious",
  MODERATE: "Moderate"
};

const incomingRiskStatus = {
  NOT_REQUESTED: "not_requested",
  IN_PROGRESS: "in_progress",
  GREEN: "green",
  RED: "red"
};

const riskStatus = {
  NOT_AVAILABLE: "notAvailable",
  NOT_RUN: "notRun",
  IN_PROGRESS: "inProgress",
  NO_RISK_ALERT: "noRiskAlert",
  RISK_ALERT: "riskAlert"
};

const customAttributeRiskScales = {
  ETHIXBASE_RISK_SCALE: "ethiXbase Risk Scale",
  FOUR_POINT_SCALE: "4 Point scale",
  ELEVEN_POINT_SCALE: "11 point numeric scale",
  TEN_POINT_SCALE: "10 point numeric scale"
};

const riskStatusAsValues = {
  [riskStatus.NO_RISK_ALERT]: ["0", "5"],
  [riskStatus.RISK_ALERT]: ["1", "6"]
};

const riskStatusMap = {
  [incomingRiskStatus.NOT_REQUESTED]: riskStatus.NOT_RUN,
  [incomingRiskStatus.IN_PROGRESS]: riskStatus.IN_PROGRESS,
  [incomingRiskStatus.GREEN]: riskStatus.NO_RISK_ALERT,
  [incomingRiskStatus.RED]: riskStatus.RISK_ALERT
};

export {
  riskScale,
  strengthScale,
  gradeRating,
  riskStatus,
  riskStatusMap,
  customAttributeRiskScales,
  incomingRiskStatus,
  riskStatusAsValues
};
