<template>
  <a
    :class="[
      'menu-item',
      `menu-item__theme--${theme}`,
      `menu-item--${this.getType()}`,
      isActive && 'menu-item--is-active'
    ]"
    data-test-id="menu-item"
    :href="path"
    @click="handleClick"
  >
    <BaseText
      v-bind="combinedTextOptions"
      data-test-id="menu-item__text"
      class="menu-item__text"
    />
  </a>
</template>

<script>
import BaseText from "@/atoms/BaseText/BaseText";
import { themes, typographySize, menuItemTypes } from "@/constants";
import { isValidTheme } from "@/utils";

export default {
  name: "MenuItem",
  components: { BaseText },
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    textOptions: {
      type: Object,
      default: () => ({})
    },
    path: {
      type: String,
      default: ""
    },
    theme: {
      type: String,
      default: themes.PRIMARY,
      validator(value) {
        return isValidTheme(value);
      }
    },
    isActive: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: menuItemTypes.DEFAULT,
      validator: (value) => Object.values(menuItemTypes).includes(value)
    }
  },
  data() {
    return {
      themes,
      typographySize,
      allowedTypes: Object.values(menuItemTypes)
    };
  },
  computed: {
    combinedTextOptions() {
      return {
        tag: "p",
        theme: themes.NONE,
        size: typographySize.BODY_TEXT_SMALL,
        ...this.textOptions
      };
    }
  },
  methods: {
    handleClick(event) {
      event.preventDefault();
      this.$emit("click", this.id);
    },
    getType() {
      return this.allowedTypes.includes(this.type)
        ? this.type
        : menuItemTypes.DEFAULT;
    }
  }
};
</script>

<style lang="scss" scoped>
.menu-item {
  background-color: transparent;
  cursor: pointer;
  padding: 15px 10px;
  position: relative;
  display: block;
  text-align: center;
  text-decoration: none;

  &__text {
    margin: 0;
  }

  &--default {
    &::before,
    &::after {
      @include menu-item-dash-default;
    }

    &::before {
      left: 50%;
      transform: translateX(-50%);
    }

    &:hover {
      &::before {
        height: 2px;
        width: 20px;
      }
    }

    &.menu-item--is-active {
      &::before {
        display: none;
      }

      &::after {
        height: 4px;
        width: initial;
        border-top-right-radius: $border-radius;
        border-top-left-radius: $border-radius;
        left: 10px;
        right: 10px;
      }
    }
  }

  &--aside {
    text-align: left;

    &::before,
    &::after {
      @include menu-item-dash-default;
    }

    &::before {
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }

    &:hover {
      &::before {
        height: 20px;
        width: 2px;
      }
    }

    &.menu-item--is-active {
      &::after {
        left: 0;
        border-top-right-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
        height: 100%;
        width: 4px;
      }
    }
  }

  @each $theme, $primary-color, $secondary-color in $themes {
    &__theme--#{$theme} {
      &::after,
      &::before {
        background: $primary-color;
      }
    }
  }
}
</style>
