const shapes = {
  NONE: "none",
  SQUARE: "square",
  CIRCLE: "circle",
  TRIANGLE: "triangle",
  OCTAGON: "octagon"
};

const icons = {
  CARET_UP: "caret-up",
  CARET_DOWN: "caret-down",
  EDIT: "edit",
  PLUS: "plus",
  QUESTION: "question",
  BUILDING: "building",
  CALENDAR: "calendar-alt",
  CHART_PIE: "chart-pie",
  EXCLAMATION: "exclamation",
  CHECK: "check",
  HISTORY: "history",
  USER: "user",
  MINUS: "minus"
};

export { shapes, icons };
